import React from 'react';
import { Link } from 'react-router-dom';
import { ucFirst, sinPlu } from '../../../utils/services';
import CategoriesView from './CategoriesView';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import MyListRecipes from '../container/MyListRecipes';

const MyRecipesByThemeView = (props) => {
    const classes = useStyles();

    let { categories, recipes, total } = props;

    return(
        <main fixed="true" className={classes.container}>

            <div className={classes.toolbar} />

            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant="h5" className={classes.title}>
                        {ucFirst(props.theme)}
                    </Typography>
                </Grid>
            </Grid>

            <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
                <Link color="inherit" to="/mes-recettes" className="breadcrumb">
                    <Typography variant="body1" color="primary">Mes recettes</Typography>
                </Link>
                <Typography variant="body1" color="textSecondary">{ucFirst(props.theme)}</Typography>
            </Breadcrumbs>

            <Divider className={classes.mbr145} />
            
            <div>
                <Grid container spacing={3}>
                    <Grid item className={classes.total}>{total} {sinPlu(total, "recette")} </Grid>
                </Grid>
                {categories.length > 0 && 
                    <Grid container spacing={3}>
                        {categories.map((category, index) =>
                            <CategoriesView 
                                key={index}
                                index={index}
                                category={category}
                                pathname={props.pathname}
                            />
                        )}
                    </Grid>
                }


                <MyListRecipes recipes={recipes} />
            </div>

            
        </main>
    );
    
}

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },    
    title: {
        flexGrow: 1,
        fontWeight: 700
    },
    breadCrumb: {
        '& li a': {
            color: 'rgba(0, 0, 0, 0.54)',
            textDecoration: 'none'
        },
        marginBottom: '5px',
    },  
    toolbar: theme.mixins.toolbar,
    mbr145: {
        marginTop: '24px',
        marginBottom: '20px'
    },
    total: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.7rem',
        marginBottom: '15px'
    }
}));

export default MyRecipesByThemeView;