import React from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../app/Resources/Layout/PrivateRoute';
import PublicRoute from '../app/Resources/Layout/PublicRoute';
import Login from '../pages/Login/container/AuthenticationLogin';
import Signup from '../pages/Signup/container/AuthenticationSignup';
import ResetPassword from '../pages/Reset/container/AuthenticationResetPassword';
import Logout from '../pages/Logout/container/AuthenticationLogout';
import UserProfile from '../pages/MyAccount/container/AppUserProfileAccount';
import MyRecipe from '../pages/MyRecipes/container/MyRecipe';
import MyRecipesByCategory from '../pages/MyRecipes/container/MyRecipesByCategory';
import MyRecipesByTheme from '../pages/MyRecipes/container/MyRecipesByTheme';
import MyRecipes from '../pages/MyRecipes/container/AppMyRecipes';
import ThemeRecipe from '../pages/CreateRecipe/container/ThemeRecipe';
import FavoriteRecipe from '../pages/MyFavorites/container/AppFavoriteRecipe';
// import MySupport from '../pages/MySupport/container/AppMySupport';
// import CreateTicket from '../pages/MySupport/container/CreateTicket';
// import DetailTicket from '../pages/MySupport/container/DetailTicket';
import Contact from '../pages/Contact/container/Contact';
import ShareSpecificRecipe from '../pages/Share/container/ShareSpecificRecipe';
import HomePage from '../pages/HomePage/container/AppHome';
import FormRecipe from '../pages/CreateRecipe';
//import AppShare from '../pages/Share/container/AppShare';

 
export default function MainRouter() {
    return (
        <Switch>
            <PublicRoute path='/login' component={Login} />
            <PublicRoute path='/signup' component={Signup} />
            <PublicRoute path='/reset-password' component={ResetPassword} />
            <PrivateRoute path='/logout' component={Logout} />

            {/* MON COMPTE */}
            <PrivateRoute path='/mon-compte' component={UserProfile} />
            {/* 
                <PrivateRoute path='/mon-support/detail-ticket/:id_ticket' component={DetailTicket} />
                <PrivateRoute path='/mon-support/nouveau-ticket' component={CreateTicket} />
                <PrivateRoute path='/mon-support' component={MySupport} />
            */}

            {/* CONTACT */}
            <PrivateRoute path='/contacts' component={Contact} />

            {/* MES RECETTES */}
            <PrivateRoute path='/mes-recettes/update/:id_recipe' component={FormRecipe} />
            <PrivateRoute path='/mes-recettes/:id_theme/:name_theme/:id_category/:category_name' component={MyRecipesByCategory} />
            <PrivateRoute path='/mes-recettes/view/:title_recipe/:id_recipe' component={MyRecipe} />
            <PrivateRoute path='/mes-recettes/:id_theme/:name_theme' component={MyRecipesByTheme} />
            <PrivateRoute path='/mes-recettes' component={MyRecipes} />

            {/* CREER RECETTE */}
            <PrivateRoute path='/creer-recette/:id_theme/:name_theme' component={FormRecipe} />
            <PrivateRoute path='/creer-recette' component={ThemeRecipe} />

            {/* MES FAVORIS */}
            <PrivateRoute path='/mes-favoris' component={FavoriteRecipe} />

            {/* SHARE */}
            <PrivateRoute path='/share/inbox/:id_recipe' component={ShareSpecificRecipe} />
            {/*
                <PrivateRoute path='/share/inbox' component={AppShare} />
            */}
            
            <PrivateRoute path='/' component={HomePage} /> 
        </Switch>
    )
}