import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const ItemRecipeView = (props) => {

    return(
        <div>
            <Typography variant="subtitle1" gutterBottom>Ajouter un ingrédient</Typography>
            <form onSubmit={props.submitItem}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            id="outlined-item-title" 
                            label="Ajouter un titre" 
                            variant="outlined"
                            onChange={props.addTitle}
                            value={props.title}
                            helperText="(facultatif) vous permet de grouper vos ingrédients"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            id="outlined-item" 
                            label="Ajouter un ingrédient" 
                            variant="outlined"
                            onChange={props.addItem}
                            value={props.item}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button 
                            fullWidth
                            variant="contained"
                            disabled={props.isAddItem ? false : true}
                            color="primary"
                            disableElevation
                            type="submit"
                        >
                            Ajouter
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default ItemRecipeView;