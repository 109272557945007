import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
  
  const useStyles = makeStyles(theme => ({
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: '#FE7820',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(5),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginBottom: '20px',
      position: 'relative',
    },
    alert: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      border: '1px solid #f50057',
      borderRadius: '4px',
    },
    buttonProgress: {
      position: 'absolute',
      marginTop: 0,
      marginLeft: '200',
    }
  }));

const AuthenticationSignupView = (props) => {
    const classes = useStyles();

    let disabled = true;
    let text_error;
    
    let error_text_pseudo;
    let error_text_email;
    let error_text_password;
    let error_text_confirm_password;

    if (props.state.pseudo && props.state.email
      && props.state.password && props.state.passwordConfirm) {
        disabled = false;
      }
  
    if (props.state.loading)
      disabled = props.state.loading

    if (props.ERROR_FORM.PSEUDO)
      error_text_pseudo = "Pseudo obligatoire";

    if (props.ERROR_FORM.EMAIL)
      error_text_email = "Adresse mail obligatoire";

    if (props.ERROR_FORM.PASSWORD)
      error_text_password = "Mot de passe obligatoire";

    if (props.ERROR_FORM.CONFIRM_PASSWORD)
      error_text_confirm_password = "Mot de passe obligatoire";

    if (props.ERROR_FORM.FORMAT_EMAIL)
      error_text_email = "Le format de l'adresse mail est invalide";

    if (props.ERROR_FORM.SAME_PASSWORD) {
      error_text_password = "les mots de passe ne sont pas identiques";
      error_text_confirm_password = "les mots de passe ne sont pas identiques";
    }

    if (props.state.ERROR_FORM.error_account.error) {
      if (props.state.ERROR_FORM.error_account.desc === "EMPTY_FIELDS") {
        text_error = "Tous les champs sont obligatoires";
      }

      if (props.state.ERROR_FORM.error_account.desc === "INVALID_EMAIL") {
        text_error = "Le format de l'adresse mail est invalide";
      }

      if (props.state.ERROR_FORM.error_account.desc === "INVALID_PASSWORDS") {
        text_error = "Les mots de passe ne sont pas identiques";
      }

      if (props.state.ERROR_FORM.error_account.desc === "USER_ALREADY_EXISTS") {
        text_error = "Ce compte existe déjà";
      }

      if (props.state.ERROR_FORM.error_account.desc === "INVALID_PSEUDO") {
        text_error = "Le nom d'utilisateur est déjà pris"
      }
    }

    
    return (
        <Grid item xs={12} sm={7} md={5} component={Paper} elevation={0} square>
          <div className={classes.paper}>
          {props.state.ERROR_FORM.error_account.error && 
                <div className={classes.alert}>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <ErrorOutlineIcon color="secondary" />
                        </Grid>
                        <Grid item>
                            <Typography noWrap>{text_error}</Typography>
                        </Grid>
                    </Grid>
                </div>
            }
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Créer un compte
            </Typography>
            <form className={classes.form} noValidate>
          
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="user-pseudo"
                label="Nom Utilisateur"
                name="pseudo"
                autoComplete="pseudo"
                onChange={props._handlePseudo}
                size="small"
                error={props.ERROR_FORM.PSEUDO}
                helperText={error_text_pseudo}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse mail"
                name="email"
                autoComplete="email"
                onChange={props._handleEmail}
                size="small"
                error={props.ERROR_FORM.EMAIL || props.ERROR_FORM.FORMAT_EMAIL}
                helperText={error_text_email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={props._handlePassword}
                helperText={error_text_password}
                size="small"
                error={props.ERROR_FORM.PASSWORD || props.ERROR_FORM.SAME_PASSWORD}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirmer le mot de passe"
                type="password"
                id="confirm-password"
                autoComplete="current-password"
                onChange={props._passwordConfirm}
                size="small"
                error={props.ERROR_FORM.CONFIRM_PASSWORD || props.ERROR_FORM.SAME_PASSWORD}
                helperText={error_text_confirm_password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={props.onLogin}
                disabled={disabled}
                disableElevation
              >
                Créer mon compte
                {props.state.loading && <CircularProgress size={24} className={classes.buttonProgress} color="primary" />}
              </Button>
              
              <Grid container>
                <Grid item xs>
                  <Link to="/reset-password" variant="body2">
                    Mot de passe oublié ?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/login">J'ai un compte</Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
    );
}

export default AuthenticationSignupView;