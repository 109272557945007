import React, { Component } from 'react';
import Client from '../../../Client';
import ShareSpecificRecipeView from '../view/ShareSpecificRecipeView';
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';

export default class ShareSpecificRecipe extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      recipe: {
        "id_recipe": "",
        "title": "",
        "picture": ""
      },
      callApiUser: false,
      successShare: {
        status: false,
        desc: ""
      },
    }

    this.client = new Client();
  }

  componentDidMount = () => {
    this.client.getRecipe(this.props.match.params.id_recipe).then((res) => {
      this.setState({
        ...this.state,
        isLoaded: false,
        recipe: {
          "id_recipe": res.data.recipe.id,
          "title": res.data.recipe.title,
          "picture": res.data.recipe.picture
        }
      })
    }).catch(error => {
      this.setState({
        errorAuthAPI: {
          status: true,
          code_status: error.response.status,
          data: error.response.data
        }
      });

    });

  }

  _userToShare = (id_user) => {
    this.setState({
      ...this.state,
      callApiUser: true,
      successShare: {
        status: false,
        desc: ''
      }
    });

    const data = {
      "user_to": id_user,
      "recipe_id": this.state.recipe.id_recipe,
    }

    this.client.createShareRecipe(data).then((res) => {
      if (res.data.error === 0) {
        this.setState({
          ...this.state,
          callApiUser: false,
          successShare: {
            status: true,
            desc: res.data.desc
          }
        })
      }
    });

  }


  render() {
    if (this.state.isLoaded) {
      return <LinearProgressView />
    } else {
      return (
        <ShareSpecificRecipeView
          recipe={this.state.recipe}
          _userToShare={this._userToShare}
          callApiUser={this.state.callApiUser}
          successShare={this.state.successShare}
        />
      )
    }

  }
};