import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

const ShareRecipeView = (props) => {
    const classes = useStyles();

    const { received } = props.data;

    return(
        <div>
            <IconButton aria-label="cart" className={classes.icon} onClick={props._toggleModal}>
                {received.length > 0 ? (
                    <Badge color="secondary" badgeContent=" " variant="dot">
                        <ShareIcon className={classes.colorCart} onClick={props._toggleModal}/>
                    </Badge>
                ):(
                    <ShareIcon className={classes.colorCart} />
                )}
            </IconButton>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({ 
    colorCart: {
      color: 'rgb(158, 158, 158)',
    },
    icon: {
        marginRight: '15px',
    }
  }));

export default ShareRecipeView;