import React, { Component } from 'react';
import { connect } from 'react-redux';
//import '../../../Webapp/css/App.css';
import Client from '../../../Client';
import FavoriteRecipeView from '../view/FavoriteRecipeView';
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';
import { location } from '../../../redux/actions/breadcrumb';

class AppFavoriteRecipe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recipes: "",
            isLoaded: true,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
        }

        this.client = new Client();
    }

    componentDidMount = () => {
		this.client.getAllFavorites().then(res => {
            let recipes = res.data;
            this.setState({
                recipes: recipes,
                isLoaded: false
            })
            // create button active on sidebar
            this.props.dispatch(location(this.props.match.path));
		}).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    _onDeleteFavorite = (index) => {
        this.client.setUpdateFavorite(this.state.recipes[index]).then((res) => {
            if (res.data.error === 0) {

                let recipes = this.state.recipes;
                recipes.splice(index, 1);

                this.setState({
                    ...this.state,
                    recipes: recipes
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if (this.state.isLoaded) {
			return <LinearProgressView />
		}else {
            return <FavoriteRecipeView 
                        recipes={this.state.recipes}
                        _onDeleteFavorite={this._onDeleteFavorite}
                    />
        }
        
    }
}

AppFavoriteRecipe = connect()(AppFavoriteRecipe);

export default AppFavoriteRecipe;