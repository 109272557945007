import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    marginTextField: {
        marginTop: '15px'
    },
    button: {
        marginBottom: '20px',
    },
    dialogContent: {
      marginBottom: '15px'
    }
}));

const UserEmailFormView = (props) => {
    const classes = useStyles();
    
    return (
      <div>
        <Dialog
          open={props.openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Changer votre adresse mail"}</DialogTitle>

          <DialogContent className={classes.dialogContent}>
            {props.isError &&
              <Alert severity="error">Votre adresse e-mail n'a pas été modifiée</Alert>
            }
            
            <TextField
                id="password-form" 
                label="Votre mot de passe"
                onChange={props._onChangeMyPassword}
                variant="outlined"
                fullWidth
                size="small"
                className={classes.marginTextField}
            />
            <TextField
                id="email-form" 
                label="Votre nouvel email"
                onChange={props._onChangeNewEmail}
                variant="outlined"
                fullWidth
                size="small"
                className={classes.marginTextField}
            />
            <TextField
                id="confirm-email-form" 
                label="Confirmer du nouvel email"
                onChange={props._onChangeConfirmNewEmail}
                variant="outlined"
                fullWidth
                size="small"
                className={classes.marginTextField}
            />
          </DialogContent>

          <DialogActions>
            <Button 
              variant="contained" 
              onClick={props._onClose}
              disableElevation
              className={classes.button}
              color="primary"
            >
              Annuler
            </Button>
            <Button 
              variant="contained" 
              onClick={props._onFormSubmit}
              disabled={props.isRequest}
              disableElevation
              className={classes.button}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default UserEmailFormView;

