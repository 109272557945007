import axios from 'axios';
import Cookies from 'js-cookie';

const urlAPI = process.env.REACT_APP_URL_API;
const COOKIE_STORAGE_KEY = 'mbr_4568762198545285638';

export default class Client {
    constructor() {
        this.useCookie = Cookies.get(COOKIE_STORAGE_KEY);

        if (typeof(this.useCookie) !== "undefined") {
            this.token = Cookies.get(COOKIE_STORAGE_KEY)
        }
    }

    isLoggedIn() {
        return !!this.token;
    }

    setToken( token ) {
        this.token = token
        if (!this.useCookie)
            //Cookies.set(COOKIE_STORAGE_KEY, token.value, { expires: new Date(token.expireIn.date) })
            //Cookies.set(COOKIE_STORAGE_KEY, token.value, { secure: true })
            Cookies.set(COOKIE_STORAGE_KEY, token.value);
    }

    removeToken() {
        this.token = null

        if (this.useCookie)
            Cookies.remove(COOKIE_STORAGE_KEY);
    }

    getToken() {
        return Cookies.get(COOKIE_STORAGE_KEY);
    }

    login(data) {
        return axios.post(urlAPI+'auth-tokens', data)
        .then(this.response)
    }

    signup(data) {
        
        return axios.post(urlAPI+'users/create', data)
        .then(this.response)
        

        /*
        const res = await fetch(urlAPI + 'users/create', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await res.json();
        */
        
    }

    logout() {
        this.removeToken();
    }

    getUser() {
        return axios.get(urlAPI+'users/profile', {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    editUserEmail(data) {
        return axios.put(urlAPI+'users/email/settings', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    editUserPassword(data) {
        return axios.put(urlAPI+'users/password/settings', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    editUser(data) {
        return axios.put(urlAPI+'users/edit', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    pictureUser(data) {
        return axios.post(urlAPI+'users/picture', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    removePictureUser() {
        return axios.put(urlAPI+'users/picture', {data: ""}, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    pictureRecipe(data) {
        return axios.post(urlAPI+'recipes/picture', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    resetPassword(data) {
        return axios.put(urlAPI+'users/reset-password', data);
    }

    getAllThemes() {
        return axios.get(urlAPI+'themes', {
            headers: { 'X-Auth-Token' : this.token }
        }).then(this.response)
        /*
        return fetch(urlAPI+'themes', {
            method: "GET",
            headers: {
                "X-Auth-Token": this.token 
            }
        }).then(res => res.json());
        */
    }

    getTheme(idTheme) {
        return axios.get(urlAPI+'theme/'+idTheme, {
            headers: { 'X-Auth-Token' : this.token }
        }) 
    }

    getAllListWithOrWithoutCatgeoryByTheme(idTheme) {
        return axios.get(urlAPI+'listWithOrWithoutCategories/'+idTheme, {
            headers: { 'X-Auth-Token' : this.token }
        }) 
    }

    getAllCategories() {
        return axios.get(urlAPI+'categories', {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    setRecipe(datas) {
        return axios.post(urlAPI+'recipes/create', datas, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    updateRecipe(datas, recipeId) {
        return axios.put(urlAPI+'recipes/update/'+recipeId, datas, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getMostCountCategory() {
        return axios.get(urlAPI+'categories/count', {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getListCategory(idTheme) {
        return axios.get(urlAPI+'categories/'+idTheme, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    addCategory(idTheme, data) {
        return axios.post(urlAPI+'categories/create/'+idTheme, data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    deletePictureRecipe(file) {
        return axios.delete(urlAPI+'recipes/picture/delete', {
            headers: { 'X-Auth-Token' : this.token }
        }, file)
        .then(this.response)
    }

    getRecipesFromCategory(id_theme, id_category) {
        return axios.get(urlAPI+'recipes/'+id_theme+'/'+id_category, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getRecipe(id_recipe) {
        return axios.get(urlAPI+'recipes/'+id_recipe, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getAllFavorites() {
        return axios.get(urlAPI+'favorites', {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    setUpdateFavorite(recipe) {
        var data = {
            "favorite": !recipe.favorite
        }
        return axios.put(urlAPI+'favorites/update/'+recipe.id, data, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getCourses() {
        return axios.get(urlAPI+'courses', {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getCountCourses() {
        return axios.get(urlAPI+'courses/count', {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    deleteCourse(id_course) {
        return axios.delete(urlAPI+'courses/delete/'+id_course, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    getCourse(id_course) {
        return axios.get(urlAPI+'courses/'+id_course, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    addCourse(data) {
        return axios.post(urlAPI+'courses/add/list', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    deleteRecipe(id_recipe) {
        return axios.delete(urlAPI+'recipes/delete/'+id_recipe, {
            headers: { 'X-Auth-Token' : this.token }
        })
        .then(this.response)
    }

    copyRecipe(data) {
        return axios.post(urlAPI+'recipes/copy', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    getTickets() {
        return axios.get(urlAPI+'tickets', {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    createTicket(data) {
        return axios.post(urlAPI+'ticket/create', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    getSupports(ticket_id) {
        return axios.get(urlAPI+'supports/'+ticket_id, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    answerTicket(data) {
        return axios.post(urlAPI+'supports/'+data.ticket_id+'/answer-ticket', data, {
            headers: { 'X-Auth-token' : this.token }
        })
    }

    contactUs(data) {
        return axios.post(urlAPI+'ticket/contact', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    getAllUsers() {
        return axios.get(urlAPI+'users', {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    getSearchUser(keyword) {
        return axios.post(urlAPI+'users/search', keyword, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    createShareRecipe(data) {
        return axios.post(urlAPI+'share/recipe/create', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    getHistoricShareSpecificRecipe(id_recipe) {
        return axios.get(urlAPI+'historic/share/recipe/'+id_recipe, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    getShareRecipe() {
        return axios.get(urlAPI+'share/recipe',  {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    acceptOrRefuseSharedRecipe(data) {
        return axios.post(urlAPI+'share/recipe/copy', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    async getLastRecipes() {
        const res = await fetch(urlAPI + 'last/recipes', {
            method: "GET",
            headers: {
                "X-Auth-Token": this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return await res.json();
    }

    updateItemFromCourse(data, id_recipe) {
        return axios.post(urlAPI+'courses/item/update/'+id_recipe, data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    async getStats(){
        const res = await fetch(urlAPI + 'stats', {
            method: "GET",
            headers: {
                "X-Auth-Token": this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return await res.json();
    }

    setPlanning(data) {
        return axios.post(urlAPI+'planning', data, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    deletePlanning(schedule_id) {
        return axios.delete(urlAPI+'planning/'+schedule_id, {
            headers: { 'X-Auth-Token' : this.token }
        })
    }

    async getPlanning() {
        const res = await fetch(urlAPI + 'plannings', {
            method: "GET",
            headers: {
                "X-Auth-Token": this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return res;
    }

}