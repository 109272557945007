import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import logo from '../../../app/assets/img/Logo_horizontal.png';



const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#FE7820',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginBottom: '20px',
    position: 'relative'
  },
  alert: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
    border: '1px solid #f50057',
    borderRadius: '4px',
  },
  buttonProgress: {
    position: 'absolute',
    marginTop: 0,
    marginLeft: '200',
  },
  title: {
    marginLeft: '80px',
    fontWeight: '500',
    marginBottom: '5px',
    fontSize: '18px',
    textTransform: 'uppercase',    
  },
  description: {
    marginLeft: '80px',
    lineHeight: '24px',
    fontSize: '14px',    
  },
  containerForm: {
    backgroundColor: '#F6F6F6'
  },
  logo: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
          width: '100%'
      },
  },
  txt_error: {
    [theme.breakpoints.down('sm')]: { fontSize: '0.8em', },
  },
  txt_error_icon: {
    [theme.breakpoints.down('sm')]: { fontSize: '1em', },
  },
  txt_breakpoint: {
    [theme.breakpoints.down('sm')]: { fontSize: '.7em', },
  }
}));

const AuthenticationLoginView = (props) => {
  const classes = useStyles();

  let disabled = true
  let error = false
  let error_text = ""
  let error_account

  if (props.state.email && props.state.password)
    disabled = false

  if (props.state.loading)
    disabled = props.state.loading
  

  if (props.state.ERROR_REPORT.email) {
    error = true
    error_text = "format invalide"
  }


  if (props.state.ERROR_REPORT.error_account) {
    let message = props.state.ERROR_REPORT.message;
    switch(message) {
      case 'INCORRECT_PASSWORD':
        error_account = "compte/mot de passe incorrect";
      break;
      default:
        error_account = "compte invalide";
    }
  }

  return (
    <Grid item xs={12} sm={7} md={5} component={Paper} elevation={0} square className={classes.containerForm}>
      <div className={classes.paper}>
        <Box display={{ xs: 'block', sm: 'none', md: 'none' }} component="div">
          <img src={logo} alt="Mon Bloc Recettes" className={classes.logo} />
        </Box>

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Se connecter
        </Typography>


        {/* message error */}
        {props.state.ERROR_REPORT.error_account && 
            <div className={classes.alert}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <ErrorOutlineIcon className={classes.txt_error_icon} color="secondary" />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.txt_error} noWrap>{ error_account }</Typography>
                    </Grid>
                </Grid>
            </div>
        }


      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Adresse mail"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={props._emailHandle}
          error={error}
          helperText={error_text}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Mot de passe"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={props._passwordHandle}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={props._onSubmit}
          disabled={disabled}
          small="small"
          disableElevation
        >
          Se connecter
          {props.state.loading && <CircularProgress size={24} className={classes.buttonProgress} color="primary" />}
        </Button>

        <Grid container className={classes.txt_breakpoint}>
          <Grid item xs>
            <Link to="/reset-password" variant="body2">
              Mot de passe oublié ?
            </Link>
          </Grid>

          <Grid item>
            <Link to="/signup">Créer un compte</Link>
          </Grid>
        </Grid>
      </form>
    </div>
  </Grid>
      


  );
}

export default AuthenticationLoginView;