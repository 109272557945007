// MANIPULATE SCHEDULE (DATETIME, REMOVE SCHEDULE, ADD)
let initialState = {
    schedules: []
}

export const schedule = (state = initialState, action) => {

    if (action.type === "INIT_SCHEDULE") {
        return {
            ...state,
            schedules: action.schedule
        }
    }

    if (action.type === "ADD_SCHEDULE") {
        const newState = state.schedules.concat([action.schedule])

        // sort by datetime
        newState.sort(function(a,b){
            return new Date(a.datetime) - new Date(b.datetime)
        })

        return {
            ...state,
            schedules: newState
        }
    }

    if (action.type === "REMOVE_SCHEDULE") {
        const result = state.schedules.filter(s => (
            s.id !== action.id
        ))

        return {
            ...state,
            schedules: result
        }
    }

    return state;
}


// ADD PICKER MODAL
let initStatePicker = {
    isOpenPickerSchedule: false,
    idRecipe: ""
}

export const pickerSchedule = (state = initStatePicker, action) => {
    if (action.type === "PICKER_SCHEDULE") {
        return {
            ...state,
            isOpenPickerSchedule: action.isOpenPickerSchedule,
            idRecipe: action.idRecipe
        }
    }

    if (action.type === "CLOSE_PICKER") {
        return initStatePicker
    }

    return state;
}

// OPEN/NOT DIALOG SCHEDULE
let initDialogSchedule = {
    isOpenDialogSchedule: false
}

export const dialogSchedule = (state = initDialogSchedule, action) => {
    if (action.type === "OPEN_DIALOG_SCHEDULE") {
        return {
            ...state,
            isOpenDialogSchedule: action.payload.isOpenDialogSchedule
        }
    }

    return state;
}