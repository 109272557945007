import React, { Component } from 'react';
import UserEmailFormView from '../view/UserEmailFormView';


class UserEmailForm extends Component {

    constructor(props) {
        super(props);

        /**
         * - isError determine if validate form have error
         * - type determine if email or password (use for Alert Component)
         */
        this.state = { 
            isError: false, 
            type: "",
            myPassword: "",
            newEmail: "",
            confirmNewEmail: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isError.bool !== this.props.isError.bool && this.props.isError.bool) {
            this.setState({
                isError: this.props.isError.bool,
                type: this.props.isError.type
            })
        }
    }

    _onFormSubmit = (evt) => {
        evt.preventDefault();

        if(!this.state.myPassword || !this.state.newEmail || !this.state.confirmNewEmail || this.state.newEmail !== this.state.confirmNewEmail) {
            this.setState({ 
                isError: true,
                type: 'EDIT_EMAIL'
             });
        }else {
            let reducer = {
                type: "EDIT_EMAIL",
                datas: {
                    password: this.state.myPassword,
                    email: this.state.newEmail,
                    confirmEmail: this.state.confirmNewEmail
                }
            }
            this.props.dispatchAPI(reducer);
            
        }
     
    }

    _onChangeMyPassword = (event) => {
        this.setState({
            ...this.state,
            myPassword: event.target.value
        })
    }

    _onChangeNewEmail = (event) => {
        this.setState({
            ...this.state,
            newEmail: event.target.value
        })
    }

    _onChangeConfirmNewEmail = (event) => {
        this.setState({
            ...this.state,
            confirmNewEmail: event.target.value
        })
    }

	render() {
		return (
            <UserEmailFormView 
                isError={this.state.isError}
                type={this.state.type}
                isRequest={this.props.isRequest}
                _onClose={this.props.onClose}
                _onFormSubmit={this._onFormSubmit}
                _onChangeMyPassword={this._onChangeMyPassword}
                _onChangeNewEmail={this._onChangeNewEmail}
                _onChangeConfirmNewEmail={this._onChangeConfirmNewEmail}
                openDialog={this.props.openDialog}
            />
		);
	}
	
}

export default UserEmailForm;