import React from 'react'
import MyListRecipeView from './MyListRecipeView';
import { Grid } from '@material-ui/core';
import DialogMyRecipesByCategoryView from './DialogMyRecipesByCategoryView';
import SnackBarMyRecipesByCategory from './SnackBarMyRecipesByCategory';
import AlertDialog from '../../../components/CopyRecipe/container/AppCopyRecipe';
import DatePickerRecipeComponent from '../../../components/DatePickerRecipeComponent';

const MyListRecipesView = (props) => {

    let { recipes } = props;

    return(
        <Grid container spacing={3}>
            {recipes.map((recipe, index) =>
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <MyListRecipeView 
                        index={index}
                        recipe={recipe}
                        _handleDeleteRecipe={props._handleDeleteRecipe}
                        _onDeleteFavorite={props._onDeleteFavorite}
                        _onAddFavorite={props._onAddFavorite}
                        _deleteCart={props._deleteCart}
                        _addCart={props._addCart}
                        _handleMoreOptions={props._handleMoreOptions}
                        moreOptions={props.moreOptions}
                        _toggleCopyRecipe={props._toggleCopyRecipe}
                        _toggleDatePickerRecipe={props._toggleDatePickerRecipe}
                    />
                </Grid>
            )}
            
            {/* POPUP DELETE RECIPE */}
            <DialogMyRecipesByCategoryView 
                openDialog={props.openDialog}
                _onCloseDialog={props._onCloseDialog}
                recipe={props.recipe}
                _onRemoveRecipe={props._onRemoveRecipe}
                isConfirmRemove={props.isConfirmRemove}
            />

            {/* POPUP COPY RECIPE */}
            <AlertDialog 
                _toggleCopyRecipe={props._toggleCopyRecipe}
                isCopy={props.isCopy}
                idRecipe={props.recipe.id}
                _copiedStatus={props._copiedStatus}
            />

            {/* POPUP DATE PICKER */}
            <DatePickerRecipeComponent 
                recipe_id={props.picker.recipe_id}
                isPicker={props.picker.open}
                _toggleDatePickerRecipe={props._toggleDatePickerRecipe}
            />

            <SnackBarMyRecipesByCategory 
                status={props.status}
                message={props.message}
                _handleCloseSnackBar={props._handleCloseSnackBar}
            />
        
        </Grid>
        

        
    );
}

export default MyListRecipesView;