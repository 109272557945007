import React, { Component } from 'react';
import StepRecipeView from '../../view/stepRecipe/StepRecipeView';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import StepView from '../../view/stepRecipe/StepView';
import { Typography } from '@material-ui/core';

class StepRecipe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            steps: this.props.steps || [],
            updateTitle: {
                status: false,
                index: ""
            },
            updateStep: {
                status: false,
                indexList: "",
                indexStep: ""
            },
            title: "",
            step: "",
        }
    }

    componentDidUpdate = (nevProps, prevState) => {

        /**
          * mise à jour des étapes
          * on envoie au composant parent la liste des étapes
          */
         if( prevState.steps !== this.state.steps ) {
            this.props._fetchSteps(this.state.steps);
        }
    }

    _addTitle = (evt) => {
        this.setState({
            ...this.state,
            title: evt.target.value
        });
    }

    _addStep = (evt) => {
        this.setState({
            ...this.state,
            step: evt.target.value,
            isAddStep: evt.target.value.trim() ? true : false
        });
    }

    /**
     * on valide l'ingrédient à ajouter
     */
    _submitStep = (evt) => {
        evt.preventDefault();

        var step = {
            "title": this.state.title, 
            "listSteps": [this.state.step]
        }

        this.setState({
            ...this.state,
            steps: [...this.state.steps, step],
            title: "",
            step: "",
            isAddStep: false,
            indexTitleUpdate: ""
        })

    }


    _onSortEndGroupSteps = ({oldIndex, newIndex}) => {
        this.setState({
            ...this.state,
            steps: arrayMove(this.state.steps, oldIndex, newIndex)
        })
    }

    _removeTitle = (indexStep) => {
        let copyState = Object.assign([], this.state.steps);
        copyState[indexStep]['title'] = ""

        // si plus rien dans la liste, on supprime la liste du tableau
        if (copyState[indexStep]['title'] === "" && copyState[indexStep]['listSteps'].length === 0) {
            copyState.splice(indexStep, 1);
        }

        this.setState({
            ...this.state,
            steps: copyState
        })
    }

    _updateTitle = (index) => {
        this.setState({
            ...this.state,
            updateTitle: {
                status: true,
                index
            }
        })
    }

    _onChangeUpdateTitle = (title) => {
        // copy object title
        let titleToUpdate = Object.assign({}, this.state.steps[this.state.updateTitle.index]);
        titleToUpdate["title"] = title;
        
        // copy object steps
        let stateToUpdate = Object.assign([], this.state.steps);
        stateToUpdate[this.state.updateTitle.index] = titleToUpdate;

        this.setState({
            ...this.state,
            steps: stateToUpdate,
            updateTitle: {
                status: false,
                index: ""
            }
        })
    }

    _onCancelUpdateTitle = () => {
        this.setState({
            ...this.state,
            updateTitle: {
                status: false,
                index: ""
            }
        })
    }

    _updateStep = (indexList, indexStep) => {
        this.setState({
            ...this.state,
            updateStep: {
                status: true,
                indexList,
                indexStep
            },
        })
    }

    _cancelUpdateStep = () => {
        this.setState({
            ...this.state,
            updateStep: {
                status: false,
                indexList: "",
                indexStep: ""
            }
        })
    }

    _addStepToList = (idList, step) => {
        let copyStateStepToUpdate = Object.assign({}, this.state.steps[idList]);
        copyStateStepToUpdate['listSteps'].push(step);
        
        let copyStateStep = Object.assign([], this.state.steps);
        copyStateStep[idList] = copyStateStepToUpdate;

        this.setState({
            ...this.state,
            steps: copyStateStep
        })
    }

    _onChangeUpdateStep = (step) => {

        let copyStateStepListToUpdate = Object.assign({}, this.state.steps[this.state.updateStep.indexList]);
        let copyStateStepToUpdate = Object.assign([], this.state.steps[this.state.updateStep.indexList]['listSteps'])
        copyStateStepToUpdate[this.state.updateStep.indexStep] = step;
        copyStateStepListToUpdate['listSteps'] = copyStateStepToUpdate;

        let copyStateStep = Object.assign([], this.state.steps);
        copyStateStep[this.state.updateStep.indexList] = copyStateStepListToUpdate;

        this.setState({
            ...this.state,
            steps: copyStateStep,
            updateStep: {
                status: false,
                indexList: "",
                indexStep: ""
            } 
        })

    }

    _onRemoveStep = (indexList, indexStep) => {

        let copyStateStepList = Object.assign({}, this.state.steps[indexList]);
        let copyStateStep = Object.assign([], copyStateStepList['listSteps']);
        copyStateStep.splice(indexStep, 1);
        copyStateStepList['listSteps'] = copyStateStep;
        let copyState = Object.assign([], this.state.steps);
        copyState[indexList] = copyStateStepList;

        // si plus rien dans la liste, on supprime la liste du tableau
        if (copyState[indexList]['title'] === "" && copyState[indexList]['listSteps'].length === 0) {
            copyState.splice(indexList, 1);
        }

        this.setState({
            ...this.state,
            steps: copyState
        })
        
    }

    _onSortStepList = ({oldIndex, newIndex, collection}) => {

        let copyStateLisSteps = Object.assign({}, this.state.steps[collection]);
        let sortChangeStep = arrayMove(copyStateLisSteps['listSteps'], oldIndex, newIndex);
        copyStateLisSteps['listSteps'] = sortChangeStep;

        let copyStateStep = Object.assign([], this.state.steps);
        copyStateStep[collection] = copyStateLisSteps;

        this.setState({
            ...this.state,
            steps: copyStateStep
        })

    }

    render(){

        const isAddStep = this.state.isAddStep;
        const steps = this.state.steps;

        const SortableContainerGroupSteps = SortableContainer(({children}) => {
            return <div>{children}</div>;
        })

        return(
            <div>
                { steps.length > 0 && <Typography variant="subtitle2" gutterBottom style={{'marginBottom':'20px'}}>Liste des étapes</Typography> }

                {/* Aperçu des étapes */}
                <SortableContainerGroupSteps onSortEnd={this._onSortEndGroupSteps} useDragHandle>
                    { steps.map((step, index) => (
                        <StepView 
                            key={index}
                            step={step}
                            updateTitle={this.state.updateTitle}
                            updateStep={this.state.updateStep}
                            index={index}
                            idList={index}
                            title={this.state.title}
                            _removeTitle={this._removeTitle}
                            _updateTitle={this._updateTitle}
                            _onChangeUpdateTitle={this._onChangeUpdateTitle}
                            _onCancelUpdateTitle={this._onCancelUpdateTitle}
                            _updateStep={this._updateStep}
                            _addStepToList={this._addStepToList}
                            _cancelUpdateStep={this._cancelUpdateStep}
                            _onChangeUpdateStep={this._onChangeUpdateStep}
                            _onRemoveStep={this._onRemoveStep}
                            _onSortStepList={this._onSortStepList}
                        />
                    ))}
                </SortableContainerGroupSteps>

                
                {/* Formulaire pour ajouter Titre et/ou ingrédient */}
                <StepRecipeView 
                    title={this.state.title}
                    step={this.state.step}
                    isAddStep={isAddStep}
                    addTitle={this._addTitle}
                    addStep={this._addStep}
                    submitStep={this._submitStep}
                />
                
            </div>

        );
    }
}

export default StepRecipe;