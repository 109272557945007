import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TEXT from '../../../utils/dialog'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertSuccess(props) {
    return (
        <Alert onClose={props._handleCloseSnackBar} severity="success">
            Suppression effectuée avec succès !
        </Alert>
    );
}

function AlertSuccessCopy(props) {
    return (
        <Alert onClose={props._handleCloseSnackBar} severity="success">
            {TEXT["SUCCESS_COPY_RECIPE"]}
        </Alert>
    );
}

function AlertError(props) {
    return (
        <Alert onClose={props._handleCloseSnackBar} severity="error">
            Une erreur s'est produite !
        </Alert>
    );
}

const SnackBarMyRecipesByCategory = (props) => {
    let message;

    if (props.message === "SUCCESS") message = <AlertSuccess _handleCloseSnackBar={props._handleCloseSnackBar} />

    if (props.message === "SUCCESS_COPY") message = <AlertSuccessCopy _handleCloseSnackBar={props._handleCloseSnackBar} />

    if (props.message === "ERROR") message = <AlertError _handleCloseSnackBar={props._handleCloseSnackBar} />

    return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={props.status}
            autoHideDuration={2000}
            onClose={props._handleCloseSnackBar}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={props._handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }>
                {message}
            </Snackbar>
        </div>
    );
}

export default SnackBarMyRecipesByCategory;