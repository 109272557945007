import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: '#FE7820',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginBottom: '20px',
      position: 'relative'
    },
    alert: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      border: '1px solid #f50057',
      borderRadius: '4px',
    },
    buttonProgress: {
      position: 'absolute',
      marginTop: 0,
      marginLeft: '200',
    },
  }));

const AuthenticationResetPasswordView = (props) => {
    const classes = useStyles();
    let disabled = true
    let error = false
    let error_text = ""

    if (props.state.email)
        disabled = false

    if (props.state.loading)
        disabled = props.state.loading
    

    if (props.state.ERROR_REPORT.email) {
        error = true
        error_text = "format invalide"
    }

    return (
        <Grid item xs={12} sm={7} md={5} component={Paper} elevation={0} square>
          <div className={classes.paper}>
            {props.state.success &&
                  <div className={classes.alert}>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <DoneOutlineIcon color="secondary" fontSize="small" />
                        </Grid>
                        <Grid item>
                            <Typography noWrap>Un mail vous a été envoyé à l'adresse mail indiquée</Typography>
                        </Grid>
                    </Grid>
                </div>
            }
            {props.state.ERROR_REPORT.error_account && 
                <div className={classes.alert}>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <ErrorOutlineIcon color="secondary" />
                        </Grid>
                        <Grid item>
                            <Typography noWrap>Ce compte n'existe pas</Typography>
                        </Grid>
                    </Grid>
                </div>
            }
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Mot de passe oublié
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse mail"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={props._handleEmail}
                error={error}
                helperText={error_text}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={props._onLogin}
                disabled={disabled}
                disableElevation
              >
                Envoyer
                {props.state.loading && <CircularProgress size={24} className={classes.buttonProgress} color="primary" />}
              </Button>
              
              <Grid container>
                
                <Grid item xs>
                  <Link to="/login" variant="body2">
                    J'ai un compte
                  </Link>
                </Grid>

                <Grid item>
                  <Link to="/signup">Créer un compte</Link>
                </Grid>   
              </Grid>
              
            </form>
          </div>
        </Grid>
    );
}

export default AuthenticationResetPasswordView;