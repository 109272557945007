import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';

const NotationView = (props) => {

    return(
      <div>
        <Typography variant="subtitle1" gutterBottom>Niveau de difficulté</Typography>
        <Rating
          name="simple-controlled"
          value={parseInt(props.difficulty)}
          onChange={(event, newValue) => {
            props._fetchDifficulty(newValue);
          }}
        />
      </div>
    );
}

export default NotationView;