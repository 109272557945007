import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogMyRecipesByCategoryView = (props) => {
    return (
      <div>
        <Dialog
          open={props.openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Supprimer une recette</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Supprimer {props.recipe.title} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              disableElevation 
              color="primary"
              variant="contained" 
              onClick={props._onCloseDialog} 
              disabled={props.isConfirmRemove}
            >
              Annuler
            </Button>
            <Button 
              disableElevation 
              color="primary"
              variant="contained" 
              onClick={() => props._onRemoveRecipe(props.recipe.id)} 
              disabled={props.isConfirmRemove}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default DialogMyRecipesByCategoryView;