import React from 'react';
import { Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FooterView from '../Templates/Footer/view/FooterView';
import AuthenticationCaptionView from '../Templates/Authentication/view/AuthenticationCaptionView';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
//import Poppins from '../../assets/fonts/Poppins/'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
      },
}))

const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Arial', 
        'Helvetica', 
        'sans-serif',
      ].join(','),
    },
    overrides: {
        MuiTypography: {
            colorPrimary: {
                color: '#FE7820'
            }
        },
        MuiTooltip: {
            tooltip: {
                padding: '15px',
                fontSize: 25
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#FE7820',
                '&:hover': {
                    backgroundColor: '#f05f00'
                }
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: '#FE7820'
            }
        }
    },
  });



const PublicRoute = ({component, ...rest}) => {
    const classes = useStyles();

    return(
        <ThemeProvider theme={theme}>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />

                <AuthenticationCaptionView />

                <Route {...rest} render={(props) => (
                    React.createElement(component, props)
                )} />
                
                <FooterView />
            </Grid>
        </ThemeProvider>
    )
}

export default PublicRoute;