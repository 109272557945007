import React, { Component } from 'react';
import Client from '../../../Client';
import UserProfileCredentialInformation from '../view/UserProfileCredentialInformation';
import UserProfileCredentialForm from '../view/UserProfileCredentialForm';
import UserProfileSnackBar from '../view/UserProfileSnackBar';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class 
UserProfileCredential extends Component {
    constructor(props) {
		super(props);
        this.state = {
            email: props.email,
            isRequest: false,
            isModal: {
                isOpen: false,
                type: ''
            },
			isError: {
				bool: false,
				type: ""
			},
			isModifiedUserProfile: false,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
		}
		
		this.client = new Client();
    }

	/**
	 * Open or close modal form
	 */
	toggleModal = (type) => {
        this.setState({
			isModal: {
				isOpen: !this.state.isModal.isOpen,
				type: type
			}
        });
    }
    
	/**
	 * display alert message after validation form from modal form
	 */
	_handleCloseSnackBar = () => {
		this.setState({
			...this.state,
			isModifiedUserProfile: false
		})
	}

    dispatchAPI = (action) => {
		var _this = this;

		this.setState({ 
			...this.state,
			isRequest: true 
		});

		if (action.type === "EDIT_EMAIL") {

			this.client.editUserEmail(action.datas).then(function(response){
				var res = response.data;

				_this.setState({ 
					..._this.state,
					isRequest: false 
				});

				if (res.error === 0) {
					_this.setState({
						..._this.state,
						email: action.datas.email,
						isModal: {
							isOpen: false,
							type: ""
						},
						isError: {
							bool: false,
							type: ""
						},
						isModifiedUserProfile: true
					})
				}

				if (res.error === 1) {
					_this.setState({ 
						..._this.state,
						isError: { bool: true, type: action.type }
					});
				}

			}).catch(error =>  {
				this.setState({
					errorAuthAPI: {
						status: true,
						code_status: error.response.status,
						data: error.response.data
					}
				});
			});
		}
		else if (action.type === "EDIT_PASSWORD") {

			this.client.editUserPassword(action.datas).then(function(response){
				var res = response.data;

				_this.setState({ 
					..._this.state,
					isRequest: false 
				});

				if (res.error === 0) {
					_this.setState({
						..._this.state,
						isModal: {
							isOpen: false,
							type: ""
						},
						isError: {
							bool: false,
							type: ""
						},
						isModifiedUserProfile: true
					})
				}

				if (res.error === 1) {
					_this.setState({ 
						..._this.state,
						isError: { bool: true, type: action.type }
					});
				}

			}).catch(error =>  {
				this.setState({
					errorAuthAPI: {
						status: true,
						code_status: error.response.status,
						data: error.response.data
					}
				});
			});
		}
    }

    render() {
		if (this.state.errorAuthAPI.status) {
			return <DialogError />
		}else {
			return (
				<div id="credential">
					<UserProfileSnackBar 
						isModifiedUserProfile={this.state.isModifiedUserProfile}
						_handleCloseSnackBar={this._handleCloseSnackBar}
					/>
		
					{/* form email and password */}
					<UserProfileCredentialInformation 
						email={this.state.email}
						toggleModal={this.toggleModal} />
						
					{/* modal window to edit email or password */}
					<UserProfileCredentialForm 
						show={this.state.isModal.isOpen} 
						onClose={this.toggleModal} 
						type={this.state.isModal.type} 
						flashMessage={this.flashMessage}
						dispatchAPI={this.dispatchAPI}
						isRequest={this.state.isRequest}
						isError={this.state.isError} />
				</div>
			);
		}
    }
}

export default UserProfileCredential;