import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';
import {sortableHandle, SortableElement, SortableContainer} from 'react-sortable-hoc';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ItemAddMoreInGroup from '../../container/ItemRecipe/ItemAddMoreInGroup';

/* DISPLAYING TITLE */
function ItemTitle(props) {
    const classes = useStyles();

    return (
        <List className={classes.root} component="div">
            <ListItem>
                <ListItemText primary={props.title} className={classes.title} />
                <IconButton
                    onClick={() => props._updateTitle(props.index)}
                    aria-label="edit"
                >
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                    onClick={() => props._removeTitle(props.index)}
                    aria-label="delete"
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </ListItem>
        </List>
    );
}

/* DISPLAYING UPDATE TITLE */
function ItemTitleUpdate(props) {
    const classes = useStyles();
    const [title, setTitle] = useState(props.title);

    const _onChangeUpdateTitle = (evt) => {
        setTitle(evt.target.value)
    }

    return (
        <List component="nav" aria-label="group item title">
            <ListItem className={classes.inputText}>
                <TextField
                    fullWidth 
                    id="outlined-title-item-update" 
                    value={title} 
                    variant="outlined" 
                    onChange={_onChangeUpdateTitle}
                    label="Titre" 
                />
                <IconButton 
                    className={classes.buttonEditUpdateTitle} 
                    onClick={() => props._onChangeUpdateTitle(title)}
                    size="small"
                    aria-label="edit"
                >
                    <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton
                    className={classes.buttonDeleteUpdateTitle}
                    onClick={props._onCancelUpdateTitle}
                    size="small" 
                    aria-label="cancel
                ">
                    <CloseIcon fontSize="small" />
                </IconButton>  
            </ListItem>
        </List>
    );
}

/* DISPLAYING ITEM */
const ItemList = SortableElement((props) => {
    const classes = useStyles();
    const DragHandle = sortableHandle(() => <OpenWithIcon fontSize="small" className={classes.dragHandle} />);

    if (props.updateItem.status && (props.updateItem.indexList === props.idList) && (props.updateItem.indexItem === props.idItem)) {
        return (
            <ItemListUpdate 
                item={props.listItem} 
                _cancelUpdateItem={props._cancelUpdateItem}
                _onChangeUpdateItem={props._onChangeUpdateItem}
            />
        );
    }
    return (
        <div>
            { props.title ? (
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem>
                        <ListItemIcon>
                            <DragHandle />
                        </ListItemIcon>
                        <ListItemText primary={props.listItem}  />
                        <IconButton
                            onClick={() => props._updateItem(props.idList, props.idItem)}
                            aria-label="edit"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                            onClick={() => props._onRemoveItem(props.idList, props.idItem)}
                            aria-label="delete"
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>  
                    </ListItem>         
                </List>
            ) : (
                <List component="nav">
                    <ListItem>
                        <ListItemText primary={props.listItem} />
                        <IconButton
                            onClick={() => props._updateItem(props.idList, props.idItem)}
                            aria-label="edit"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                            onClick={() => props._onRemoveItem(props.idList, props.idItem)}
                            aria-label="delete"
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>  
                    </ListItem>         
                </List>
            )}

        </div>
    );
})

/* DISPLAYING UPDATE ITEM */
function ItemListUpdate(props) {
    const classes = useStyles();
    const [item, setItem] = useState(props.item);

    const _handleChangeItem = (evt) => {
        setItem(evt.target.value)
    }

    return (
        <List component="nav">
            <ListItem className={classes.inputText}>
                <TextField 
                    fullWidth 
                    id="outlined-item-to-update" 
                    variant="outlined"
                    value={item}
                    onChange={_handleChangeItem}
                />
                <IconButton 
                    className={classes.buttonEditUpdate} 
                    onClick={() => props._onChangeUpdateItem(item)} 
                    size="small" 
                    aria-label="edit"
                >
                    <DoneIcon />
                </IconButton>
                <IconButton
                    className={classes.buttonDeleteUpdate}
                    onClick={props._cancelUpdateItem}
                    size="small"
                    aria-label="cancel"
                >
                    <CloseIcon />
                </IconButton>
            </ListItem>
        </List>
    );
}


const ItemView = (props) => {

    const classes = useStyles();
    const DragHandle = sortableHandle(() => <OpenWithIcon className={classes.dragHandleMain} />);

    const SortableContainerGroupItem = SortableContainer(({children, collection}) => {
        return <div>{children}</div>;
    })

    let title;

    // if title
    if (props.item.title) {
        // if update title
        if (props.updateTitle.status && (props.updateTitle.index === props.idList)){
            title = <ItemTitleUpdate 
                        title={props.item.title}
                        index={props.idList}
                        _onChangeUpdateTitle={props._onChangeUpdateTitle}
                        _onCancelUpdateTitle={props._onCancelUpdateTitle}
                    />
        }
        // displaying title
        else {
            title =  <ItemTitle 
                        title={props.item.title}
                        index={props.idList}
                        _removeTitle={props._removeTitle}
                        _updateTitle={props._updateTitle}
                    />
        }
    }


    return (
        <div className={classes.blockItem}>
            <DragHandle />

            <div className={classes.rootItem}>
                {title}
                
                {/* affichage des étapes */}
                <SortableContainerGroupItem onSortEnd={props._onSortItemList} useDragHandle>
                    {props.item.listItems.map((listItem, index) => (
                        <ItemList 
                            title={title}
                            key={index}
                            index={index}
                            idItem={index}
                            idList={props.idList}
                            listItem={listItem}
                            collection={props.idList}
                            updateItem={props.updateItem}
                            _updateItem={props._updateItem}
                            _cancelUpdateItem={props._cancelUpdateItem}
                            _onChangeUpdateItem={props._onChangeUpdateItem}
                            _onRemoveItem={props._onRemoveItem}
                            _onSortItemList={props._onSortItemList}
                        />
                    ))}
                </SortableContainerGroupItem>
            </div>

            {/* affichage du textarea pour ajouter une étape dans sa partie seulement pour les groupes d'étapes */}
            { title && 
                <div className={classes.rootAddItem}>
                    <ItemAddMoreInGroup 
                        _addItemToList={props._addItemToList}
                        idList={props.idList}
                    />
                </div>
            }   


        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        marginBottom: '0px',
      },
      buttonEditUpdate: {
          position: 'absolute',
          top: '-25px',
          right: '35px'
      },
      buttonDeleteUpdate: {
        position: 'absolute',
        top: '-25px',
        right: '0px'
      },
      buttonEditUpdateTitle: {
        position: 'absolute',
        top: '-25px',
        right: '35px'
      },
      buttonDeleteUpdateTitle: {
        position: 'absolute',
        top: '-25px',
        right: '0px'
      },
      dragHandleMain: {
        position: 'absolute',
        marginTop: '-22px',
        marginLeft: '-23px',
        zIndex: '1',
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.74)'
        },
        color: 'rgba(0, 0, 0, 0.54)'
      },
      dragHandle: {
          position: 'absolute',
          marginTop: '-10px',
          marginLeft: '0px',
          zIndex: '1',
          '&:hover': {
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.74)'
          },
          color: 'rgba(0, 0, 0, 0.54)'
      },
      inputText: {
        paddingRight: '0px',
        paddingLeft: '0px',
        marginTop: '15px'
      },
      rootItem: {
          marginBottom: '5px',
      },
      rootAddItem: {
          marginBottom: '25px',
          marginTop: '25px',
      },
      blockItem: {
          border: '1px solid #ddd',
          padding: '10px',
          borderRadius: '4px',
          boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
          marginBottom: '20px'
      },
      title: {
          '& span': {
            fontWeight: 'bold'
          }
      }
}))

const Item = SortableElement(ItemView);

export default Item;