import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField, Typography } from "@material-ui/core";
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import { BallTriangle } from 'react-loading-icons'

export default function CategoryListView({
    categories, 
    open, 
    _handleClose, 
    _handleValidateCategory,
    _handleSelectCategory,
    collectionCategory,
    _handleInput,
    _handleSubmitNewCategory,
    newCategory,
    isLoading
}){

      let cat = categories.map((category) => {
          if (collectionCategory.find(collecCat => collecCat.id === category.id)) {
            return(
                <Box component="span" p={0.5} key={category.id}>
                    <Button 
                        variant="contained"
                        onClick={() => _handleSelectCategory(category.id)}
                        color="primary"
                        disableElevation
                    >
                        {category.name}
                    </Button>  
                </Box> 
            )
          } else {
            return (
                <Box component="span" p={0.5} key={category.id}>
                    <Button 
                        key={category.id} 
                        variant="outlined"
                        onClick={() => _handleSelectCategory(category.id)}
                    >
                        {category.name}
                    </Button>  
                </Box> 
            )
          }
      })

    return (
        <div>
            <Dialog 
                open={open} 
                onClose={_handleClose} 
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">Ajouter/supprimer plusieurs catégories</DialogTitle>
                <Typography variant="caption" style={{ marginLeft: '25px', marginTop: '-15px', marginBottom: '25px' }}>Les catégories sont facultatives</Typography>
                
                <DialogContent dividers>
                    {isLoading ? (
                        <>
                            <Box display="flex" flexDirection="column" justifyContent="center" style={{ marginTop: '15px' }}>
                                <DialogContentText style={{ textAlign: "center"}}>
                                    <BallTriangle fill="transparent" stroke="#FE7820" height="2em" />
                                </DialogContentText>
                                <DialogContentText style={{ textAlign: "center", marginBottom: "50px" }}>
                                    Recherche de vos catégories...
                                </DialogContentText>
                            </Box>
                        </>
                    ) : (
                        <Box style={{ marginTop: '30px' }}>
                            <DialogContentText style={{ marginBottom: '74px' }}>
                                {cat}
                            </DialogContentText>
                        </Box>
                    )}

                    <Box display="flex">
                        <TextField 
                            id="category_name"
                            label="Ajouter un nouvelle catégorie"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ClassOutlinedIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={_handleInput}
                            value={newCategory.name}
                        />
                        <Button 
                            style={{ marginLeft: '5px' }} 
                            variant="outlined" 
                            size="small"
                            onClick={_handleSubmitNewCategory}
                        >
                            ajouter
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_handleClose}>Fermer</Button>
                    <Button onClick={_handleValidateCategory}>Valider</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}