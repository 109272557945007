import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';
  
const useStyles = makeStyles((theme) => ({
    pageFooter: {
        background: '#FFFFFF',
        display: 'flex',
        [theme.breakpoints.down('sm')]: { 
          flexDirection: 'column', 
        },
        flexDirection: 'row',
        padding: '15px',
        '& a': {
          color: '#000000'
        }
    },
    jss203: {
      padding: '8px',
      color: '#9da5b3',
      '& a': {
        color: '#9da5b3',
      }
    }
}));

const FooterView = (props) => {
  const classes = useStyles();


  return(
    <Grid item xs={12} sm={12} md={12} component="div" className={classes.pageFooter}>
      <Box flexGrow={1} className={classes.jss203}>
        ©<Typography variant="caption" gutterBottom>{new Date().getFullYear()} MonBlocRecettes</Typography>
      </Box>
      <Box className={classes.jss203}>
        <Typography variant="caption" gutterBottom>
          <a href="https://about.monblocrecettes.fr">En savoir plus</a>
        </Typography>
      </Box>
      <Box className={classes.jss203}>
        <Typography variant="caption" gutterBottom>
          <a href="https://policy.monblocrecettes.fr">Politique de confidentialite</a>
        </Typography>
      </Box>
    </Grid>
  );
}

export default FooterView;