import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, Typography, Button } from '@material-ui/core';
import { formatDate } from '../../../utils/services';
import { green } from '@material-ui/core/colors';
import ButtonsView from './ButtonsView';


const ModalShapeRecipeView = (props) => {
    const classes = useStyles();
    const { recipes, loading } = props;
    
    return (
        <div>
            <Dialog aria-labelledby="simple-dialog-title" open={props.open} onClose={props._onClose}>
            <DialogTitle id="title-share">Recettes partagées</DialogTitle>
            <DialogContent dividers>
                {props.recipes.length > 0 ? (
                    <div>
                        {recipes.map((data_recipe, index) => (
                            <div className={classes.containerRecipe} key={index}>
                                <img src={data_recipe.recipe.picture} alt="recipe to share" className={classes.size} /> 
                                <div className={classes.mbr_456}>
                                    <Typography variant="body2">{data_recipe.recipe.title}</Typography>
                                    <Typography variant="body2" className={classes.mbr_446}><b>{data_recipe.from_user.pseudo}</b></Typography>
                                    <Typography variant="body2" className={classes.mbr_446}>{data_recipe.from_user.firstname} {data_recipe.from_user.lastname}</Typography>
                                    <Typography variant="body2" className={classes.mbr_446}><i>{formatDate(data_recipe.recipe.created_at.date, "short_date", "fr")}</i></Typography>
                                </div>
                                <div className={classes.itemsButtons}>
                                    <div className={classes.wrapperButton}>
                                        <ButtonsView 
                                            recipe={data_recipe}
                                            loading={loading}
                                            _handleConfirmOrNotShareRecipe={props._handleConfirmOrNotShareRecipe}
                                            index={index}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                ) : (
                    <div><Typography variant="body1">Vous n'avez reçu aucune recette</Typography></div>
                )}

            </DialogContent>

                <Button onClick={props._onClose}>Fermer</Button>

            </Dialog>
        </div>
    );
    


}

const useStyles = makeStyles(theme => ({ 
    mbr_21: {
        display: 'flex',
        marginBottom: '15px'
    },
    size: {
        width: '12%',
        height: '12%',
        borderRadius: '4px'
    },
    mbr_41: {
        marginLeft: '15px'
    },
    mbr_4563: {
        display: 'flex'
    },
    mbr_4521: {
        marginTop: '-5px'
    },

    containerRecipe: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px'
    },
    itemsButtons: {
        display: 'flex',
        marginLeft: 'auto',
        '& button': {
            marginLeft: '5px'
        }
    },
    mbr_456: {
        marginLeft: '15px',
        '& p': {
            lineHeight: '17px'
        }
    },
    mbr_446: {
        fontSize: '12px'
    },
    wrapperButton: {
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
        backgroundColor: green[700],
        },
    },
}));

export default ModalShapeRecipeView;