import React, { Component } from 'react';
import DialogCopyRecipe from '../view/DialogCopyRecipe';
import Client from '../../../Client';

export default class AppCopyRecipe extends Component {

  constructor(props) {
    super(props);
    this.state = {
      themes: [],
      confirmCopy: false,
      isLoaded: true,
			errorAuthAPI: {
        status: false,
        code_status: "",
        data: {}
			},
    }

    this.client = new Client();

  }


  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isCopy !== this.props.isCopy) {
      if (this.props.isCopy) {
        this.client.getAllThemes().then(res => {
          this.setState({
            isLoaded: false,
            themes: res.data
          })
        }).catch(error =>  {
          this.setState({
              errorAuthAPI: {
                  status: true,
                  code_status: error.response.status,
                  data: error.response.data
              }
          });
        });
      }
    }
  }

  _selectTheme = (id_theme) => {

    this.setState({
      ...this.state,
      confirmCopy: true
    })

    let data = {
      "id_theme": id_theme,
      "id_recipe": this.props.idRecipe
    }

    this.client.copyRecipe(data).then(res => {
      if (res.data.error === 0) {
        this.setState({
          themes: [],
          confirmCopy: false
        })
        this.props._copiedStatus({"status": "success", "desc": res.data.message});
      }
    }).catch(err => {
      this.setState({
        themes: [],
        confirmCopy: false
      });
      this.props._copiedStatus({"status": "error"});
    })
    
  }

  render(){
    return (
        <DialogCopyRecipe 
          isLoaded={this.state.isLoaded}
          isCopy={this.props.isCopy} 
          _toggleCopyRecipe={this.props._toggleCopyRecipe}
          themes={this.state.themes}
          _selectTheme={this._selectTheme}
          confirmCopy={this.state.confirmCopy}
        />
    );
  }
};