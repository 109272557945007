export const flashmessage = (state = {"generate": false, "status" : false, "desc": "", duration: 0, "display": false}, action) => {
    if (action.type === "FLASH_MESSAGE") {
        return Object.assign({}, state, {
            "generate": action.generate,
            "status": action.status,
            "desc": action.desc,
            "duration": action.duration,
            "display": action.display
        });
    }
    return state;
}