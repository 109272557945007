import React, { Component } from 'react';
import UserPasswordFormView from '../view/UserPasswordFormView';

class UserPasswordForm extends Component {

    constructor(props) {
        super(props);

        /**
         * - isError determine if validate form have error
         * - type determine if email or password (use for Alert Component)
         */
        this.state = { 
            isError: false, 
            type: "",
            myPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isError.bool !== this.props.isError.bool && this.props.isError.bool) {
            this.setState({
                isError: this.props.isError.bool,
                type: this.props.isError.type
            })
        }
    }

    _onFormSubmit = (evt) => {
        evt.preventDefault();

        if(!this.state.myPassword || !this.state.newPassword || !this.state.confirmNewPassword || this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({ 
                isError: true,
                type: 'EDIT_PASSWORD'
             });
        }else {
            let reducer = {
                type: "EDIT_PASSWORD",
                datas: {
                    password: this.state.myPassword,
                    newPassword: this.state.newPassword,
                    confirmNewPassword: this.state.confirmNewPassword
                }
            }
            this.props.dispatchAPI(reducer);
        }
        
    }

    _onChangeMyPassword = (event) => {
        this.setState({
            ...this.state,
            myPassword: event.target.value
        });
    }

    _onChangeNewPassword = (event) => {
        this.setState({
            ...this.state,
            newPassword: event.target.value
        });
    }

    _onChangeConfirmNewPassword = (event) => {
        this.setState({
            ...this.state,
            confirmNewPassword: event.target.value
        })
    }

	render() {

		return (
            <UserPasswordFormView 
                isError={this.state.isError}
                type={this.state.type}
                isRequest={this.props.isRequest}
                _onClose={this.props.onClose}
                _onFormSubmit={this._onFormSubmit}
                _onChangeMyPassword={this._onChangeMyPassword}
                _onChangeNewPassword={this._onChangeNewPassword}
                _onChangeConfirmNewPassword={this._onChangeConfirmNewPassword}
                openDialog={this.props.openDialog}
            />
		);
	}
	
}

export default UserPasswordForm;
