import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';

const StepTextareaView = (props) => {
    const classes = useStyles();

    return (
        <div>
            <TextField 
                size="small" 
                fullWidth 
                label="Ajouter une étape à ce groupe" 
                id="outlined-group-step" 
                value={props.step}
                variant="outlined" 
                onChange={props._handleChangeStep}
                className={classes.inputItem}
                multiline
                rows="4"
            />
            <Button 
                fullWidth 
                variant="contained" 
                onClick={props._handleSubmitStep}
                disableElevation
                className={classes.buttonAddItem}
                color="primary"
                disabled={props.isDisabled}
            >
                    Ajouter
            </Button>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonAddItem: {
        marginTop: '15px',
    }
}))

export default StepTextareaView;