import { combineReducers } from 'redux'
import { logUser } from './authentication';
import { pictureUser, pseudoUser } from './users';
import {
  pictureRecipe,
  starRecipe, 
  titleRecipe, 
  categoryRecipe, 
  timeWorkingRecipeHour, 
  timeWorkingRecipeMinute, 
  timeCookingRecipeHour, 
  timeCookingRecipeMinute,
  timeRestRecipeHour,
  timeRestRecipeMinute,
  shareRecipe,
  items,
  steps,
  editorContent,
  addOrnotCourse,
  addExistingCategory
} from './recipe';
import { addCart, totalCart, removeCart, removeIdCourse } from './cart';
import { flashmessage } from './flashMessage';
import { path } from './breadCrumb';
import { schedule, pickerSchedule, dialogSchedule } from './schedule';
import { reloadHome } from './home'

export default combineReducers({
  logUser,
  pictureUser,
  pictureRecipe,
  starRecipe,
  titleRecipe,
  categoryRecipe,
  timeWorkingRecipeHour,
  timeWorkingRecipeMinute,
  timeCookingRecipeHour,
  timeCookingRecipeMinute,
  timeRestRecipeHour,
  timeRestRecipeMinute,
  shareRecipe,
  items,
  steps,
  editorContent,
  addOrnotCourse,
  addExistingCategory,
  addCart,
  totalCart,
  removeCart,
  removeIdCourse,
  pseudoUser,
  flashmessage,
  path,
  schedule,
  pickerSchedule,
  dialogSchedule,
  reloadHome
})