import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Divider, Button, TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({  
    title: {
      flexGrow: 1,
      fontWeight: 700
    },
    container: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
      marginTop: '24px',
      marginBottom: '34px'
    },
    breadCrumb: {
        marginBottom: '5px',
    },
    marginInput: {
        marginBottom: '5px',
        marginTop: '15px'
    }
}));

const ContactView = (props) => {
    const classes = useStyles();
    let isError = false;

    if (props.state.isError) {
        isError = true;
    }

    return (
        <main className={classes.container}>
            <div className={classes.toolbar} />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Typography variant="h5" className={classes.title}>
                        Nous contacter
                    </Typography>
                </Grid>
            </Grid>

            <Divider className={classes.mbr145} />

            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant="body1" className={classes.title}>
                        Vous rencontrez un souci, une question à poser ou vous souhaitez proposer une idée d'évolution afin que votre bloc recettes vous apporte plus de satisfaction, nous serons très heureux de vous répondre.
                        </Typography>
                    </Grid>
                </Grid>

                {isError &&
                    <div>
                        {props.state.error.ERROR_SUBJECT &&
                            <Alert severity="error">Veuillez renseignez l'objet de votre ticket</Alert>
                        }

                        {props.state.error.ERROR_COMENT &&
                            <Alert severity="error">Veuillez formuler votre demande</Alert>
                        }
                    </div>
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <form className={classes.root} noValidate autoComplete="off" onSubmit={props._handleSubmit}>


                            <InputLabel variant="standard" className={classes.marginInput}>Département</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                defaultValue={1}
                                onChange={props._handleChangeDepartment}
                            >
                                <MenuItem value={1}>Support Technique</MenuItem>
                                <MenuItem value={2}>Service Marketing</MenuItem>
                            </Select>


                            <InputLabel variant="standard" className={classes.marginInput}>Sujet</InputLabel>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Veuillez entrez un titre indicatif" 
                                variant="outlined"
                                fullWidth
                                onChange={props._handleChangeSubject}
                            />

                            <InputLabel varian="standard" className={classes.marginInput}>Description</InputLabel>
                            <TextField
                                multiline
                                rows={10}
                                fullWidth
                                variant="outlined"
                                onChange={props._handleChangeDescription}
                            />
                            
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                className={classes.marginInput} 
                                disableElevation
                                disabled={props.state.isSubmit}
                            >
                                    Envoyer
                            </Button>
                            
                        </form>
                    </Grid>
                </Grid>
            </div>


        </main>
    );
}

export default ContactView;