import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const CourseView = (props) => {
    const classes = useStyles();

    return(
        <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
            <FormControlLabel
                value="start"
                control={<Switch color="primary" />}
                label="Ajouter dans ma liste des courses"
                labelPlacement="start"
                onChange={props.handleCourse}
                checked={props.checked}
                className={classes.controlLabel}
            />
            </FormGroup>
        </FormControl>
    );
}

const useStyles = makeStyles(theme => ({
    controlLabel: {
        marginLeft: '0',
        marginTop: '16px'
    }
}));

export default CourseView;