import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatDate } from '../../../utils/services';

const useStyles = makeStyles(theme => ({
    blocHistoric: {
        borderLeft: '1px solid rgba(0,0,0,0.12)',
        paddingLeft: '15px',
        height: '100%'
    },
    title_historic: {
        textAlign: 'center'
    },
    mt2: {
        marginTop: '20px'
    },
    mbr_155: {
        marginTop: '20px;'
    },
    circularProgress: {
        marginTop: '20px'
    },
    mbr_21: {
        display: 'flex',
        marginBottom: '15px'
    },
    mbr_41: {
        marginLeft: '15px',
        marginBottom: '20px'
    },
    mbr_colorSubText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '11px'
    },
    mb15: {
        marginBottom: '15px'
    },
    avatar: {
        background: 'rgb(158, 158, 158)'
    },
    skeletonShare: {
        marginBottom: '-10px'
    }
}));

const Status = (param) => {
    if (param.status === 1) {
        return "acceptée";
    } else if (param.status === 2) {
        return "refusée";
    } else {
        return "en attente de confirmation";
    }
}

function UserShareView(props) {
    const classes = useStyles();

    const { data } = props;

    /*
    * if status.confirm = 0 or 1 or 2, that mean that a recipe shared by own user
    */
    if ('property' in data) {
        // it is the own user that shared the recipe
        // we want to know if its recipe was accepted, refused or in wait
        return (
            <div className={classes.mbr_41}>
                <Typography variant="body2">Vous avez partagé votre recette avec <b>{data.user_to.pseudo}</b> · <span className={classes.mbr_colorSubText}> {formatDate(data.status.created_at, "short_date", "fr")} </span></Typography>
                <Typography variant="body2" className={classes.mbr_colorSubText}>
                    <Status status={data.status.confirm} />
                </Typography>
            </div>
        );
    } else {
        return (
            <div className={classes.mbr_41}>
                <Typography variant="body2"><b>{data.user_from.pseudo}</b> a partagé sa recette · <span className={classes.mbr_colorSubText}> {formatDate(data.status.created_at, "short_date", "fr")} </span></Typography>
                <Typography variant="body2" className={classes.mbr_colorSubText}>
                    <Status status={data.status.confirm} />
                </Typography>
            </div>
        );
    }
}

function DisplayHistoric(props) {
    const classes = useStyles();

    return (
        <div>
            {props.callApiUser &&
                <div className={classes.mt2}>
                    <div className={classes.mbr_21}>
                        <div className={classes.mbr_41}>
                            <Skeleton variant="text" width={200} height={40} className={classes.skeletonShare} />
                            <Skeleton variant="text" width={200} height={40} className={classes.skeletonShare} />
                        </div>
                    </div>
                </div>
            }

            <div className={classes.mt2}>
                {props.data.map((data, index) =>
                    <UserShareView key={index} data={data} />
                )}
            </div>

            {/*
            <div className={classes.mt2}>
                {props.data.map((data) => 
                    <div key={data.user.id} className={classes.mbr_21}>
                        {data.user.authorPicture ? (
                            <Avatar 
                                className={classes.avatar}
                                src={data.user.authorPicture}
                            />
                        ) : (
                            <PersonIcon />
                        )}
                        <div className={classes.mbr_41}>
                            <Typography variant="body2">{data.user.pseudo}</Typography>
                            <Typography variant="caption">{data.user.firstname} {data.user.lastname}</Typography>
                            <Typography variant="body2" className={classes.mbr_colorSubText}>
                                <Status status={data.share_status.status}/>
                            </Typography>
                            <i><Typography variant="caption" className={classes.mbr_colorSubText}>{formatDate(data.share_status.created_at.date, "short_date", "fr")}</Typography></i>
                        </div>
                    </div>
                )}
            </div> 
            */}
        </div>
    );

}

const HistoricRecipesSharedView = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.blocHistoric}>
            <Typography variant="body1" className={classes.title_historic}>Historique</Typography>

            <Divider className={classes.mt2} />

            {props.isloaded
                ? <CircularProgress size={20} className={classes.circularProgress} />
                : <DisplayHistoric
                    callApiUser={props.callApiUser}
                    successShare={props.successShare}
                    data={props.data}
                />
            }

        </div>

    );

}

export default HistoricRecipesSharedView;