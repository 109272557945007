import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import logo from '../../../../assets/img/Logo_horizontal.png';
import { Dashboard } from '@material-ui/icons';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#FFFFFF',
    borderRight: 'none',
    boxShadow: '0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15)'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    background: '#FFFFFF',
    '& a': {
      fontSize: '18px',
      lineHeight: '30px',
      textTransform: 'uppercase',
      margin: 'auto',
    }
  },
  textColorListItem: {
    fontWeight: 500
  },
  ColorListItemIcon: {
    color: '#FE7820',
  },
  colorButton : {
    margin: '10px 15px 0',
    width: 'auto',
    padding: '10px 15px',
    borderRadius: '3px',
    '&:hover': {
      background: '#fff0e6',
    },
    '&:hover div': {
      color: '#ffffff'
    }
  },
  buttonActive: {
    background: '#fff0e6',
  },
  colorButtonActive: {
    '&:hover': {
      background: '#fff0e6',
    },
  },
  listItemIcon: {
    minWidth: '35px'
  },
  logo: {
    width: '90%'
  }
}));

const SidebarView = (props) => {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  let pathname = "";


  if (props.locationPathname !== "") pathname = props.locationPathname.path;

  const drawer = (
    <div>
      <List>
        <ListItem button component={Link} to="/" className={(pathname === "/" ? classes.colorButton + ' ' + classes.buttonActive : classes.colorButton)} onClick={props.handleCloseDrawer}>
            <ListItemIcon className={classes.listItemIcon}>
                <Dashboard className={classes.ColorListItemIcon} />
            </ListItemIcon>
            <Typography variant="body1" className={classes.textColorListItem}>Tableau de bord</Typography>
        </ListItem>
        <ListItem button component={Link} to="/mes-recettes" className={(pathname === "/mes-recettes" ? classes.colorButton + ' ' + classes.buttonActive : classes.colorButton)} onClick={props.handleCloseDrawer}>
            <ListItemIcon className={classes.listItemIcon}>
                <ListAltOutlinedIcon className={classes.ColorListItemIcon} />
            </ListItemIcon>
            <Typography variant="body1" className={classes.textColorListItem}>Mes recettes</Typography>
        </ListItem>
        <ListItem button component={Link} to="/creer-recette" className={(pathname === "/creer-recette" ? classes.colorButton + ' ' + classes.buttonActive : classes.colorButton)} onClick={props.handleCloseDrawer}>
            <ListItemIcon className={classes.listItemIcon}>
                <NoteAddOutlinedIcon className={classes.ColorListItemIcon} />
            </ListItemIcon>
            <Typography variant="body1" className={classes.textColorListItem}>Créer une recette</Typography>
        </ListItem>
        <ListItem button component={Link} to="/mes-favoris" className={(pathname === "/mes-favoris" ? classes.colorButton + ' ' + classes.buttonActive : classes.colorButton)} onClick={props.handleCloseDrawer}>
            <ListItemIcon className={classes.listItemIcon}>
                <FavoriteBorderOutlinedIcon className={classes.ColorListItemIcon} />
            </ListItemIcon>
            <Typography variant="body1" className={classes.textColorListItem}>Mes favoris</Typography>
        </ListItem>
        {/* 
            <ListItem button component={Link} to="/share/inbox" className={classes.colorButton} onClick={props.handleCloseDrawer}>
                <ListItemIcon className={classes.listItemIcon}>
                    <ShareIcon className={classes.ColorListItemIcon} />
                </ListItemIcon>
                <ListItemText primary="Recettes partagées" className={classes.textColorListItem} />
            </ListItem>
        */}
      </List>
    </div>
  );

  return(
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={props.handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <div className={classes.drawerHeader}>
            <Link to="/"><img src={logo} alt="Mon Bloc Recettes" className={classes.logo} /></Link>
          </div>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default SidebarView;