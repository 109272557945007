/**
 * etat de la liste des ingrédients
 */

const stateRecipe = {
  items: {
    listItems: [],
    groupItems: []
  },
  title: "",
  item: "",
  isAddItem: false,
  idItemToUpdate: "",
  idItemFromGroupToUpdate: {
    idGroup: "",
    idItem: "",
    isUpdate: false
  },
  idTitleFromGroupToUpdate: {
    idGroup: "",
    isUpdate: false
  },
  idGroup: "",
  idGroupToDelete: ""
};


/**
 * etat de la liste des etapes
 */
const stateRecipeStep = {
  steps: {
    listSteps: [],
    groupSteps: []
  },
  title: "",
  step: "",
  isAddStep: false,
  idStepToUpdate: "",
  idStepFromGroupToUpdate: {
    idGroup: "",
    idStep: "",
    isUpdate: false
  },
  idTitleFromGroupToUpdate: {
    idGroup: "",
    isUpdate: false
  },
  idGroup: "",
  idGroupToDelete: ""
};

const stateCategory = {
  id_category: "", 
  name_category: ""
}

export const pictureRecipe = (state = "", action) => {
  if(action.type === 'UPLOAD_PICTURE') {
    return action.pictureUrl;
  }
  return state;
}

export const starRecipe = (state = "", action) => {
  if(action.type === 'STAR_RECIPE') {
    return action.starRecipe;
  }
  return state;
}

export const categoryRecipe = (state = "", action) => {
    if(action.type === 'CATEGORY_RECIPE') {
      return action.categoryRecipe;
    }
    return state;
}

export const titleRecipe = (state = "", action) => {
  if(action.type === 'TITLE_RECIPE') {
    return action.titleRecipe;
  }
  return state;
}

export const timeWorkingRecipeHour = (state = "", action) => {
  if(action.type === 'TIME_WORKING_HOUR_RECIPE') {
    return action.timeWorkingRecipeHour;
  }
  return state;
}

export const timeWorkingRecipeMinute = (state = "", action) => {
  if(action.type === 'TIME_WORKING_MINUTE_RECIPE') {
    return action.timeWorkingRecipeMinute;
  }
  return state;
}

export const timeCookingRecipeHour = (state = "", action) => {
  if(action.type === 'TIME_COOKING_HOUR_RECIPE') {
    return action.timeCookingRecipeHour;
  }
  return state;
}

export const timeCookingRecipeMinute = (state = "", action) => {
  if(action.type === 'TIME_COOKING_MINUTE_RECIPE') {
    return action.timeCookingRecipeMinute;
  }
  return state;
}

export const timeRestRecipeHour = (state = "", action) => {
  if(action.type === 'TIME_REST_HOUR_RECIPE') {
    return action.timeRestRecipeHour;
  }
  return state;
}

export const timeRestRecipeMinute = (state = "", action) => {
  if(action.type === 'TIME_REST_MINUTE_RECIPE') {
    return action.timeRestRecipeMinute;
  }
  return state;
}

export const shareRecipe = (state = "", action) => {
  if(action.type === 'SHARE_RECIPE') {
    return action.shareRecipe;
  }
  return state;
}

/**
 * 
 *  ItemRecipe
 */
export const items = (state = stateRecipe, action) => {
  switch(action.type) {

    case 'ADD_ITEM_FROM_INPUT':
      return Object.assign({}, state, {
        item: action.addItemFromInput,
        isAddItem: true
      });

    case 'DELETE_ITEM_FROM_INPUT':
      return Object.assign({}, state, {
        item: "",
        isAddItem: false
      });

    case 'ADD_TITLE_FROM_INPUT':
      return Object.assign({}, state, {
        title: action.addTitleFromInput
      });

    case 'SUBMIT_ITEM_WITHOUT_TITLE':
      return Object.assign({}, state, {
        title: "",
        item: "",
        items: {
          listItems: state.items.listItems.concat(state.item),
          groupItems: state.items.groupItems
        },
        isAddItem: false
      });

    case 'SUBMIT_ITEM_WITH_TITLE':
      return Object.assign({}, state, {
        title: "",
        item: "",
        items: {
          listItems: state.items.listItems,
          groupItems: state.items.groupItems.concat(action.submitItemWithTitle)
        },
        isAddItem: false
      });

    case 'DELETE_ITEM_FROM_LIST':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: state.items.groupItems
        }
      });

    case 'UPDATE_ITEM_FROM_LIST':
      return Object.assign({}, state, {
        idItemToUpdate: action.idItem
      });

    case 'SUBMIT_UPDATE_ITEM_FROM_LIST':
      return Object.assign({}, state, {
        items: {
          listItems: action.arrayItems,
          groupItems: state.items.groupItems
        },
        idItemToUpdate: ""
      });

    case 'CANCEL_UPDATE_ITEM_FROM_LIST':
      return Object.assign({}, state, {
        idItemToUpdate: ""
      })

    case 'ADD_ITEM_FOR_GROUP':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: action.itemForGroup
        }
      });

    case 'DELETE_ITEM_FOR_GROUP':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: action.deleteItemForGroup
        }
      });

    case 'VALID_UPDATE_ITEM_FOR_GROUP':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: action.item
        },
        idItemFromGroupToUpdate: {
            idGroup: "",
            idItem: "",
            isUpdate: false
        }
      })

    case 'DELETE_ONLY_TITLE_FOR_GROUP':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems.concat(action.listItemFromGroupToDelete),
          groupItems: action.titleFromGroupToDelete
        },
        idGroupToDelete: ""
      })

    case 'DELETE_ALL_ITEM_GROUP_ITEM':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: action.groupItems
        },
        idGroupToDelete: ""
      });

    case 'VALID_UPDATE_TITLE_FOR_GROUP':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: action.title
        },
        idTitleFromGroupToUpdate: {
            idGroup: "",
            isUpdate: false
        }
      });

    case 'SORT_ITEM':
      return Object.assign({}, state, {
        items: {
          listItems: action.items,
          groupItems: state.items.groupItems
        }
      });

    case 'SORT_GROUP_ITEM':
      return Object.assign({}, state, {
        items: {
          listItems: state.items.listItems,
          groupItems: action.groupItems
        }
      });

    case 'SORT_GROUP_ITEMS':
      return Object.assign({}, state, {
        items: action.groupItems
      });

    case 'ADD_ITEMS_FROM_UPDATE':
      return Object.assign({}, state, {
        items: {
          listItems: action.listItems,
          groupItems: action.groupItems
        }
      });

    case 'ID_TITTLE_FROM_GROUP_TO_UPDATE':

      if( action.idGroup !== "undefined" ) {

        return Object.assign({}, state, {
          idTitleFromGroupToUpdate: {
              idGroup: action.idGroup,
              isUpdate: true
          }
        });

      }else {

        return Object.assign({}, state, {
          idTitleFromGroupToUpdate: {
              idGroup: "",
              isUpdate: false
          }
        });
        
      }

    case 'ID_ITEM_FROM_GROUP_TO_UPDATE':

      if( action.idGroupItem !== "undefined" && action.idItem !== "undefined" ) {

        return Object.assign({}, state, {
          idItemFromGroupToUpdate: {
              idGroup: action.idGroupItem,
              idItem: action.idItem,
              isUpdate: true
          }
        });

      }else {

        return Object.assign({}, state, {
          idItemFromGroupToUpdate: {
              idGroup: "",
              idItem: "",
              isUpdate: false
          }
        });

      }

    case 'ID_GROUP_TO_DELETE':
      return Object.assign({}, state,  {
        idGroupToDelete: action.idGroup
      });

    default:
      return state;

  }
  
}


/**
 * 
 *  StepRecipe
 */
export const steps = (state = stateRecipeStep, action) => {

  switch(action.type) {

    case 'ADD_STEP_FROM_INPUT':
      return Object.assign({}, state, {
        step: action.addStepFromInput,
        isAddStep: true
      });

    case 'DELETE_STEP_FROM_INPUT':
      return Object.assign({}, state, {
        step: "",
        isAddStep: false
      });

    case 'ADD_TITLE_FROM_INPUT_STEP':
      return Object.assign({}, state, {
        title: action.addTitleFromInput
      });

    case 'SUBMIT_STEP_WITHOUT_TITLE':
      return Object.assign({}, state, {
        title: "",
        step: "",
        steps: {
          listSteps: state.steps.listSteps.concat(state.step),
          groupSteps: state.steps.groupSteps
        },
        isAddStep: false
      });

    case 'SUBMIT_STEP_WITH_TITLE':
      return Object.assign({}, state, {
        title: "",
        step: "",
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: state.steps.groupSteps.concat(action.submitStepWithTitle)
        },
        isAddStep: false
      });

    case 'DELETE_STEP_FROM_LIST':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: state.steps.groupSteps
        }
      });

    case 'UPDATE_STEP_FROM_LIST':
      return Object.assign({}, state, {
        idStepToUpdate: action.idStep
      });

    case 'SUBMIT_UPDATE_STEP_FROM_LIST':
      return Object.assign({}, state, {
        steps: {
          listSteps: action.arraySteps,
          groupSteps: state.steps.groupSteps
        },
        idStepToUpdate: ""
      });

    case 'CANCEL_UPDATE_STEP_FROM_LIST':
      return Object.assign({}, state, {
        idStepToUpdate: ""
      })

    case 'ADD_STEP_FOR_GROUP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: action.stepForGroup
        }
      });

    case 'DELETE_STEP_FOR_GROUP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: action.deleteStepForGroup
        }
      });

    case 'UPDATE_STEP_FOR_GROUP':
      return Object.assign({}, state, {
        idIStepFromGroupToUpdate: {
          idGroup: action.idGroupStep,
          idStep: action.idStep,
          isUpdate: true
        }
      });

    case 'VALID_UPDATE_STEP_FOR_GROUP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: action.step
        },
        idStepFromGroupToUpdate: {
            idGroup: "",
            idStep: "",
            isUpdate: false
        }
      })

    case 'DELETE_ONLY_TITLE_FOR_GROUP_STEP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps.concat(action.listStepFromGroupToDelete),
          groupSteps: action.titleFromGroupToDelete
        }
      })

    case 'DELETE_ALL_STEP_GROUP_STEP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: action.groupSteps
        }
      });

    case 'VALID_UPDATE_TITLE_FOR_GROUP_STEP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: action.title
        },
        idTitleFromGroupToUpdate: {
            idGroup: "",
            isUpdate: false
        }
      });

    case 'SORT_STEP':
      return Object.assign({}, state, {
        steps: {
          listSteps: action.steps,
          groupSteps: state.steps.groupSteps
        }
      });

    case 'SORT_GROUP_STEP':
      return Object.assign({}, state, {
        steps: {
          listSteps: state.steps.listSteps,
          groupSteps: action.groupSteps
        }
      });

    default:
      return state;

  }
  
}

export const editorContent = (state = {}, action) => {
  if(action.type === 'EDITOR_CONTENT') {
    return Object.assign({}, state, {
      editorState: action.editorState,
      editorHTML: action.editorHTML
    })
  }
  return state;
}

export const addOrnotCourse = (state = false, action) => {
  if(action.type === 'ADD_OR_NOT_LIST_COURSES') {
    return action.checked;
  }
  return state;
}

export const addExistingCategory = (state = stateCategory, action) => {
  if (action.type === 'ADD_EXISTING_CATEGORY') {
    return Object.assign({}, state, {
      id_category: action.idCategory,
      name_category: action.nameCategory
    }) 
  }
  return state;
}