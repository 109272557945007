import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const ShareView = (props) => {

    return(
        <div>
            <Typography variant="subtitle1" gutterBottom>Nombre de part(s)</Typography>
            <TextField
                fullWidth
                id="share-recipe" 
                label="Nombre de part" 
                variant="outlined"
                onChange={props._handleChange}
                value={props.share}
                type="number"
                inputProps={{ min: "0", step: "1"}}
            />
        </div>
    );
}

export default ShareView;