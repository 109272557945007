export const users = (state = "", action) => {
  if(action.type === 'EDIT_USER_EMAIL') {
    return action.userEmail;
  }
  return state;
}

export const pictureUser = (state = "", action) => {
  if(action.type === "PICTURE_USER") {
    return action.pictureUser
  }
  return state;
}

export const pseudoUser = (state = "", action) => {
  if(action.type === "PSEUDO_USER") {
    return action.pseudoUser
  }
  return state;
}