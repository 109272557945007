import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const StepRecipeView = (props) => {

    return(
        <div>
            <Typography variant="subtitle1" gutterBottom>Ajouter une étape</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        fullWidth
                        id="outlined-step-title" 
                        label="Ajouter un titre" 
                        variant="outlined"
                        onChange={props.addTitle}
                        value={props.title}
                        helperText="(facultatif) vous permet de grouper vos étapes"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        fullWidth
                        id="outlined-step" 
                        label="Ajouter une étape" 
                        variant="outlined"
                        onChange={props.addStep}
                        value={props.step}
                        multiline
                        rows="4"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button 
                        fullWidth 
                        onClick={props.submitStep} 
                        variant="contained"
                        disabled={props.isAddStep ? false : true}
                        color="primary"
                        disableElevation
                    >
                        Ajouter
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default StepRecipeView;