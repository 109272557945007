import React, { Component } from 'react';
import { connect } from 'react-redux';
import FlashMessageView from '../view/FlashMessageView';
import { flashMessage } from '../../../redux/actions/flashmessage'
/**
 * flashMessage[status, desc, display]
 * generate: if flash message created
 * status : to know if success or error or info or warning
 * desc : what kind of message need to display
 * duration: time to display on screen
 * display : if flash message need to be display on screen
 */

class AppFlashMessage extends Component {

    _handleCloseSnackBar = () => {
        this.props.dispatch(flashMessage(false, this.props.flashMessage.status, this.props.flashMessage.desc, this.props.duration, false));
    }

    render() {
        return <FlashMessageView 
                    flashmessage={this.props.flashMessage} 
                    _handleCloseSnackBar={this._handleCloseSnackBar}
                />
    }
}

function mapsStateToProps(state) {
    return {
        flashMessage: state.flashmessage
    }
}

AppFlashMessage = connect(mapsStateToProps)(AppFlashMessage);

export default AppFlashMessage;