import React, { Component } from 'react';
import { emailFormat } from '../../../utils/services';
import Client from '../../../Client';
import AuthenticationResetPasswordView from '../view/AuthenticationResetPasswordView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class AuthenticationResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            loading: false,
            success: false,
            ERROR_REPORT: {
                email: false,
                error_account: false
            },
            errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
            }
        }

        this.client = new Client();
    }

    _handleEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onLogin = (e) => {
        e.preventDefault();
        var _this = this;

        this.setState({ loading: true });

        if (emailFormat(this.state.email)) {
            
            let data = {
                email: this.state.email,
            }

            this.client.resetPassword(data).then(function(res){
                if (res.data.error === 0) {

                    _this.setState({
                        loading: false,
                        success: true,
                        ERROR_REPORT: {
                            email: false,
                            error_account: false
                        }
                    })

                }else {
                    _this.setState({
                        loading: false,
                        success: false,
                        ERROR_REPORT: {
                            email: false,
                            error_account: true
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });

        }else {
            this.setState({
                ...this.state,
                ERROR_REPORT: {
                    email: true,
                    error_account: false
                }
            })
        }
    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else {
            return (
                <AuthenticationResetPasswordView
                    state={this.state}
                    _handleEmail={this._handleEmail}
                    _onLogin={this.onLogin}
                />
            );
        }
    }
}

export default AuthenticationResetPassword;