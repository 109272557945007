import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      zIndex: 9999
    },
    content: {
        flexGrow: 1,
        height: '100vh',
    },
  }));

const LinearProgressView = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
          <LinearProgress variant="query" />
        </div>
    );
}

export default LinearProgressView;