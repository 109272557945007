import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Avatar, CardMedia, CardContent, Typography } from '@material-ui/core';
import { ucFirst, urlFormat, removeAccents } from '../../../utils/services';
import Rating from '@material-ui/lab/Rating';

const LastRecipesView = (props) => {
    
    const classes = useStyles();

    let timeWork = "n/a";
    let timeWorkingHour = "";
    let timeWorkingMinute = "";

    if( parseInt(props.recipe.timeWorkingHour) > 0 )
        timeWorkingHour = props.recipe.timeWorkingHour + "h";

    if( parseInt(props.recipe.timeWorkingMinute) > 0 )
        timeWorkingMinute = props.recipe.timeWorkingMinute + "min";

    if (timeWorkingHour || timeWorkingMinute)
        timeWork = timeWorkingHour + timeWorkingMinute;

    return (
        <Card className={classes.card}>

            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar} alt={props.recipe.title.toUpperCase()} src="/" />
                }
                title={ucFirst(props.recipe.title.toLowerCase())}
            />

                {props.recipe.link ? (
                    <CardMedia
                        className={classes.media}
                        image={props.recipe.pictureUrl}
                        component="a" 
                        href={props.recipe.link}
                        target="_blank"
                        rel="noreferrer"
                    />
                ) : (
                    <CardMedia
                        className={classes.media}
                        image={props.recipe.pictureUrl}
                        title={props.recipe.title.toUpperCase()}
                        component={Link} 
                        to={`/mes-recettes/view/${urlFormat(removeAccents(props.recipe.title.toLowerCase()))}/${props.recipe.id}`}
                    />
                )}


            <CardContent className={classes.rootContent}>
                <Typography variant="body1" className={classes.captionTime}>Temps: {timeWork}</Typography>
                <Rating name="difficulty" value={parseInt(props.recipe.difficulty)} readOnly  size="small" className={classes.rating} />
            </CardContent>

        
        </Card>
    );
}

const useStyles = makeStyles(theme => ({
    card: {
      /*maxWidth: 345,*/
      boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
      background: '#ffffff',
    },
    media: {
      /*width: 300,*/
      paddingTop: '56.25%', // 16:9,
    },
    avatar: {
      backgroundColor: '#FE7820',
    },
    captionTime: {
        margin: 0,
        flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    mbr123: {
        padding: '5px'
    },
    rootContent: {
        display: 'flex',
    },
    rating: {
        marginTop: '3px'
    }
  }));

export default LastRecipesView;