import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ucFirst } from '../../../utils/services';
import { Divider } from '@material-ui/core';
import '../../../assets/css/grid.css';

const ThemeRecipeView = (props) => {
    const classes = useStyles();
    var themes = props.themes;

    return(
            <main fixed="true" className={classes.container}>
                <div className={classes.toolbar} />
                <Grid container spacing={3}>
                    <Grid item lg={12}>
                        <Typography variant="h5" className={classes.title}>Créer une recette</Typography>
                    </Grid>
                </Grid>

                <Divider className={classes.mbr145} />

                <Grid container spacing={3}>
                  <div className="masonryholder">
                      {themes.map((name, index) => 
                        <Link to={`/creer-recette/${name.id}/${name.name_uri}`} className="masonryblocks" key={index}>
                          <div className="bl_masonryblocks">
                              <img src={name.poster} alt="poster recipe" />
                              <div className="caption">
                                  <div>
                                      <Typography component="span" variant="subtitle1" color="inherit">
                                          {ucFirst(name.name)}
                                          <span></span>
                                      </Typography>
                                  </div>
                              </div>
                            </div>
                        </Link>
                      )}
                    </div>
                </Grid>
            </main>
    );
}

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },    
  title: {
    flexGrow: 1,
    fontWeight: 700
  },
  toolbar: theme.mixins.toolbar,
  mbr145: {
    marginTop: '24px',
    marginBottom: '34px'
  }
}));

export default ThemeRecipeView;