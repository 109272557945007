import React, { Component } from 'react';

import Client from '../../../Client';

import ShareRecipeView from '../view/ShareRecipeView';
import ModalShapeRecipeView from '../view/ModalShapeRecipeView';

import { connect } from 'react-redux';
import { reloadHome } from '../../../redux/actions/home';

class AppShareRecipe extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        "all": [],
        "accepted": [],
        "received": [],
        "refused": [],
        "shared": [],
      },
      isModal: {
        isOpen: false
      },
      loading: false,
      successShareRecipe: false,
      refusedShareRecipe: false
    }

    this.client = new Client();
  }

  componentDidMount = () => {
    this._getShareRecipe();
  }

  /**
  * Open or close modal form
  */
  _toggleModal = () => {
    this.setState({
      ...this.state,
      isModal: {
        isOpen: !this.state.isModal.isOpen
      }
    });
  }

  _handleConfirmOrNotShareRecipe = (id_recipe, index, status) => {

    let copyRecipe = Object.assign([], this.state.data.received);

    this.setState({
      ...this.state,
      loading: true,
      data: {
        "received": copyRecipe
      }
    });

    let data = {
      "id_recipe_copy": id_recipe,
      "status": status,
    };

    // status = 1 (confirm)
    // status = 2 (refuse)
    copyRecipe[index]['confirm'] = status;

    this.client.acceptOrRefuseSharedRecipe(data).then((res) => {
      if (res.data.error === 0) {
        this.setState({
          ...this.state,
          loading: false,
          data: {
            "received": copyRecipe
          }
        });

        // update homa page
        this.props.dispatch(reloadHome(true));

      } else {
        copyRecipe[index]['confirm'] = 0;
        this.setState({
          ...this.state,
          loading: false,
          data: {
            "received": copyRecipe
          }
        });
      }
    });

  }

  _onClose = () => {
    this.setState({
      ...this.state,
      isModal: {
        isOpen: false
      },
    });
    this._getShareRecipe();
  }

  _getShareRecipe = () => {
    this.client.getShareRecipe().then((res) => {
      this.setState({
        data: {
          "received": res.data.received
        }
      });
    })
  }


  render() {
    return (
      <div>
        {/* display icon share */}
        <ShareRecipeView
          data={this.state.data}
          _toggleModal={this._toggleModal}
        />

        <ModalShapeRecipeView
          open={this.state.isModal.isOpen}
          recipes={this.state.data.received}
          _toggleModal={this._toggleModal}
          _handleConfirmOrNotShareRecipe={this._handleConfirmOrNotShareRecipe}
          _onClose={this._onClose}
          loading={this.state.loading}
        />
      </div>

    );
  }
};

AppShareRecipe = connect()(AppShareRecipe);

export default AppShareRecipe;