import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UserProfileCredential from '../container/UserProfileCredential';
import UserProfileInformation from '../container/UserProfileInformation';
import UserProfilePictureView from './UserProfilePictureView';
import { Divider, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({  
      title: {
        flexGrow: 1,
        fontWeight: 700
      },
      container: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      paper: {
        padding: theme.spacing(2),
        boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        marginTop: '-10px',
        background: '#ffffff',
      },
      toolbar: theme.mixins.toolbar,
      mbr145: {
        marginTop: '24px',
        marginBottom: '34px'
      }
}));

const UserProfileAccountView = (props) => {
    const classes = useStyles();
    
    return (
            <main fixed="true" className={classes.container}>

                <div className={classes.toolbar} />

                <Grid container spacing={3}>
                    <Grid item lg={12}>
                        <Typography variant="h5" className={classes.title}>
                            Mes informations
                        </Typography>
                    </Grid>
                </Grid>

                <Divider className={classes.mbr145} />

                <Grid container spacing={3}>
                    <Grid item lg={12}>
                        <UserProfilePictureView 
                            authorPictureUrl={props.authorPictureUrl}
                            _handleSelectedFile={props._handleSelectedFile}
                            _deleteAuthorPicture={props._deleteAuthorPicture}
                        />
                    </Grid>
                </Grid>

                <Paper className={classes.paper} elevation={0}>
                    <UserProfileCredential 
                        email={props.email}
                    />
                </Paper>


                <Grid container lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserProfileInformation 
                            name={props.name}
                            firstname={props.firstname}
                            pseudo={props.pseudo}
                        />
                    </Grid>
                </Grid>

            </main>
    )
}

export default UserProfileAccountView;