import React from 'react';
import { Box, CardContent, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

export default function MoreOptionsView({
    index,
    id_recipe,
    link,
    addOrNotCourse,
    disabledCourse,
    _handleDeleteRecipe, 
    _handleMoreOptions,
    _deleteCart,
    _addCart,
    _toggleCopyRecipe,
    _toggleDatePickerRecipe
}) {

    const classes = useStyles();


    return (
        <Box component="div" className={classes.boxMoreOptions} >
            <CardContent className={classes.cardContentMoreOptions}>
                <List component="nav" className={classes.root} aria-label="mailbox folders">

                    <ListItem button onClick={() => _handleDeleteRecipe(index)}>
                        <ListItemIcon>
                            <DeleteOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="supprimer" />
                    </ListItem>

                    <Divider variant="middle" />

                    <Link component="button" to={`/mes-recettes/update/${id_recipe}`} className={classes.textLink}>
                        <ListItem>
                            <ListItemIcon>
                                <EditOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="éditer" />
                        </ListItem>
                    </Link>
                    
                    <Divider variant="middle" />

                    <ListItem button onClick={() => _toggleCopyRecipe(index)}>
                        <ListItemIcon>
                            <FileCopyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="copier" />
                    </ListItem>

                    <Divider variant="middle" />

                    <Link component="button" to={`/share/inbox/${id_recipe}`} className={classes.textLink}>
                        <ListItem>
                            <ListItemIcon>
                                <ShareIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="partager" />
                        </ListItem>
                    </Link>

                    {!link &&
                        <>
                            {addOrNotCourse.status ? (
                                <ListItem button onClick={() => _deleteCart(addOrNotCourse.id_course, index)}>
                                    <ListItemIcon>
                                        <RemoveShoppingCartOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="liste de course" />
                                </ListItem>
                            ) : (
                                <ListItem button onClick={() => _addCart(index)} disabled={disabledCourse}>
                                    <ListItemIcon>
                                        <AddShoppingCartOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="liste de course" />
                                </ListItem>
                            )}
                        </>
                    }

                    <ListItem button onClick={() => _toggleDatePickerRecipe(index)}>
                        <ListItemIcon>
                            <CalendarTodayIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary="calendrier" />
                    </ListItem>

                </List>
            </CardContent>

            <Box component="div" className={classes.closeMoreOptions}>
                <IconButton aria-label="close more options" onClick={() => _handleMoreOptions("")}>
                    <HighlightOffIcon fontSize="large" />
                </IconButton>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 220,
    },
    boxMoreOptions: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 999,
        opacity: '.98',
        display: 'flex',
        flexDirection: 'column'
    },
    cardContentMoreOptions: {
        flexGrow: 1
    },
    closeMoreOptions: {
        textAlign: 'center'
    },
    textLink: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: '#000'
        }
    }
  }));