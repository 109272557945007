import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import PictureRecipe from '../container/PictureRecipe';
import Title from '../container/Title';
import { makeStyles } from '@material-ui/core/styles';
import NotationView from './NotationView';
import TimeWorking from '../container/TimeWorking';
import TimeCooking from '../container/TimeCooking';
import TimeRest from '../container/TimeRest';
import ShareRecipe from '../container/ShareRecipe';
import ItemRecipe from '../container/ItemRecipe/ItemRecipe';
import StepRecipe from '../container/StepRecipe/StepRecipe';
import NoteView from './NoteView';
import ButtonSubmitRecipeView from './ButtonSubmitRecipeView';
import Category from '../Category';
import ButtonChooseKindWriteView from './ButtonChooseKindWrite';
import CreateRecipeLinkView from './CreateRecipeLinkView';

export default function CreateRecipeWriteView({state}){


    
    const classes = useStyles();

    return(
        <Grid>
            {/* TITLE */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper} elevation={0}>
                    <Title 
                        _fetchTitle={state._fetchTitle}
                        title={state.title}
                    />
                </Paper>
            </Grid>

            {/* PICTURE */}
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    <PictureRecipe 
                        _fetchPicture={state._fetchPicture}
                        picture={state.picture}
                    />
                </Paper>
            </Grid>

            {/* DIFFICULTY */}
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    <NotationView
                        _fetchDifficulty={state._fetchDifficulty}
                        difficulty={state.difficulty}
                    />
                </Paper>
            </Grid>
            
            {/* CATEGORY */}
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    <Category
                        idTheme={state.idTheme}
                        _fetchCategory={state._fetchCategory}
                        collection_category={state.dataFormRecipe.collection_category}
                    />
                </Paper>
            </Grid>

            {/* CHHOSE KIND OF CONTENT (CREATE OR LINK WEBSITE) */}
            {!state.isUpdate.status &&
                <Grid item xs={12}>
                    <ButtonChooseKindWriteView 
                        kindRecipe={state.kindRecipe}
                        _handleKindRecipe={state._handleKindRecipe}
                        state={state}
                    />
                </Grid>
            }


            {/* TIME WORKING */}
            { state.kindRecipe === "write" ? (
                <>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}  elevation={0}>
                            <TimeWorking 
                                _fetchTimeWorkingHour={state._fetchTimeWorkingHour}
                                _fetchTimeWorkingMinute={state._fetchTimeWorkingMinute}
                                timeWorkingHour={state.timeWorkingHour}
                                timeWorkingMinute={state.timeWorkingMinute}
                            />
                        </Paper>
                    </Grid>

                    {/* TIME COOKING */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <TimeCooking 
                                _fetchTimeCookingHour={state._fetchTimeCookingHour}
                                _fetchTimeCookingMinute={state._fetchTimeCookingMinute}
                                timeCookingHour={state.timeCookingHour}
                                timeCookingMinute={state.timeCookingMinute}
                            />
                        </Paper>
                    </Grid>

                    {/* TIME RESTING */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <TimeRest 
                                _fetchTimeRestingHour={state._fetchTimeRestingHour}
                                _fetchTimeRestingMinute={state._fetchTimeRestingMinute}
                                timeRestingHour={state.timeRestingHour}
                                timeRestingMinute={state.timeRestingMinute}
                            />
                        </Paper>
                    </Grid>

                    {/* SHARE RECIPE */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <ShareRecipe 
                                _fetchShare={state._fetchShare}
                                share={state.share}
                            />
                        </Paper>
                    </Grid>

                    {/* ITEM RECIPE */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <ItemRecipe 
                                _fetchItems={state._fetchItems}
                                _fetchCourse={state._fetchCourse}
                                items={state.items}
                                addOrNotCourse={state.addOrNotCourse}
                            />
                        </Paper>
                    </Grid>

                    {/* STEP RECIPE */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <StepRecipe
                                _fetchSteps={state._fetchSteps}
                                steps={state.steps}
                            />
                        </Paper>
                    </Grid>

                    {/* NOTE VIEW */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <NoteView 
                                note={state.note}
                                _fetchEditor={state._fetchEditor}
                            />
                        </Paper>
                    </Grid>
                </>
            ) : (
                <>
                    <CreateRecipeLinkView 
                        state={state}
                    />
                </>
            )}

            {/* BUTTON SUBMIT */}
            <Grid item xs={12}>
                <ButtonSubmitRecipeView
                    dataFormRecipe={state.dataFormRecipe}
                    _submitFormRecipe={state._submitFormRecipe}
                    isUpdate={state.isUpdate}
                    isLoadingSubmitRecipe={state.isLoadingSubmitRecipe}
                    isLoadingCircular={state.isLoadingCircular}
                    kindRecipe={state.kindRecipe}
                />
            </Grid>

        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },    
    title: {
      flexGrow: 1,
      fontWeight: 700
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
      marginTop: '24px',
      marginBottom: '34px'
    },
    paper: {
        padding: theme.spacing(2),
        background: '#ffffff',
        marginBottom: '20px'
      },
    breadCrumb: {
        '& li a': {
            color: 'rgba(0, 0, 0, 0.54)',
            textDecoration: 'none'
        },
        marginBottom: '5px',
    },
    buttonsChoiceCreate: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));