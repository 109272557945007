import React from 'react'
import CategorySelectedView from "../view/CategoryListSelectedView";

const CategorySelected = ({ idTheme, collection_category }) => {

    return <CategorySelectedView 
                collection_category={collection_category}
            />
}

export default CategorySelected;