import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';
import StepTextarea from '../../container/StepRecipe/StepTextarea';
import {sortableHandle, SortableElement, SortableContainer} from 'react-sortable-hoc';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';

/* DISPLAYING TITLE */
function StepTitle(props) {
    const classes = useStyles();

    return (
        <List className={classes.root} component="div">
            <ListItem>
                <ListItemText primary={props.title} className={classes.title} />
                    <IconButton 
                        className={classes.buttonEdit}
                        onClick={() => props._updateTitle(props.index)}
                        aria-label="edit"
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        className={classes.buttonDelete}
                        onClick={() => props._removeTitle(props.index)}
                        aria-label="delete"
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
            </ListItem>
        </List>
    );
}

/* DISPLAYING UPDATE TITLE */
function StepTitleUpdate(props) {
    const classes = useStyles();
    const [title, setTitle] = useState(props.title);

    const _onChangeUpdateTitle = (evt) => {
        setTitle(evt.target.value)
    }

    return (
        <List component="nav">
            <ListItem className={classes.inputText}>
                <TextField
                    fullWidth 
                    id="outlined-title-step-update" 
                    value={title} 
                    variant="outlined" 
                    onChange={_onChangeUpdateTitle}
                    label="Titre" 
                />
                <IconButton 
                    className={classes.buttonEditUpdateTitle} 
                    onClick={() => props._onChangeUpdateTitle(title)}
                    size="small"
                    aria-label="edit"
                >
                    <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton
                    className={classes.buttonDeleteUpdateTitle}
                    onClick={props._onCancelUpdateTitle}
                    size="small"
                    aria-label="cancel
                ">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </ListItem>
        </List>
    );
}

/* DISPLAYING STEP */
const StepList = SortableElement((props) => {
    const classes = useStyles();
    const DragHandle = sortableHandle(() => <OpenWithIcon fontSize="small" className={classes.dragHandle} />);

    if (props.updateStep.status && (props.updateStep.indexList === props.idList) && (props.updateStep.indexStep === props.idStep)) {
        return (
            <StepListUpdate 
                step={props.listStep} 
                _cancelUpdateStep={props._cancelUpdateStep}
                _onChangeUpdateStep={props._onChangeUpdateStep}
            />
        );
    }
    return (
        <div>
            { props.title ? (
                <List component="nav">
                    <ListItem>
                        <ListItemIcon>
                            <DragHandle />
                        </ListItemIcon>
                        <ListItemText primary={props.listStep}  />
                        <IconButton
                            className={classes.buttonEdit}
                            onClick={() => props._updateStep(props.idList, props.idStep)}
                            aria-label="edit"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                            onClick={() => props._onRemoveStep(props.idList, props.idStep)}
                            aria-label="delete"
                            className={classes.buttonDelete}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </ListItem>         
                </List>
            ) : (
                <List component="nav">
                    <ListItem>
                        <ListItemText primary={props.listStep}  />
                        <IconButton
                            className={classes.buttonEdit}
                            onClick={() => props._updateStep(props.idList, props.idStep)}
                            aria-label="edit"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                            onClick={() => props._onRemoveStep(props.idList, props.idStep)}
                            aria-label="delete"
                            className={classes.buttonDelete}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </ListItem>         
                </List>
            )}

        </div>
    );
})

/* DISPLAYING UPDATE STEP */
function StepListUpdate(props) {
    const classes = useStyles();
    const [step, setStep] = useState(props.step);

    const _handleChangeStep = (evt) => {
        setStep(evt.target.value)
    }

    return (
        <List component="nav">
            <ListItem className={classes.inputText}>
                <TextField 
                    fullWidth 
                    id="outlined-step-to-update" 
                    variant="outlined"
                    value={step}
                    onChange={_handleChangeStep}
                    multiline
                    rows="10"
                />
                <IconButton 
                    className={classes.buttonEditUpdateStep} 
                    onClick={() => props._onChangeUpdateStep(step)} 
                    size="small" 
                    aria-label="edit"
                >
                    <DoneIcon />
                </IconButton>
                <IconButton
                    className={classes.buttonDeleteUpdateStep}
                    onClick={props._cancelUpdateStep}
                    size="small"
                    aria-label="cancel"
                >
                    <CloseIcon />
                </IconButton>
            </ListItem>
        </List>
    );
}


const StepView = (props) => {

    const classes = useStyles();
    const DragHandle = sortableHandle(() => <OpenWithIcon className={classes.dragHandleMain} />);

    const SortableContainerGroupStep = SortableContainer(({children, collection}) => {
        return <div>{children}</div>;
    })

    let title;

    // if title
    if (props.step.title) {
        // if update title
        if (props.updateTitle.status && (props.updateTitle.index === props.idList)){
            title = <StepTitleUpdate 
                        title={props.step.title}
                        index={props.idList}
                        _onChangeUpdateTitle={props._onChangeUpdateTitle}
                        _onCancelUpdateTitle={props._onCancelUpdateTitle}
                    />
        }
        // displaying title
        else {
            title =  <StepTitle 
                        title={props.step.title}
                        index={props.idList}
                        _removeTitle={props._removeTitle}
                        _updateTitle={props._updateTitle}
                    />
        }
    }


    return (
        <div className={classes.blockStep}>
            <DragHandle />

            <div className={classes.rootStep}>
                {title}
                
                {/* affichage des étapes */}
                <SortableContainerGroupStep onSortEnd={props._onSortStepList} useDragHandle>
                    {props.step.listSteps.map((listStep, index) => (
                        <StepList 
                            title={title}
                            key={index}
                            index={index}
                            idStep={index}
                            idList={props.idList}
                            listStep={listStep}
                            collection={props.idList}
                            updateStep={props.updateStep}
                            _updateStep={props._updateStep}
                            _cancelUpdateStep={props._cancelUpdateStep}
                            _onChangeUpdateStep={props._onChangeUpdateStep}
                            _onRemoveStep={props._onRemoveStep}
                            _onSortStepList={props._onSortStepList}
                        />
                    ))}
                </SortableContainerGroupStep>
            </div>

            {/* affichage du textarea pour ajouter une étape dans sa partie seulement pour les groupes d'étapes */}
            { title && 
                <div className={classes.rootAddStep}>
                    <StepTextarea 
                        _addStepToList={props._addStepToList}
                        idList={props.idList}
                    />
                </div>
            }

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        marginBottom: '0px',
      },
      buttonEditUpdateTitle: {
        position: 'absolute',
        top: '-25px',
        right: '40px'
      },
      buttonDeleteUpdateTitle: {
        position: 'absolute',
        top: '-25px',
        right: '0px'
      },
      buttonEditUpdateStep: {
        position: 'absolute',
        top: '-25px',
        right: '40px'
      },
      buttonDeleteUpdateStep: {
        position: 'absolute',
        top: '-25px',
        right: '0px'
      },
      dragHandleMain: {
        position: 'absolute',
        marginTop: '-22px',
        marginLeft: '-23px',
        zIndex: '1',
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.74)'
        },
        color: 'rgba(0, 0, 0, 0.54)'
      },
      dragHandle: {
          position: 'absolute',
          marginTop: '-10px',
          marginLeft: '0px',
          zIndex: '1',
          '&:hover': {
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.74)'
          },
          color: 'rgba(0, 0, 0, 0.54)'
      },
      inputText: {
          paddingRight: '0px',
          paddingLeft: '0px',
          marginTop: '15px'
      },
      rootStep: {
          marginBottom: '5px',
      },
      rootAddStep: {
          marginBottom: '25px'
      },
      blockStep: {
          border: '1px solid #ddd',
          padding: '10px',
          borderRadius: '4px',
          boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
          marginBottom: '20px'
      },
      title: {
          '& span': {
              fontWeight: 'bold'
          }
      }
}))

const Step = SortableElement(StepView);

export default Step;