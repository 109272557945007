import moment from 'moment'
import 'moment/locale/fr'



export function emailFormat(email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email))
    {
      return (true)
    }

      return (false)
}

export function formatDate(date, type, lang = "fr") {
    if (date != null) {
        if (lang === "fr") {
            // 27/01/2020
            if (type === "short_date") {
                return moment(date).format('DD/MM/YYYY');
            }

            // 27/01/2020 (21:00:00)
            if (type === "short_date_w_time") {
                return moment(date).format('DD/MM/YYYY (H:mm:ss)')
            }

            // 27/01/2020 - 21h00
            if (type === "short_date_w_hyphen") {
              return moment(date).format('DD/MM/YY - H:mm')
            }

            // 27 janvier 2020
            if (type === "long_date") {
              return moment(date).locale('fr').format('LL')
            }

            // 21h00
            if (type === "time") {
              return moment(date).locale('fr').format('LT')
            }
        }
    }
}

export function urlFormat(str) {
    var regex = /[- )(]/g;
    return str.replace(regex, "-");
}

export function firstLetter(str) {
    return str.substring(0, 1);
}

export function ucFirst(str) {
    if (str.length > 0) {
        return str[0].toUpperCase() + str.substring(1);
      } else {
        return str;
    }
}

export function removeAccents(str) {
    var accents    = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) !== -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
}

export function sinPlu(total, name) {
  if (total > 1) {
    return name + 's';
  } else {
    return name
  }
}