import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Client from '../../../Client';
import TopBar from '../Templates/Topbar/container/Topbar';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import AppFlashMessage from '../../../components/FlashMessage/container/AppFlashMessage';

const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Arial', 
        'Helvetica', 
        'sans-serif',
      ].join(','),
    },
    overrides: {
        MuiTypography: {
            colorPrimary: {
                color: '#FE7820'
            },
        },
        MuiTooltip: {
            tooltip: {
                padding: '15px',
                fontSize: 12
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#FE7820',
                '&:hover': {
                    backgroundColor: '#f05f00'
                }
            },
        },
        MuiLinearProgress: {
            root: {
                height: '2px'
            },
            barColorPrimary: {
                backgroundColor: '#FE7820'
            },
            query: {
                backgroundColor: '#f05f00'
            },
        },
        MuiSvgIcon: {
            colorPrimary: {
                color: '#FE7820'
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: '#FE7820'
            }
        }
    },
    palette: {
        background: {
            default: "#f8f9fc" // background body
        }
    },
  });

const client = new Client();

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
}))

const PrivateRoute = ({component, ...rest}) => {
    const classes = useStyles();

    return (
        
        client.getToken() ? (
            <div className={classes.root}>
                <ThemeProvider theme={theme}>
                    <TopBar />
                    <Route {...rest} render={(props) => (
                        React.createElement(component, props)
                    )} />
                    <AppFlashMessage />
                </ThemeProvider>
            </div>
        ) : (
            <Redirect to='/login' />
        )

    );
}

export default PrivateRoute;