import { Component } from 'react';
import Client from '../../../Client';

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logout: false
        }
        this.client = new Client();
        this.client.logout();
        window.location.href = process.env.REACT_APP_URL_APP+'login';
    }

    render() {
        return null;
    }
}

export default Logout;

