import React, { Component } from 'react';
import { connect } from 'react-redux';
import Client from '../../../Client';
import MyRecipesByThemeView from '../view/MyRecipesByThemeView';
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class MyRecipesByTheme extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            id_theme: "",
            theme: "",
            categories: [],
            total: 0,
            isLoaded: true,
            errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
            }
        }

        this.client = new Client();
    }

    componentDidMount = () => {
        var id_theme = parseInt(this.props.match.params.id_theme);
        this.client.getTheme(id_theme).then(res => {
            if (res.data) {
                let theme = res.data.name;
                this.client.getAllListWithOrWithoutCatgeoryByTheme(id_theme).then(res => {
                    this.setState({
                        ...this.state,
                        id_theme,
                        theme,
                        categories: res.data.categories,
                        recipes: res.data.recipes,
                        total: res.data.total,
                        isLoaded: false
                    })
                }).catch((error) => {
                    this.setState({
                        errorAuthAPI: {
                            status: true,
                            code_status: error.response.status,
                            data: error.response.data
                        }
                    });
                });
            }
            
        }).catch(error => {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
        
    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if (this.state.isLoaded) {
			return <LinearProgressView />
		}else {
            return(
                <MyRecipesByThemeView 
                    id_theme={this.state.id_theme}
                    theme={this.state.theme}
                    pathname={this.props.location.pathname}
                    categories={this.state.categories}
                    recipes={this.state.recipes}
                    total={this.state.total}
                />
            );
        }
    }
}

MyRecipesByTheme = connect()(MyRecipesByTheme);

export default MyRecipesByTheme;