import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

import LastRecipesView from './LastRecipesView';
import MainInformationView from './MainInformationView';

import { ThreeDots } from 'react-loading-icons'
import CalendarRecipeView from './CalendarRecipeView';

function ListRecipe(props) {
    const { lastRecipes } = props;
    const classes = useStyles();

    if (!lastRecipes.length > 0) {
        return (
            <div>
                <Typography variant="body2" className={classes.mbr256}>Aucune recette ces derniers jours</Typography>
            </div>
        );
    }

    return (
        <Grid container spacing={3}>
            {lastRecipes.map((recipe, index) =>
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <LastRecipesView 
                        index={index}
                        recipe={recipe}
                    />
                </Grid>
            )}
        </Grid>
    );

}

const AppHomeView = (props) => {
    const classes = useStyles();

    const { stats, lastRecipes, load, loadSchedule, schedules } = props;

    return(
          <main fixed="true" className={classes.container}>

                <div className={classes.toolbar} />

                <Grid container spacing={3}>
                    <Grid item lg={12}>
                        <Typography variant="h5" className={classes.title}>Tableau de bord</Typography>
                    </Grid>
                </Grid>

                <Divider className={classes.mbr145} />
                
                <MainInformationView stats={stats} />

                <div style={{ marginTop: 30 }}>
                    <Grid container spacing={3}>
                        <Grid item lg={12}>
                            <Typography variant="subtitle1">Mes dernières recettes</Typography>
                        </Grid>
                    </Grid>

                    
                    {load ? (
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <ThreeDots fill="#98ff98" height="1em" width="60" style={{ marginTop: 30 }} />
                        </Grid>
                    ) : (
                        <ListRecipe lastRecipes={lastRecipes} />
                    )}
                </div>

                <div style={{ marginTop: 30 }}>
                    <Grid container spacing={3}>
                        <Grid item lg={12}>
                            <Typography variant="subtitle1">Mon calendrier</Typography>
                        </Grid>
                    </Grid>

                    {loadSchedule ? (
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <ThreeDots fill="#98ff98" height="1em" width="60" style={{ marginTop: 30 }} />
                        </Grid>
                    ) : (
                        <CalendarRecipeView loadSchedule={loadSchedule} schedules={schedules} />
                    )}
                </div>



                

          </main>
    );
}

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },    
  title: {
    flexGrow: 1,
    fontWeight: 700
  },
  toolbar: theme.mixins.toolbar,
  mbr145: {
    marginTop: '24px',
    marginBottom: '34px'
  },  
  paper: {
    padding: theme.spacing(2),
  },
  mbr897: {
    color: '#FE7820'
  },
  mbr565: {
      height: '52px',
      '& .icon-loading': {
          marginTop: '15px'
      } 
  },
  mbr567: {
      color:"#8c98a4"
  },
  mbr256: {
      textAlign: "center",
      marginTop: "30px"
  }
}));

export default AppHomeView;