import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';

const ItemAddMoreInGroupView = (props) => {
    const classes = useStyles();

    return (
        <div>
            <TextField 
                size="small" 
                fullWidth 
                label="Ajouter un ingrédient à ce groupe" 
                id="outlined-group-item" 
                value={props.item}
                variant="outlined" 
                onChange={props._handleChangeItem}
                className={classes.inputItem}
            />
            <Button 
                fullWidth 
                variant="contained" 
                onClick={props._handleSubmitItem}
                disableElevation
                className={classes.buttonAddItem}
                color="primary"
                disabled={props.isDisabled}
            >
                    Ajouter
            </Button>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonAddItem: {
        marginTop: '15px',
    }
}))

export default ItemAddMoreInGroupView;