import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
        },
    },
    marginTextField: {
        width: '60%',
        marginRight: '15px',
    },
    marginBloc: {
        /*display: 'flex',
        justifyContent: 'center',*/
        marginTop: '25px',
    },
    button: {
        marginBottom: '20px',
    }
}));

const UserProfileCredentialInformation = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h5" gutterBottom>Connexion</Typography>
                <div className={classes.marginBloc}>
                    <TextField
                        disabled
                        id="user-credential" 
                        label="Email"
                        value={props.email}
                        variant="outlined"
                        className={classes.marginTextField}
                        size="small"
                    />
                    <Button 
                        variant="contained" 
                        onClick={() => props.toggleModal('edit_email')}
                        disableElevation
                        className={classes.button}
                        color="primary"
                    >
                        Modifier
                    </Button>
                </div>

                <div className={classes.marginBloc}>
                    <TextField 
                        disabled
                        id="staticEmail"
                        label="Mot de passe"
                        defaultValue="*****"
                        variant="outlined"
                        className={classes.marginTextField}
                        size="small"
                    />
                    <Button 
                        variant="contained" 
                        onClick={() => props.toggleModal('password')}
                        disableElevation
                        className={classes.button}
                        color="primary"
                    >
                        Modifier
                    </Button>
                </div>
        </div>
    );
}

export default UserProfileCredentialInformation;