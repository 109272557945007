import React, { Component } from 'react';
import { connect } from 'react-redux';
import Client from '../../../Client';
import { addCart, removeCart } from '../../../redux/actions/cart';
import MyListRecipesView from '../view/MyListRecipesView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class MyListRecipes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            recipes: this.props.recipes,
            total: "",
            isSnackBar: {
                status: false,
                desc: ""
            },
            openDialog: false,
            isConfirmRemove: false,
            recipe: "",
            indexRecipe: "",
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			},
            moreOptions: {
                status: false,
                index: ""
            },
            isCopy: false,
            picker: {
                open: false,
                recipe_id: ""
            }
        }

        this.client = new Client();
    }

    _handleDeleteRecipe = (index) => {
        this.setState({
            ...this.state,
            openDialog: true,
            recipe: this.state.recipes[index],
            indexRecipe: index
        })
    }

    _onDeleteFavorite = (index) => {
        this.client.setUpdateFavorite(this.state.recipes[index]).then((res) => {
            if (res.data.error === 0) {

                let recipes = this.state.recipes;
                let recipe = this.state.recipes[index]
                recipe.favorite = !recipe.favorite

                this.setState({
                    ...this.state,
                    recipes: recipes
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    _onAddFavorite = (index) => {
        this.client.setUpdateFavorite(this.state.recipes[index]).then((res) => {
            if (res.data.error === 0) {

                let recipes = this.state.recipes;
                let recipe = this.state.recipes[index]
                recipe.favorite = !recipe.favorite

                this.setState({
                    ...this.state,
                    recipes: recipes
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    _deleteCart = (id_course, index) => {
        let recipeToChange = this.state.recipes[index];
        recipeToChange.addOrNotCourse.status = false;
        recipeToChange.addOrNotCourse.id_course = "";

        this.client.deleteCourse(id_course).then(res => {
            if( res.data.error === 0 ) {
                this.props.dispatch(removeCart(1));
                this.setState({
                    ...this.state
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
        
    }

    _addCart = (index) => {
        this.client.addCourse(this.state.recipes[index]).then(res => {
            if( res.data.error === 0 ) {
                this.props.dispatch(addCart(1));
                let recipeToChange = this.state.recipes[index];
                recipeToChange.addOrNotCourse.status = true;
                recipeToChange.addOrNotCourse.id_course = res.data.data.course_id;

                this.setState({
                    ...this.state
                })
            } 
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    _onCloseDialog = () => {
        this.setState({
            ...this.state,
            openDialog: !this.state.openDialog,
            recipe: "",
            indexRecipe: ""
        })
    }

    _onRemoveRecipe = (id_recipe) => {
        
        this.setState({
            ...this.state,
            isConfirmRemove: true
        });

        this.client.deleteRecipe(id_recipe).then((res) => {
            this.setState({
                ...this.state,
                isConfirmRemove: !this.state.isConfirmRemove,
                openDialog: !this.state.openDialog,
            });

            if( res.data.error === 0) {

                if (this.state.recipes[this.state.indexRecipe]['addOrNotCourse'].status) {
                    this.props.dispatch(removeCart(1));
                }
                
                let arrayRecipes = this.state.recipes;
                arrayRecipes.splice(this.state.indexRecipe, 1);

                this.setState({
                    ...this.state,
                    recipes: arrayRecipes,
                    isSnackBar: {
                        status: true,
                        desc: "SUCCESS",
                    }
                })
                
            }else {
                this.setState({
                    ...this.state,
                    isSnackBar: {
                        status: true,
                        desc: "ERROR",
                    }
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        }); 
    }

    _handleCloseSnackBar = () => {
        this.setState({
            ...this.state,
            isSnackBar: {
                status: false,
                desc: ""
            }
        })
    }

    _handleMoreOptions = (index) => {
        if ( typeof(index) == "number") {
            this.setState({
                ...this.state,
                moreOptions: {
                    status: true,
                    index
                }
            })
        } else {
            this.setState({
                ...this.state,
                moreOptions: {
                    status: false,
                    index
                }
            })
        }
    }

    _toggleCopyRecipe = (index) => {
        if (this.state.isCopy) {
            this.setState({
                ...this.state,
                isCopy: !this.state.isCopy,
                recipe: "",
                indexRecipe: ""
            })
        } else {
            this.setState({
                ...this.state,
                isCopy: !this.state.isCopy,
                recipe: this.state.recipes[index],
                indexRecipe: index
            })
        }

    }

    _copiedStatus = (data) => {
        
        this.setState({
            ...this.state,
            isCopy: !this.state.isCopy,
            isSnackBar: {
                status: true,
                desc: data.status === "error" ? "ERROR" : "SUCCESS_COPY",
            }
        })
    }

    _toggleDatePickerRecipe = (index) => {
        if ( typeof(index) === "number" ) {
            this.setState({
                ...this.state,
                picker: {
                    open: true,
                    recipe_id: this.state.recipes[index].id
                }
            })
        } else {
            this.setState({
                ...this.state,
                picker: {
                    open: false,
                    recipe_id: ""
                }
            })
        }
    }

    

    render(){
        let { recipes, recipe } = this.state;
        
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else {
            return(
                <MyListRecipesView 
                    _copiedStatus={this._copiedStatus}
                    _toggleCopyRecipe={this._toggleCopyRecipe}
                    isCopy={this.state.isCopy}
                    moreOptions={this.state.moreOptions}
                    _handleMoreOptions={this._handleMoreOptions}
                    recipes={recipes} 
                    _handleDeleteRecipe={this._handleDeleteRecipe}
                    _onDeleteFavorite={this._onDeleteFavorite}
                    _onAddFavorite={this._onAddFavorite}
                    openDialog={this.state.openDialog}
                    _onCloseDialog={this._onCloseDialog}
                    recipe={recipe}
                    _onRemoveRecipe={this._onRemoveRecipe}
                    isConfirmRemove={this.state.isConfirmRemove}
                    status={this.state.isSnackBar.status}
                    message={this.state.isSnackBar.desc}
                    _handleCloseSnackBar={this._handleCloseSnackBar}
                    _deleteCart={this._deleteCart}
                    _addCart={this._addCart}
                    _toggleDatePickerRecipe={this._toggleDatePickerRecipe}
                    picker={this.state.picker}
                />
            );
        }
    }
}

MyListRecipes = connect()(MyListRecipes);

export default MyListRecipes;