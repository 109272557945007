import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import TEXT from '../utils/translate';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SuccessFlashMessageView({content, _handleCloseFlashMessage}) {
    return (
        <Alert severity="success" onClose={_handleCloseFlashMessage}>
            {TEXT[content]}
        </Alert>
    );
}