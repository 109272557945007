const TEXT = {
    "SUCCESS_COPY_RECIPE": "Copie de votre recette effectuée avec succès !",
    "SUCCESS_INSERT_PLANNING": "Programmation de votre recette effectuée avec succès !",
    "SUCCESS_DELETE_PLANNING": "Suppression de votre recette de votre planning !",
    "SUCCESS_REMOVE_SCHEDULE": "Supprimé de votre agenda !",
    "ERROR_REMOVE_SCHEDULE": "Impossible de supprimer de l'agenda !",
    "SUCCESS_ADD_SCHEDULE": "Ajouté à l'agenda !",
    "ERROR_ADD_SCHEDULE": "Impossible d'ajouter à l'agenda !"
}

export default TEXT;