import React from 'react'
import { Box, Typography } from "@material-ui/core";

export default function CategoryTitleView(){
    return (
        <>
            <Box component="div" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" gutterBottom>Catégorie</Typography>
                <Typography variant="caption" gutterBottom style={{ fontSize: '0.75em', marginTop: '0px', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.54)' }}>(facultatif)</Typography>
            </Box>
        </>
    )
}