import React from 'react';
import { Link } from 'react-router-dom';
import { firstLetter } from '../../../utils/services';
import { urlFormat, ucFirst } from '../../../utils/services';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Divider } from '@material-ui/core';

const RecipeView = (props) => {
    const classes = useStyles();

    let timeWork = "n/a";
    let timeWorkingHour = "";
    let timeWorkingMinute = "";

    if( parseInt(props.recipe.timeWorkingHour) > 0 )
        timeWorkingHour = props.recipe.timeWorkingHour + "h";

    if( parseInt(props.recipe.timeWorkingMinute) > 0 )
        timeWorkingMinute = props.recipe.timeWorkingMinute + "min";

    if (timeWorkingHour || timeWorkingMinute)
        timeWork = timeWorkingHour + timeWorkingMinute;

    return(
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.card} elevation={0}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                        {firstLetter(props.recipe.title.toUpperCase())}
                        </Avatar>
                    }
                    title={ucFirst(props.recipe.title.toLowerCase())}
                />
                {props.recipe.link ? (
                    <CardMedia
                        className={classes.media}
                        image={props.recipe.pictureUrl}
                        component="a" 
                        href={props.recipe.link}
                        target="_blank"
                        rel="noreferrer"
                    />
                ) : (
                    <CardMedia
                        className={classes.media}
                        image={props.recipe.pictureUrl}
                        title={props.recipe.title.toUpperCase()}
                        component={Link} 
                        to={`/mes-recettes/view/${urlFormat(props.recipe.title.toLowerCase())}/${props.recipe.id}`}
                    />
                )}

                <CardContent>
                    <p className={classes.captionTime}>Temps: {timeWork}</p>
                </CardContent>

                <Divider />

                <CardActions disableSpacing className={classes.mbr123}>
                    {props.recipe.favorite &&
                        <Tooltip title="Supprimer des favoris" aria-label="del" placement="top" arrow={true}>
                            <IconButton aria-label="delete to favorites" onClick={() => props._onDeleteFavorite(props.index)}>
                                <FavoriteIcon className={classes.colorIcon} fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                </CardActions>
        </Card>
    </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    card: {
        border: '1px solid #ECECEC',
        background: '#ffffff',
        boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)'
      },
      media: {
        /*width: 300,*/
        paddingTop: '56.25%', // 16:9,
      },
      avatar: {
        backgroundColor: '#FE7820',
      },
      colorIcon: {
          color: '#FE7820'
      },
      captionTime: {
          margin: 0
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      mbr123: {
          padding: '5px'
      },
  }));

export default RecipeView;