import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
    },
    popover: {
        marginTop: '40px',
    },
    textPopper: {
        margin: 0,
    },
    listItem: {
        padding: '10px 15px',
        borderRadius: '3px',
        '&:hover': {
          background: '#FE7820',
          color: '#FFFFFF'
        },
    },
    avatar: {
        '&:hover': {
            cursor: 'pointer'
        },
        background: 'rgb(158, 158, 158)'
    }
  }));

const AuthorPictureView = (props) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <div>
            <Avatar 
                alt="" 
                src={ props.authorPicture }
                className={classes.avatar}
                aria-describedby={id} 
                onClick={handleClick}
            />
            <Popover 
                id={id} 
                open={open} 
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popover}
            >
                <div className={classes.paper}>
                    <ListItem button component={Link} to="/mon-compte" onClick={handleClose} className={classes.listItem}>
                        <p className={classes.textPopper}>Paramètre du compte</p>
                    </ListItem>

                    {/* 
                    <ListItem button component={Link} to="/mon-support" onClick={handleClose} className={classes.listItem}>
                        <p className={classes.textPopper}>Mon support</p>
                    </ListItem>
                    */}

                    <ListItem button component={Link} to="/contacts" onClick={handleClose} className={classes.listItem}>
                        <p className={classes.textPopper}>Nous contacter</p>
                    </ListItem>

                    <Divider />
                    
                    <ListItem button component={Link} to="/logout" className={classes.listItem}>
                        <p className={classes.textPopper}>Se déconnecter</p>
                    </ListItem>
                </div>
            </Popover>
        </div>
    );
};

export default AuthorPictureView;