import React, { Component } from 'react';
import ItemAddMoreInGroupView from '../../view/itemRecipe/ItemAddMoreInGroupView';

class ItemAddMoreInGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: "",
            isDisabled: true
        }
    }

    _handleChangeItem = (evt) => {
        if (evt.target.value === "") {
            this.setState({
                item: evt.target.value,
                isDisabled: true
            })
        }else {
            this.setState({
                item: evt.target.value,
                isDisabled: false
            })
        }

    }

    _handleSubmitItem = () => {
        this.setState({
            item: ""
        });
        this.props._addItemToList(this.props.idList, this.state.item);
    }

    render(){
        return(
            <ItemAddMoreInGroupView 
                item={this.state.item}
                isDisabled={this.state.isDisabled}
                _handleChangeItem={this._handleChangeItem}
                _handleSubmitItem={this._handleSubmitItem}
            />     
        );
    }
}

export default ItemAddMoreInGroup;