import React from 'react';
import TextField from '@material-ui/core/TextField';

const CreateRecipeLinkInputView = (props) => {

    return(
        <div>
            <TextField 
                required
                fullWidth
                id="link-recipe" 
                label="Lien de la recette" 
                variant="outlined"
                onChange={props._handleChange}
                value={props.linkRecipe}
                helperText="Url du site"
            />
        </div>
    );
}

export default CreateRecipeLinkInputView;
