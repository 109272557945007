/**
 * Component User
 * 
 * This component authorize user to modify his account 
 * What can he modify ?
 * - email
 * - password
 * - pseudo
 * 
 * @author [Mickael Mercier]
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Client from '../../../Client';
import UserProfileAccountView from '../view/UserProfileAccountView';
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import { pictureUser } from '../../../redux/actions/user';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';


class AppUserProfileAccount extends Component {

	constructor(props) {
		super(props);

		this.state = { 
			email: "",
			name: "",
			firstname: "",
			pseudo: "",
			internalServerError: false,
			authorPictureUrl: "",
			isLoaded: true,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
		};

		this.client = new Client();
	}

    componentDidMount = () => {

		this.client.getUser().then(res => {
			this.setState({
				email: res.data.email,
				name: res.data.name,
				firstname: res.data.firstname,
				pseudo: res.data.pseudo,
				authorPictureUrl: res.data.authorPictureUrl,
				isLoaded: false
			})
		}).catch(error =>  {
			this.setState({
				errorAuthAPI: {
					status: true,
					code_status: error.response.status,
					data: error.response.data
				}
			});
		});
	}

	_loadAuthorPictureUrl = (fileName) => {
		this.setState({
			...this.state,
			authorPictureUrl: fileName
		})
	}

	_handleSelectedFile = (event) => {
		const data = new FormData();
		data.append('file', event.target.files[0], event.target.files[0].name);

		this.client.pictureUser(data).then(res => {
			this._loadAuthorPictureUrl(res.data.desc);
			this.props.dispatch(pictureUser(res.data.desc));
		}).catch(error =>  {
			this.setState({
				errorAuthAPI: {
					status: true,
					code_status: error.response.status,
					data: error.response.data
				}
			});
		});
	}

	_deleteAuthorPicture = () => {
		this.client.removePictureUser().then(res => {
			if (res.data.error === 0) {
				this._loadAuthorPictureUrl("");
			}
		}).catch(error =>  {
			this.setState({
				errorAuthAPI: {
					status: true,
					code_status: error.response.status,
					data: error.response.data
				}
			});
		});
	}

	render() {
		if (this.state.errorAuthAPI.status) {
			return <DialogError error={this.state.errorAuthAPI} />
		}
		else if (this.state.isLoaded) {
			return(
				<LinearProgressView />
			);
		}else {
			return (
				<UserProfileAccountView
					authorPictureUrl={this.state.authorPictureUrl}
					email={this.state.email}
					name={this.state.name}
					firstname={this.state.firstname}
					pseudo={this.state.pseudo}
					_handleSelectedFile={this._handleSelectedFile}
					_deleteAuthorPicture={this._deleteAuthorPicture}
				/>
			);
		}

	}
	
}

AppUserProfileAccount = connect()(AppUserProfileAccount);

export default AppUserProfileAccount;