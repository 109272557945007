import React, { Component } from 'react';
import TitleView from '../view/TitleView';

class Title extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title : this.props.title || ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.title !== this.state.title ) {
            this.props._fetchTitle(this.state.title);
        }
    }

    _handleChange = (event) => {
        this.setState({
            ...this.state,
            title: event.target.value
        });
    }

    render(){
        return(
            <TitleView 
                _handleChange={this._handleChange}
                titleRecipe={this.state.title}
             />
        );

    }
}

export default Title;