import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
        },
    },
    marginTextField: {
        marginRight: '15px',
    },
    marginBloc: {
        marginTop: '25px',
        marginBottom: '25px'
    },
    button: {
        marginBottom: '20px',
    }
}));

const UserProfileInformation = (props) => {
    const classes = useStyles();

    let error_pseudo; 

    if (props.ERROR_FORM.PSEUDO) {
        error_pseudo = "Le nom est obligatoire";
    }

    return (
        <div>
            <h2 className="heading-2 heading-bordered">Information du profil</h2>
            <div className={classes.marginBloc}>
                <TextField
                    id="user-profile-name" 
                    label="Nom"
                    value={props.name}
                    variant="outlined"
                    onChange={props._onChangeName}
                    className={classes.marginTextField}
                    size="small"
                    fullWidth
                />
            </div>
            <div className={classes.marginBloc}>
                <TextField
                    id="user-profile-firstname" 
                    label="Prénom"
                    value={props.firstname}
                    variant="outlined"
                    onChange={props._onChangeFirstname}
                    className={classes.marginTextField}
                    size="small"
                    fullWidth
                    helperText="Aidez les gens à trouver plus facilement votre compte à l'aide de votre nom et prénom si vous souhaitez partager vos recettes."
                />
            </div>
            <div className={classes.marginBloc}>
                <TextField
                    id="user-profile-pseudo" 
                    label="Pseudo"
                    value={props.pseudo}
                    variant="outlined"
                    onChange={props._onChangePseudo}
                    className={classes.marginTextField}
                    size="small"
                    required
                    error={props.ERROR_FORM.PSEUDO}
                    helperText={error_pseudo}
                    fullWidth
                />
            </div>
            <div className={classes.marginBloc}>
                <Button 
                    variant="contained" 
                    onClick={props._onFormSubmit} 
                    disabled={props.isRequest}
                    disableElevation
                    className={classes.button}
                    color="primary"
                >
                    modifier
                </Button>
            </div>
        </div>
    );
}

export default UserProfileInformation;