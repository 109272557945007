import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Avatar, CardMedia, CardContent, Typography, Divider, CardActions, Tooltip, IconButton, Box, CardActionArea } from '@material-ui/core';
import { firstLetter, ucFirst, urlFormat, removeAccents } from '../../../utils/services';
import Rating from '@material-ui/lab/Rating';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreOptionsView from './MyRecipeMoreOptionsView';
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined';



const MyListRecipeView = (props) => {
    const classes = useStyles();

    let timeWork = "n/a";
    let timeWorkingHour = "";
    let timeWorkingMinute = "";
    let disabledCourse = false;

    if( parseInt(props.recipe.timeWorkingHour) > 0 )
        timeWorkingHour = props.recipe.timeWorkingHour + "h";

    if( parseInt(props.recipe.timeWorkingMinute) > 0 )
        timeWorkingMinute = props.recipe.timeWorkingMinute + "min";

    if (timeWorkingHour || timeWorkingMinute)
        timeWork = timeWorkingHour + timeWorkingMinute;

    if(props.recipe.items){
        if (!props.recipe.items.length > 0) {
            disabledCourse = true;
        }
    }else {
        disabledCourse = true;
    }

    return (
        <Card 
            className={classes.card} 
            elevation={0}
        >
            
            {/* display more options if button clicked */}
            {props.moreOptions.status &&
                <>
                    {props.moreOptions.index === props.index &&
                        <MoreOptionsView 
                            index={props.index}
                            _handleMoreOptions={props._handleMoreOptions}
                            _handleDeleteRecipe={props._handleDeleteRecipe}
                            id_recipe={props.recipe.id}
                            link={props.recipe.link}
                            addOrNotCourse={props.recipe.addOrNotCourse}
                            _addCart={props._addCart}
                            _deleteCart={props._deleteCart}
                            disabledCourse={disabledCourse}
                            _toggleCopyRecipe={props._toggleCopyRecipe}
                            _toggleDatePickerRecipe={props._toggleDatePickerRecipe}
                        />
                    }
                </>
            }


            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                    {firstLetter(props.recipe.title.toUpperCase())}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings" onClick={() => props._handleMoreOptions(props.index)}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                title={ucFirst(props.recipe.title.toLowerCase())}
            />

            {props.recipe.link ? (
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={props.recipe.pictureUrl}
                        component="a" 
                        href={props.recipe.link}
                        target="_blank"
                        rel="noreferrer"
                    />
                </CardActionArea>
            ) : (
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={props.recipe.pictureUrl}
                        component={Link} 
                        to={`/mes-recettes/view/${urlFormat(removeAccents(props.recipe.title.toLowerCase()))}/${props.recipe.id}`}
                    />
                </CardActionArea>
            )}


            <CardContent className={classes.rootContent}>
                <Typography variant="body1" className={classes.captionTime}>Temps: {timeWork}</Typography>
                <Rating name="difficulty" value={parseInt(props.recipe.difficulty)} readOnly  size="small" className={classes.rating} />
            </CardContent>

            <Divider />
            

            <CardActions disableSpacing className={classes.mbr123}>
                {props.recipe.favorite ? (
                    <Tooltip title="Supprimer des favoris" aria-label="del" placement="top" arrow={true}>
                        <IconButton aria-label="delete to favorites" onClick={() => props._onDeleteFavorite(props.index)} className={classes.buttonIcon}>
                            <FavoriteIcon color="primary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Ajouter aux favoris" aria-label="add" placement="top" arrow={true}>
                        <IconButton aria-label="add to favorites" onClick={() => props._onAddFavorite(props.index)} className={classes.buttonIcon}>
                            <FavoriteBorderIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}

                    <Box component="div" className={classes.iconKindRecipe}>
                        {props.recipe.link &&
                            <InsertLinkOutlinedIcon fontSize="small" />
                        }
                    </Box>



            </CardActions>
        </Card>
    );
}

const useStyles = makeStyles(theme => ({
    card: {
      position: 'relative',
      background: '#ffffff',
      boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
    },
    media: {
      /*width: 300,*/
      paddingTop: '56.25%', // 16:9,
    },
    avatar: {
      backgroundColor: '#FE7820',
    },
    captionTime: {
        margin: 0,
        flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    mbr123: {
        padding: '5px'
    },
    rootContent: {
        display: 'flex',
    },
    rating: {
        marginTop: '3px'
    },
    iconKindRecipe: {
        marginLeft: 'auto',
        marginTop: '5px'
    }
  }));

export default MyListRecipeView;