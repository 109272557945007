export const totalCart = (totalCart) => {
  return {
    type: 'TOTAL_CART',
    totalCart
  }
}

export const addCart = (addCart) => {
    return {
      type: 'ADD_CART',
      addCart
    }
}

export const removeCart = (removeCart) => {
  return {
    type: 'REMOVE_CART',
    removeCart
  }
}

export const removeIdCourse = (idCourse) => {
  return {
    type: 'REMOVE_IDCOURSE',
    idCourse
  }
}