import React from 'react'
import { useState } from 'react'
import CategoryList from './container/CategoryList'
import CategorySelected from './container/CategorySelected'
import CategoryEditButtonView from './view/CategoryEditButtonView'
import CategoryTitleView from './view/CategoryTitleView'


const Category = ({idTheme, _fetchCategory, collection_category}) => {

    const [open, setOpen] = useState(false)

    const _handleOpen = () => {
        setOpen(!open)
    }

    return (
        <>
            {/* TITLE */}
            <CategoryTitleView />

            {/* LIST CATEGORIES SELECTED */}
            <CategorySelected 
                idTheme={idTheme} 
                collection_category={collection_category} 
            />

            {/* BUTTON EDIT */}
            <CategoryEditButtonView _handleOpen={_handleOpen} />
            
            {/* LIST ALL CATEGORIES + ADD NEW CATEGORY */}
            <CategoryList 
                idTheme={idTheme} 
                _fetchCategory={_fetchCategory}
                open={open}
                _handleOpen={_handleOpen}
                collection_category={collection_category} 
            />


        </>
    )
}

export default Category