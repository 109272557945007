import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorFlashMessageView from './view/ErrorFlashMessageView';
import SuccessFlashMessageView from './view/SuccessFlashMessageView';


const FlashMessageComponent = ({
    open,
    duration,
    content,
    _handleCloseFlashMessage
}) => {

    let message;

    switch(content.alert) {
        case "ERROR":
            message = <ErrorFlashMessageView content={content.desc} _handleCloseFlashMessage={_handleCloseFlashMessage} />
        break;

        case "SUCCESS":
            message = <SuccessFlashMessageView content={content.desc} _handleCloseFlashMessage={_handleCloseFlashMessage} />
        break;
        
        default:
            message = "";
        break;
    }

    return (
        <div>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={open}
            autoHideDuration={duration}
            onClose={_handleCloseFlashMessage}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={_handleCloseFlashMessage}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }>
                {message}
            </Snackbar>
        </div>
    );
}

export default FlashMessageComponent