import React from 'react';
import UserEmailForm from '../container/UserEmailForm';
import UserPasswordForm from '../container/UserPasswordForm';

const UserProfileCredentialForm = (props) => {

    if(!props.show) {
        return null;
    }

    if (props.type === 'edit_email') {
        return (<UserEmailForm 
                            onClose={props.onClose} 
                            flashMessage={props.flashMessage}
                            dispatchAPI={props.dispatchAPI}
                            isRequest={props.isRequest}
                            isError={props.isError}
                            openDialog={props.show}
                            />
                )
    }

    if (props.type === 'password') {
        return (<UserPasswordForm 
                            onClose={props.onClose} 
                            flashMessage={props.flashMessage}
                            dispatchAPI={props.dispatchAPI}
                            isRequest={props.isRequest}
                            isError={props.isError}
                            openDialog={props.show}
                            />
                )
    }
}

export default UserProfileCredentialForm;