import React from 'react'
import { useState } from 'react';
import { useEffect } from "react";
import Client from "../../../../Client";
import CategoryListView from "../view/CategoryListView";

const CategoryList = ({
    idTheme,
    _fetchCategory,
    open,
    _handleOpen,
    collection_category
}) => {

    const client = new Client();

    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    const [collectionCategory, setCollectionCategory] = useState([])
    const [newCategory, setNewCategory] = useState({"name": ""})

    useEffect(() => {
        if (open) {
            setIsLoading(true);

            client.getListCategory(idTheme).then(res => {
                if (res.status === 200) {
                    setCategories(res.data.categories)
                    const timer = setTimeout(() => {
                        setIsLoading(false)
                    }, 500)
                    setCollectionCategory(collection_category)
                    return () => clearTimeout(timer)
                }
            }).catch(error =>  {
    
            });
            
        }

    }, [open])

    const _handleClose = () => {
        _handleOpen()
    }

    const _handleValidateCategory = () => {
        _fetchCategory(collectionCategory)
        _handleOpen()
    }

    const _handleSelectCategory = (id_category) => {

        let index = categories.findIndex(p => p.id === id_category);
        let addCategory = categories[index];

        // check if id category is already in collection category
        const isEltExist = collectionCategory.find(collectCat => collectCat.id === id_category)

        // remove element 
        if (isEltExist) {
            const curentCollectionCategoryCopy = [...collectionCategory]
            let indexElt = collectionCategory.findIndex(p => p.id === id_category)
            if (indexElt > -1) {
                curentCollectionCategoryCopy.splice(indexElt, 1)
            }
            setCollectionCategory(curentCollectionCategoryCopy)
        } 
        // add element
        else {
            setCollectionCategory([
                ...collectionCategory,
                addCategory
            ])
        }
    }

    const _handleInput = (e) => {
        setNewCategory({
            ...newCategory,
            name: e.target.value
        })
    }

    const _handleSubmitNewCategory = () => {
        let newCat = newCategory.name;
        if (newCat.trim()) {
            client.addCategory(idTheme, newCategory).then(res => {
                if (res.status === 200) {
                    const newInput = categories.concat([{"id": res.data.category.id, "name": res.data.category.name}])
                    setCategories(newInput)
                    setNewCategory({
                        ...newCategory,
                        name: ""
                    })
                }
            }).catch(error =>  {
    
            });
        }
    }

    return <CategoryListView 
                categories={categories} 
                open={open}
                _handleClose={_handleClose}
                _handleValidateCategory={_handleValidateCategory}
                _handleSelectCategory={_handleSelectCategory}
                collectionCategory={collectionCategory}
                _handleInput={_handleInput}
                _handleSubmitNewCategory={_handleSubmitNewCategory}
                newCategory={newCategory}
                isLoading={isLoading}
            />;
}

export default CategoryList;