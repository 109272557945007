import React from 'react';
import AuthorPictureView from './AuthorPictureView';
import CourseRecipe from '../../../../../components/CourseRecipe/container/AppCourseRecipe';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import SidebarView from '../../Sidebar/view/SidebarView';
import { CssBaseline } from '@material-ui/core';
import AppShareRecipe from '../../../../../components/ShareRecipe/container/AppShareRecipe'

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: 'rgb(255, 255, 255)',
    color: 'rgb(158, 158, 158)',
    display: 'flex',
    boxShadow: '0 .15rem 1.75rem #21283226!important',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    color: '#c7c7c7'
  },
  mbr253: {
    flex: '1 1 auto'
  },   
  colorCart: {
    color: 'rgb(158, 158, 158)',
  },
  icon: {
      marginRight: '15px',
  }
}));

const TopBarView = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
  };

  const handleCloseDrawer = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen)
    }
  }

    return(
        <div>
          <CssBaseline />
          
          <AppBar position="fixed" className={classes.appBar} elevation={0}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >

                <MenuIcon />

              </IconButton>

                <div className={classes.mbr253}></div>

                {/* cart */}
                {props.totalCourse > 0 && <CourseRecipe total={props.totalCourse} /> }

                {/* share */}
                <AppShareRecipe />

                {/* 
                    <Link to="/share/inbox">
                      <IconButton aria-label="cart" className={classes.icon} onClick={props._toggleModal}>
                        <Badge color="secondary" badgeContent=" " variant="dot">
                          <SendIcon className={classes.colorCart} />
                        </Badge>
                      </IconButton>
                    </Link>
                */}

                {/* profile */}
                <AuthorPictureView authorPicture={props.authorPicture} 
              />
            </Toolbar>
          </AppBar>

          <SidebarView 
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            handleCloseDrawer={handleCloseDrawer}
            locationPathname={props.locationPathname}
          />
      </div>
    );
}

export default TopBarView;