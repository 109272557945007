import React from 'react';

import { Grid, Paper, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

import CountUp from 'react-countup';
import { ThreeDots } from 'react-loading-icons'

const MainInformationView = ({stats}) => {
    const classes = useStyles();

    return (
        <Grid
        container 
        spacing={3}
        >
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper className={classes.paper}>
                    <Typography variant="subtitle1" gutterBottom className={classes.mbr567}>RECETTES</Typography>
                        { stats.length > 0 ? (
                            <Typography variant="h4" gutterBottom className={classes.mbr897}>
                                <CountUp end={stats[0].total_recipe} />
                            </Typography>
                        ) : (
                            <div className={classes.mbr565}>
                                <ThreeDots fill="#98ff98" height="1em" width="60" />
                            </div>
                        )}
                </Paper>
            </Grid>  

            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper className={classes.paper}>
                    <Typography variant="subtitle1" gutterBottom className={classes.mbr567}>RECETTES PARTAGÉES</Typography>
                        { stats.length > 0 ? (
                            <Typography variant="h4" gutterBottom className={classes.mbr897}>
                                <CountUp end={stats[0].total_share_recipe} />
                            </Typography>
                        ) : (
                            <div className={classes.mbr565}>
                                <ThreeDots fill="#98ff98" height="1em" width="60" />
                            </div>
                        )}
                </Paper>
            </Grid>  

            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper className={classes.paper}>
                    <Typography variant="subtitle1" gutterBottom className={classes.mbr567}>CATÉGORIES</Typography>
                        { stats.length > 0 ? (
                            <Typography variant="h4" gutterBottom className={classes.mbr897}>
                                <CountUp end={stats[0].total_category} />
                            </Typography>
                        ) : (
                            <div className={classes.mbr565}>
                                <ThreeDots fill="#98ff98" height="1em" width="60" />
                            </div>
                        )}
                </Paper>
            </Grid>  
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({   
    paper: {
      padding: theme.spacing(2),
    },
    mbr897: {
      color: '#FE7820'
    },
    mbr565: {
        height: '52px',
        '& .icon-loading': {
            marginTop: '15px'
        } 
    },
    mbr567: {
        color:"#8c98a4"
    },
  }));

export default MainInformationView