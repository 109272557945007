import React, { Component } from 'react';
import Client from '../../../Client';
import HistoricRecipesSharedView from '../view/HistoricRecipesSharedView';

export default class HistoricRecipesShared extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloaded: true,
            data: []
        }

        this.client = new Client();
    }

    componentDidMount = () => {
        // get historic share for specific recipe
        this.client.getHistoricShareSpecificRecipe(this.props.recipe.id_recipe).then((res) => {
            this.setState({
                isloaded: false,
                data: res.data
            });
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.successShare.status !== prevProps.successShare.status) {
            if (this.props.successShare.status) {
                this.setState({
                    ...this.state,
                    //data: [this.props.successShare.desc].concat(datas) // another way to add in first array
                    data: [this.props.successShare.desc, ...this.state.data]
                });
            }
        }
    }

    render(){
        return (
            <HistoricRecipesSharedView 
                callApiUser={this.props.callApiUser}
                successShare={this.props.successShare}
                isloaded={this.state.isloaded}
                data={this.state.data}
            />
        );
    }
}