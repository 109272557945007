import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { emailFormat } from '../../../utils/services';
import Client from '../../../Client';
import AuthenticationSignupView from '../view/AuthenticationSignupView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class AuthenticationSignup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            firstname: '',
            pseudo: '',
            email: '',
            password: '',
            passwordConfirm: '',
            loading: false,
            ERROR_FORM: {
                PSEUDO: false,
                EMAIL: false,
                PASSWORD: false,
                CONFIRM_PASSWORD: false,
                error_account: {
                    error: false,
                    desc: ""
                },
                FORMAT_EMAIL: false,
                SAME_PASSWORD: false,

            },
            errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
            },
            redirect: false,
        }

        this.client = new Client();
    }

    _handleName = (e) => {
        this.setState({ name: e.target.value });
    }

    _handleFirstname = (e) => {
        this.setState({ firstname: e.target.value });
    }

    _handlePseudo = (e) => {
        this.setState({ pseudo: e.target.value });
    }

    _handleEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    _handlePassword = (e) => {
        this.setState({ password: e.target.value });
    }

    _passwordConfirm = (e) => {
        this.setState({ passwordConfirm: e.target.value });
    }

    onLogin = (e) => {
        e.preventDefault();
        var _this = this;

        this.setState({ loading: true });

        var ERROR_FORM = {
            PSEUDO: false,
            EMAIl: false,
            PASSWORD: false,
            CONFIRM_PASSWORD: false,
            error_account: {
                error: false,
                desc: ""
            },
            FORMAT_EMAIL: false,
            SAME_PASSWORD: false
        }

        if (!this.state.pseudo) {
            ERROR_FORM.PSEUDO = true;
        }

        if (!this.state.email) {
            ERROR_FORM.EMAIL = true;
        }

        if (!this.state.password) {
            ERROR_FORM.PASSWORD = true;
        }

        if (!this.state.passwordConfirm) {
            ERROR_FORM.CONFIRM_PASSWORD = true
        }

        var handleError = Object.values(ERROR_FORM);
        var checkError = handleError.indexOf(true);

        
        if (checkError === -1) {

            if(emailFormat(this.state.email)) {
                if (this.state.password === this.state.passwordConfirm) {

                    let data = {
                        pseudo: this.state.pseudo,
                        email: this.state.email,
                        plainPassword: this.state.password,
                        confirmPlainPassword: this.state.passwordConfirm
                    }
                    
                    this.client.signup(data).then(function(res){
                        const resp = res.data

                        if (!resp.error) {
                            _this.client.setToken(resp.user.token);
                            if (_this.client.getToken()) {
                                _this.setState({
                                    redirect: true
                                });
                            }
                        }else {
                            _this.setState({
                                ..._this.state,
                                ERROR_FORM: {
                                    error_account: {
                                        error: true,
                                        desc: resp.message
                                    }
                                },
                                loading: false
                            })
                        }
                    }).catch((error) => {
                        this.setState({
                            errorAuthAPI: {
                                status: true,
                                code_status: error.response.status,
                                data: error.response.data
                            }
                        });
                    });
                    

                }else {
                    ERROR_FORM.SAME_PASSWORD = true;
                    this.setState({
                        ...this.state,
                        ERROR_FORM: ERROR_FORM,
                        loading: false
                    })
                }
            }else {
                ERROR_FORM.FORMAT_EMAIL = true;
                this.setState({
                    ...this.state,
                    ERROR_FORM: ERROR_FORM,
                    loading: false
                })
            }

        }else {
            this.setState({
                ...this.state,
                ERROR_FORM: ERROR_FORM,
                loading: false
            })
        }
    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if (this.state.redirect) {
            return <Redirect to='/' />
        }else {
            return(
                <AuthenticationSignupView 
                    state={this.state}
                    _handlePseudo={this._handlePseudo}
                    _handleEmail={this._handleEmail}
                    _handlePassword={this._handlePassword}
                    _passwordConfirm={this._passwordConfirm}
                    onLogin={this.onLogin}
                    ERROR_FORM={this.state.ERROR_FORM}
                />
            );
        }
    }
}

function mapsStateToProps(state) {
    return {
        userInfo: state.logUser
    }
}

AuthenticationSignup = connect(mapsStateToProps)(AuthenticationSignup);

export default AuthenticationSignup;