import { Avatar, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import React from 'react';

import { formatDate, removeAccents, ucFirst, urlFormat } from '../../../utils/services';

import { Link } from 'react-router-dom';

const CalendarRecipeView = ({schedules}) => {

    const classes = useStyles();

    if (schedules.length > 0) {
        return (
            <Grid container spacing={3}>
                {schedules.map((schedule) => 
                    <Grid item xs={12} sm={6} md={4} lg={3} key={schedule.id}>
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar} alt={schedule.recipe.title.toUpperCase()} src="/" />
                                }
                                title={ucFirst(schedule.recipe.title.toLowerCase())}
                            />

                            <CardMedia
                                className={classes.media}
                                image={schedule.recipe.picture}
                                title={schedule.recipe.title.toUpperCase()}
                                component={Link} 
                                to={`/mes-recettes/view/${urlFormat(removeAccents(schedule.recipe.title.toLowerCase()))}/${schedule.recipe.id_recipe}`}
                            />

                            <CardContent className={classes.rootContent}>
                                <Typography variant="body1">{formatDate(schedule.schedule, "long_date")} - {formatDate(schedule.schedule, "time")}</Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                )}
            </Grid>
        )
    }
    
    return (
        <div>
            <Typography variant="body2" className={classes.mbr256}>Aucune recette programmée</Typography>
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    card: {
      /*maxWidth: 345,*/
      boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
      background: '#ffffff',
    },
    media: {
      /*width: 300,*/
      paddingTop: '56.25%', // 16:9,
    },
    avatar: {
      backgroundColor: '#FE7820',
    },
    rootContent: {
        display: 'flex',
    },
    mbr256: {
        textAlign: "center",
        marginTop: "30px"
    }
  }));

export default CalendarRecipeView