import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const ModalSubmitRecipeView = (props) => {

    const classes = useStyles();

    let text_title, text_caption = "";

    const _handleClose = () => {
        window.location.href = '/';
    }

    const _handleGoRecipe = () => {
        window.location.href = '/mes-recettes/view/'+props.title_recipe+'/'+props.id_recipe;
    }

    if (props.message === "RECIPE_CREATE_SUCCESSFULLY") {
        text_title = 'Création de la recette';
        text_caption = 'Création de la recette confirmée !';
    } else {
        text_title = 'Modification de la recette';
        text_caption = 'Modification de la recette confirmée !';
    }

    return (
        <Dialog open={true} aria-labelledby="simple-dialog-title">
            <DialogTitle id="alert-dialog-title">{text_title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text_caption}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.buttonActions}>
                <Button 
                    onClick={_handleClose} 
                    disableElevation
                    variant="contained"
                    color="primary"
                >
                    Accueil
                </Button>
                {props.link ? (
                    <Link  
                        component="button"
                        onClick={() => {
                            window.location.href = props.link
                        }} 
                        disableElevation
                        variant="contained"
                        color="primary"
                        className={classes.linkButton}
                    >
                        Voir la recette
                    </Link>
                ) : (
                    <Button 
                        onClick={_handleGoRecipe} 
                        disableElevation
                        variant="contained"
                        color="primary"
                    >
                        Voir la recette
                    </Button>
                )}

            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    buttonActions: {
        justifyContent: 'space-between'
    },
    linkButton: {
        color: '#fff',
        backgroundColor: '#FE7820',
        padding: '6px 16px',
        textTransform: 'uppercase',
        borderRadius: '4px',
        fontWeight: 500,
        lineHeight: '1.75',
        '&:hover': {
            textDecoration: 'none'
        }
    }
  }));

export default ModalSubmitRecipeView