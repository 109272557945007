import React from 'react';
import { ucFirst } from '../../../utils/services';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttonVizualize: {
        marginRight: '15px',
    },
  }));

const ListCourseRecipeView = (props) => {
    const classes = useStyles();

    let recipes = props.recipes;

    return (
        <List>
            {recipes.map((recipe, index) => {
                return (
                    <div key={index}>
                        <ListItem>
                            {/* 
                            <ListItemText primary={ucFirst(recipe.title)} /> ({recipe.nb_items})
                            */}
                            <ListItemText>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="textPrimary"
                                >
                                    {ucFirst(recipe.title)}
                                </Typography>
                            </ListItemText>
                            
                            <Button 
                                color="primary"
                                variant="contained" 
                                className={classes.buttonVizualize} 
                                onClick={() => props._showCourseRecipe(recipe.id)}
                                disableElevation
                                disabled={recipe.nb_items > 0 ? "" : "disbabled"}
                            >
                                Visualiser
                            </Button>
                            <Button
                                color="primary"
                                variant="contained" 
                                onClick={() => props._deleteCourseRecipe(recipe.id, index)}
                                disableElevation
                            >
                                Supprimer
                            </Button>
                        </ListItem>
                        <Divider />
                    </div>
                )
            })}
        </List>
    );
}

export default ListCourseRecipeView;