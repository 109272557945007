import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';

const ButtonsView = (props) => {
    const classes = useStyles();

    if (props.recipe.confirm === 1) {
        return (
            <div>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled
                    classes={{ root: classes.buttonDisabledAccepted, disabled: classes.disabled }}
                    disableElevation
                >
                    Confirmer
                </Button>

                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled
                >
                    Refuser
                </Button>
            </div>
        );
    }

    if (props.recipe.confirm === 2) {
        return (
            <div>
                <Button 
                    variant="contained" 
                    color="primary"
                    disabled
                >
                    Confirmer
                </Button>

                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled
                    classes={{ root: classes.buttonDisabledRefused, disabled: classes.disabled }}
                    disableElevation
                >
                    Refuser
                </Button>
            </div>
        );
    }

    let status = false;
    if (props.loading && props.recipe.confirm !== 0) {
        status = true;
    }

    return (
        <div>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={status}
                    onClick={() => props._handleConfirmOrNotShareRecipe(props.recipe.recipe.id_recipe_share, props.index, status = 1)}
                    disableElevation
                >
                    Confirmer
                </Button>

                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={status}
                    onClick={() => props._handleConfirmOrNotShareRecipe(props.recipe.recipe.id_recipe_share, props.index, status = 2)}
                    disableElevation
                >
                    Refuser
                </Button>
        </div>
    );
}


const useStyles = makeStyles(theme => ({ 
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -55,
    },
    buttonProgressRefused: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: 50,   
    },
    buttonDisabledAccepted: {
        '&$disabled': {
            backgroundColor: green[300],
            color: '#fff'
        },
    },
    buttonDisabledRefused: {
        '&$disabled': {
            borderColor: red[300],
            color: red[300]
        },
    },
    disabled: {}
}));

export default ButtonsView;