import React from 'react';
import { Box, Chip } from "@material-ui/core"
import StarBorderIcon from '@material-ui/icons/StarBorder';

export default function CategorySelectedView({ collection_category }) {

    let cat = collection_category.map((category) => {
        return(
            <Box component="span" p={0.5} key={category.id}>
                <Chip 
                    variant="outlined"
                    label={category.name} 
                    icon={<StarBorderIcon style={{ color: "#FE7820" }}/>} />
            </Box> 
        )
    });

    return (
        <Box display="flex" justifyContent="flex-start" style={{ marginTop: '15px', marginBottom: '15px' }}>
            {cat}
        </Box>
    )
}