import React, { useState, useEffect } from 'react';
import { location } from '../../../redux/actions/breadcrumb';
import { connect } from 'react-redux';
import Client from '../../../Client';
import AppHomeView from '../view/AppHomeView';
import { reloadHome } from '../../../redux/actions/home';

let AppHome = function (props) {

    const client = new Client();

    const [stats, setStats] = useState([]);
    const [lastRecipes, setLastRecipes] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [load, setLoad] = useState(true)
    const [loadSchedule, setLoadSchedule] = useState(true)

    useEffect(() => {
        props.dispatch(location(props.match.path));
        // get stats
        client.getStats().then(
            (result) => {
                setStats([result])
            },
            (error) => {

            }
        )

        props.dispatch(reloadHome(false))

    }, [props.isReloadHome])

    useEffect(() => {
        // get last recipes created
        client.getLastRecipes().then(
            (result) => {
                setLastRecipes(result);
                setLoad(false)
            },
            (error) => {

            }
        )
    }, [props.isReloadHome])

    useEffect(() => {
        // get calendar
        async function fetchPlannings(){
            const data = await client.getPlanning()
            if (data.status === 200) {
                let datas = await data.json();
                setSchedules(datas)
                setLoadSchedule(false)
            }
        }
        fetchPlannings()
    }, [])


    return <AppHomeView 
                stats={stats} 
                lastRecipes={lastRecipes}
                load={load}
                loadSchedule={loadSchedule}
                schedules={schedules}
            />;
}

function mapsStateToProps(state) {
    return {
        isReloadHome: state.reloadHome.is_reload_home
    }
}


AppHome = connect(mapsStateToProps)(AppHome);

export default AppHome;