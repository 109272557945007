import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FlashMessageView = (props) => {

    return(
        <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        open={props.flashmessage.display}
        autoHideDuration={props.flashmessage.duration}
        onClose={props._handleCloseSnackBar}
        message={props.flashmessage.desc}
        action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={props._handleCloseSnackBar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
        }>
        
            <Alert onClose={props._handleCloseSnackBar} severity={props.flashmessage.status}>
                {props.flashmessage.desc}
            </Alert>
        

      </Snackbar>
    );
}

export default FlashMessageView;