export const pictureUser = (pictureUser) => {
    return {
      type: 'PICTURE_USER',
      pictureUser
    }
}

export const pseudoUser = (pseudoUser) => {
  return {
    type: 'PSEUDO_USER',
    pseudoUser
  }
}