export const logUser = (state = {}, action) => {

    if(action.type === 'LOG_USER') {

      const info = {};
      info.idPseudo = action.logUser.id;
      info.pseudo = action.logUser.pseudo;
      info.email = action.logUser.email;
      info.token = action.logUser.token.value;
      info.authorPicture = action.logUser.authorPictureUrl;

      const userInfo = Object.assign(info, state)
      
      return userInfo;
    }
    
    return state;
}