import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserProfileInformationSnackBar = (props) => {
    let textAlert;

    if (props.contextualAlert === "danger")
        textAlert = "Les modifications n'ont pas été effectuées !";

    if (props.contextualAlert === "success")
        textAlert = "Mise à jour des modifications avec succès !";


    return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={props.isModifiedUserProfile}
            autoHideDuration={2000}
            onClose={props._handleCloseSnackBar}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={props._handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }>
                <Alert onClose={props._handleCloseSnackBar} severity="success">
                    {textAlert}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default UserProfileInformationSnackBar;