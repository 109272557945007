import React, { Component } from 'react';
//import '../css/createRecipe.css';
import TimeRestView from '../view/TimeRestView';

class TimeRest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timeRestingRecipeHour: this.props.timeRestingHour || "",
            timeRestingRecipeMinute: this.props.timeRestingMinute || ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.timeRestingRecipeHour !== this.state.timeRestingRecipeHour ) {
            this.props._fetchTimeRestingHour(this.state.timeRestingRecipeHour);
        }

        if( prevState.timeRestingRecipeMinute !== this.state.timeRestingRecipeMinute ) {
            this.props._fetchTimeRestingMinute(this.state.timeRestingRecipeMinute);
        }
    }

    _onSelectHour = (evt) => {
        this.setState({
            ...this.state,
            timeRestingRecipeHour: evt.target.value
        })
    }

    _onSelectMinute = (evt) => {
        this.setState({
            ...this.state,
            timeRestingRecipeMinute: evt.target.value
        })
    }

    render(){
        return(
            <TimeRestView 
                _onSelectHour={this._onSelectHour} 
                _onSelectMinute={this._onSelectMinute}
                timeRestingHour={this.state.timeRestingRecipeHour}
                timeRestingMinute={this.state.timeRestingRecipeMinute}
            />
        );
    }
}

export default TimeRest;