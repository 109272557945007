import React, { Component } from 'react';
import StepTextareaView from '../../view/stepRecipe/StepTextareaView';

class StepTextarea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: "",
            isDisabled: true
        }
    }

    _handleChangeStep = (evt) => {
        if (evt.target.value === "") {
            this.setState({
                step: evt.target.value,
                isDisabled: true
            })
        }else {
            this.setState({
                step: evt.target.value,
                isDisabled: false
            })
        }

    }

    _handleSubmitStep = () => {
        this.setState({
            step: ""
        });
        this.props._addStepToList(this.props.idList, this.state.step);
    }

    render(){
        return(
            <StepTextareaView 
                step={this.state.step}
                isDisabled={this.state.isDisabled}
                _handleChangeStep={this._handleChangeStep}
                _handleSubmitStep={this._handleSubmitStep}
            />     
        );
    }
}

export default StepTextarea;