import React, { Component } from 'react';
import { connect } from 'react-redux';
import Client from '../../../Client';
import MyRecipesNavThemeView from '../view/MyRecipesNavThemeView';
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';
import { location } from '../../../redux/actions/breadcrumb';

class AppMyRecipes extends Component {

    constructor(props){
        super(props);

        this.state = {
            themes: [],
            isLoaded: true,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
        }

        this.client = new Client();
    }

    componentDidMount = () => {

		this.client.getAllThemes().then(res => {
            console.log(res)
			var themes = res.data;
			this.setState({
                themes: themes,
                isLoaded: false
            });
            this.props.dispatch(location(this.props.match.path));
		}).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
        
    }

    render(){

        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if (this.state.isLoaded) {
			return <LinearProgressView />
		}else {
            return(
                <MyRecipesNavThemeView 
                    themes={this.state.themes}
                />
            );
        }
    }
}

AppMyRecipes = connect()(AppMyRecipes);

export default AppMyRecipes;