import React, { Component } from 'react';
import CourseView from '../view/CourseView';

class Courses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.addOrNotCourse.status || false
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.checked !== this.state.checked ) {
            this.props._fetchCourse(this.state.checked);
        }
    }

    handleCourse = (evt) => {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            ...this.state,
            checked: value
        });

    }

    render(){
        return(
            <CourseView 
                handleCourse={this.handleCourse}
                checked={this.state.checked} 
            />
        );
    }
}

export default Courses;