import React, { Component } from 'react';
import { connect } from 'react-redux';
import Client from '../../../../../Client';
import { pictureUser } from '../../../../../redux/actions/user';
import TopBarView from '../presentational/TopBarView';


class Topbar extends Component {

	constructor(props) {
		super(props);

		this.state = { 
			authorPicture: "",
			totalCourse: ""
		}

		this.client = new Client();
	}

	componentDidMount() {
		this.client.getUser().then((res) => {
			this.props.dispatch(pictureUser(res.data.authorPictureUrl));
		})

		this._getCountCourse();
	}

	static getDerivedStateFromProps(props, state) {
		
		if(props.authorPicture !== "" && props.authorPicture !== state.authorPicture) {
			return { authorPicture: props.authorPicture };
		}else {
			return null;
		}
		
	}

	componentDidUpdate(prevProps, prevState) {

		if( this.props.addCart !== prevProps.addCart ) {
			this.setState({
				...this.state,
				totalCourse: this.state.totalCourse + 1
			});
		}

		if( this.props.removeCart !== prevProps.removeCart ) {
			this.setState({
				...this.state,
				totalCourse: this.state.totalCourse - 1
			})
		} 

	} 


	_getCountCourse = () => {
		this.client.getCountCourses().then((res) => {
			this.setState({
				...this.state,
				totalCourse: res.data.total
			})
		})
	} 



	render() {
		return (
			<TopBarView 
				totalCourse={this.state.totalCourse}
				authorPicture={this.state.authorPicture}
				locationPathname={this.props.location}
			/>
		);
	}
	
}

function mapsStateToProps(state) {
    return {
		authorPicture: state.pictureUser,
		totalCart: state.totalCart,
		addCart: state.addCart,
		removeCart: state.removeCart,
		location: state.path
    }
}

Topbar = connect(mapsStateToProps)(Topbar);

export default Topbar;
