import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Courses from '../Courses';
import ItemRecipeView from '../../view/itemRecipe/ItemRecipeView';
import ItemView from '../../view/itemRecipe/ItemView';
import { Typography } from '@material-ui/core';

class ItemRecipe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: this.props.items || [],
            updateTitle: {
                status: false,
                index: ""
            },
            updateItem: {
                status: false,
                indexList: "",
                indexItem: ""
            },
            title: "",
            item: ""
        }
    }

    componentDidUpdate = (nevProps, prevState) => {
         /**
          * mise à jour des ingrédients
          * on envoie au composant parent la liste des ingrédients
          */
         if( prevState.items !== this.state.items ) {
             this.props._fetchItems(this.state.items);
         }
         
    }

    _addTitle = (evt) => {
        this.setState({
            ...this.state,
            title: evt.target.value
        })
    }

    _addItem = (evt) => {
        console.log("evt =>", evt.target.value)
        this.setState({
            ...this.state,
            item: evt.target.value,
            isAddItem: evt.target.value.trim() ? true : false
        })
    }

    _submitItem = (evt) => {
        evt.preventDefault();

        var item = {
            "title": this.state.title, 
            "listItems": [this.state.item]
        }

        this.setState({
            ...this.state,
            items: [...this.state.items, item],
            title: "",
            item: "",
            isAddItem: false,
            indexTitleUpdate: ""
        })

    }

    _onSortEndGroupItems = ({oldIndex, newIndex}) => {
        this.setState({
            ...this.state,
            items: arrayMove(this.state.items, oldIndex, newIndex)
        })
    }

    _removeTitle = (indexItem) => {
        let copyState = Object.assign([], this.state.items);
        copyState[indexItem]['title'] = ""

        // si plus rien dans la liste, on supprime la liste du tableau
        if (copyState[indexItem]['title'] === "" && copyState[indexItem]['listItems'].length === 0) {
            copyState.splice(indexItem, 1);
        }

        this.setState({
            ...this.state,
            items: copyState
        })
    }

    _updateTitle = (index) => {
        this.setState({
            ...this.state,
            updateTitle: {
                status: true,
                index
            }
        })
    }

    _onChangeUpdateTitle = (title) => {
        // copy object title
        let titleToUpdate = Object.assign({}, this.state.items[this.state.updateTitle.index]);
        titleToUpdate["title"] = title;
        
        // copy object items
        let stateToUpdate = Object.assign([], this.state.items);
        stateToUpdate[this.state.updateTitle.index] = titleToUpdate;

        this.setState({
            ...this.state,
            items: stateToUpdate,
            updateTitle: {
                status: false,
                index: ""
            }
        })
    }


    _onCancelUpdateTitle = () => {
        this.setState({
            ...this.state,
            updateTitle: {
                status: false,
                index: ""
            }
        })
    }

    _updateItem = (indexList, indexItem) => {
        this.setState({
            ...this.state,
            updateItem: {
                status: true,
                indexList,
                indexItem
            },
        })
    }

    _cancelUpdateItem = () => {
        this.setState({
            ...this.state,
            updateItem: {
                status: false,
                indexList: "",
                indexItem: ""
            }
        })
    }

    _addItemToList = (idList, item) => {
        let copyStateItemToUpdate = Object.assign({}, this.state.items[idList]);
        copyStateItemToUpdate['listItems'].push(item);
        
        let copyStateItem = Object.assign([], this.state.items);
        copyStateItem[idList] = copyStateItemToUpdate;

        this.setState({
            ...this.state,
            items: copyStateItem
        })
    }


    _onChangeUpdateItem = (item) => {

        let copyStateItemListToUpdate = Object.assign({}, this.state.items[this.state.updateItem.indexList]);
        let copyStateItemToUpdate = Object.assign([], this.state.items[this.state.updateItem.indexList]['listItems'])
        copyStateItemToUpdate[this.state.updateItem.indexItem] = item;
        copyStateItemListToUpdate['listItems'] = copyStateItemToUpdate;

        let copyStateItem = Object.assign([], this.state.items);
        copyStateItem[this.state.updateItem.indexList] = copyStateItemListToUpdate;

        this.setState({
            ...this.state,
            items: copyStateItem,
            updateItem: {
                status: false,
                indexList: "",
                indexItem: ""
            } 
        })

    }


    _onRemoveItem = (indexList, indexItem) => {

        let copyStateItemList = Object.assign({}, this.state.items[indexList]);
        let copyStateItem = Object.assign([], copyStateItemList['listItems']);
        copyStateItem.splice(indexItem, 1);
        copyStateItemList['listItems'] = copyStateItem;
        let copyState = Object.assign([], this.state.items);
        copyState[indexList] = copyStateItemList;

        // si plus rien dans la liste, on supprime la liste du tableau
        if (copyState[indexList]['title'] === "" && copyState[indexList]['listItems'].length === 0) {
            copyState.splice(indexList, 1);
        }

        this.setState({
            ...this.state,
            items: copyState
        })
        
    }

    _onSortItemList = ({oldIndex, newIndex, collection}) => {

        let copyStateLisItems = Object.assign({}, this.state.items[collection]);
        let sortChangeItem = arrayMove(copyStateLisItems['listItems'], oldIndex, newIndex);
        copyStateLisItems['listItems'] = sortChangeItem;

        let copyStateItem = Object.assign([], this.state.items);
        copyStateItem[collection] = copyStateLisItems;

        this.setState({
            ...this.state,
            items: copyStateItem
        })

    }

    render(){
        const isAddItem = this.state.isAddItem;
        const  { items } = this.state;

        let courses;

        if( this.state.items.length !== 0 ) {
            courses = <Courses _fetchCourse={this.props._fetchCourse} addOrNotCourse={this.props.addOrNotCourse} />
        }

        const SortableContainerGroupItems = SortableContainer(({children}) => {
            return <div>{children}</div>;
        })

        return (
            <div>
                { items.length > 0 && <Typography variant="subtitle2" gutterBottom style={{'marginBottom':'20px'}}>Liste des ingrédients</Typography> }

                {/* Aperçu des étapes */}
                <SortableContainerGroupItems onSortEnd={this._onSortEndGroupItems} useDragHandle>
                    { items.map((item, index) => (
                        <ItemView 
                            key={index}
                            item={item}
                            updateTitle={this.state.updateTitle}
                            updateItem={this.state.updateItem}
                            index={index}
                            idList={index}
                            title={this.state.title}
                            _removeTitle={this._removeTitle}
                            _updateTitle={this._updateTitle}
                            _onChangeUpdateTitle={this._onChangeUpdateTitle}
                            _onCancelUpdateTitle={this._onCancelUpdateTitle}
                            _updateItem={this._updateItem}
                            _addItemToList={this._addItemToList}
                            _cancelUpdateItem={this._cancelUpdateItem}
                            _onChangeUpdateItem={this._onChangeUpdateItem}
                            _onRemoveItem={this._onRemoveItem}
                            _onSortItemList={this._onSortItemList}
                        />
                    ))}
                </SortableContainerGroupItems>

                {/* Formulaire pour ajouter Titre et/ou ingrédient */}
                <ItemRecipeView 
                    title={this.state.title}
                    item={this.state.item}
                    isAddItem={isAddItem}
                    addTitle={this._addTitle}
                    addItem={this._addItem}
                    submitItem={this._submitItem}
                />

                {courses}
            </div>

        );
    }
}

export default ItemRecipe;