export const totalCart = (state = "", action) => {
  if(action.type === "TOTAL_CART") {
    return action.totalCart;
  }
  return state;
}

export const addCart = (state = "", action) => {
    if(action.type === "ADD_CART") {
      return parseInt(state + action.addCart);
    }
    return state;
}

export const removeCart = (state = "", action) => {
  if(action.type === "REMOVE_CART") {
    return parseInt(state - action.removeCart); 
  }
  return state;
}

export const removeIdCourse = (state = "", action) => {
  if( action.type === "REMOVE_IDCOURSE" ) {
    return action.idCourse;
  }
  return state;
} 