import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const NoteView = (props) => {
    return (
        <div>
            <Typography variant="subtitle1" gutterBottom>Remarque(s)</Typography>

            <TextField 
                size="small" 
                fullWidth
                id="outlined-note" 
                variant="outlined" 
                multiline
                rows="4"
                onChange={props._fetchEditor}
                value={props.note}
            />
        </div>

    );
}

export default NoteView;