import React from 'react';
import TextField from '@material-ui/core/TextField';

const TitleView = (props) => {

    return(
        <div>
            <TextField 
                required
                fullWidth
                id="title-recipe" 
                label="Titre de la recette" 
                variant="outlined"
                onChange={props._handleChange}
                value={props.titleRecipe}
            />
        </div>
    );
}

export default TitleView;
