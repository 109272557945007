import React from 'react';
import { Link } from 'react-router-dom';
import { removeAccents, sinPlu, ucFirst, urlFormat } from '../../../utils/services';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, Tooltip, Typography } from '@material-ui/core';

const CategoriesView = (props) => {

    const classes = useStyles();

    return (
        <Grid key={props.index} item xs={12} sm={4} md={3} lg={3} component={Link} to={`${props.pathname}/${props.category.id}/${urlFormat(removeAccents(props.category.name.toLowerCase()))}`}>
            <Card 
                elevation={0} 
                style={{
                    boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
                }}
            >
                <CardActionArea>
                    <Tooltip 
                        title={`${props.category.total_recipe} ${sinPlu(props.category.total_recipe, "recette")} dans cette catégorie`}
                        placement="top-end"
                        arrow
                    >
                        <CardContent>
                            <Typography variant="caption" className={classes.totalRecipe}>{props.category.total_recipe}</Typography>
                            <Typography variant="body1" className={classes.titleCategory}>{ucFirst(props.category.name)}</Typography>
                        </CardContent>
                    </Tooltip>
                </CardActionArea>
            </Card>
        </Grid>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        marginTop: '-10px',
        background: '#ffffff',
        position: 'relative'
      },
    totalRecipe: {
        position: 'absolute',
        fontSize: '0.6rem',
        right: '8px',
        top: '3px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    titleCategory: {
        textAlign: 'center'
    },
    cardCategory: {
        border: '1px solid #ECECEC'
    }
}));

export default CategoriesView;