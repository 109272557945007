import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    accountThumbnail: {
        width: '150px',
        height: '150px',
        borderRadius: '4px',
        background: 'rgba(199, 199, 199, 0.5)',
        marginBottom: '20px'
    },
    thumbnail: {
        position: 'relative'
    },
    input: {
      display: 'none',
    },
    camera: {
        position: 'absolute',
        top: '50px',
        left: '50px',
    },
    img: {
        width: '150px',
        borderRadius: '4px'
    },
    rootActionButtons: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '50px',
        left: '20px'
    },
    buttonIconUpload: {
        background: '#FFFFFF',
        color: '#000000',
        transitionDuration: '0.15s',
        transitionProperty: 'opacity,transform',
        marginRight: '20px',
        '&:hover': {
            transition: 'all 150ms ease',
            transform: 'scale(1.2)',
            background: '#FFFFFF'
        }
    },
    buttonIconDelete: {
        background: '#000000',
        color: '#FFFFFF',
        transitionDuration: '0.15s',
        transitionProperty: 'opacity,transform',
        '&:hover': {
            transition: 'all 150ms ease',
            transform: 'scale(1.2)',
            background: '#000000'
        }
    }
}));

const UserProfilePictureView = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.accountThumbnail}>
            {props.authorPictureUrl ? (
                <div className={classes.thumbnail}>
                    <div>
                        <img src={props.authorPictureUrl} className={classes.img} alt="profile author" />
                    </div>
                    <div className={classes.rootActionButtons}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                        />
                        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={props._handleSelectedFile} />
                        <label htmlFor="icon-button-file">
                            <IconButton aria-label="upload picture" component="span" className={classes.buttonIconUpload}>
                                <PhotoCamera fontSize="small" />
                            </IconButton>
                        </label>
                        <IconButton aria-label="upload picture" component="span" className={classes.buttonIconDelete} onClick={props._deleteAuthorPicture}>
                            <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <div className={classes.thumbnail}>
                        <div>
                            <span className="mbr-none-picture"><i className="far fa-image"></i></span>
                        </div>
                        <div className={classes.camera}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                            />
                            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={props._handleSelectedFile} />
                            <label htmlFor="icon-button-file">
                                <IconButton aria-label="upload picture author" component="span" className={classes.buttonIconUpload}>
                                    <PhotoCamera fontSize="small" />
                                </IconButton>
                            </label>
                        </div>
                </div>
            )}

        </div>

    );

}

export default UserProfilePictureView;