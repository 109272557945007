import React, { Component } from 'react';
import Client from '../../../Client';
import UserProfileInformationForm from './UserProfileInformationForm';
import UserProfileInformationSnackBar from '../view/UserProfileInformationSnackBar';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class UserProfileInformation extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            name: props.name,
            firstname: props.firstname,
            pseudo: props.pseudo,
            isFlashMessage: false, 
            contextualAlert: '',
            isRequest: false,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
        };

        this.client = new Client();
    }

    _handleCloseSnackBar = () => {
        this.setState({
            ...this.state,
            isFlashMessage: false
        })
    }

    dispatchAPI = (action) => {

		if (action.type === "EDIT_USER") {

            var _this = this;

            this.setState({ 
                ...this.state,
                isRequest: true 
            });

            this.client.editUser(action.datas)
            .then(function(response){
                var res = response.data;

                _this.setState({ 
                    ..._this.state,
                    isRequest: false 
                });
                
                if (res.error === 0) {
                    _this.setState({
                        ..._this.state,
                        isFlashMessage: true,
                        contextualAlert: 'success'
                    })
                }

                if (res.error === 1) {
                    _this.setState({
                        ...this.state,
                        isFlashMessage: true,
                        contextualAlert: 'error'
                    })
                }
            }).catch(error =>  {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });
		}
    }
    
    render() {
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else {
            return (
                <div id="information">
                    <UserProfileInformationForm 
                        name={this.state.name}
                        firstname={this.state.firstname}
                        pseudo={this.state.pseudo}
                        dispatchAPI={this.dispatchAPI}
                        isRequest={this.state.isRequest} />
    
                    <UserProfileInformationSnackBar 
                        isModifiedUserProfile={this.state.isFlashMessage}
                        contextualAlert={this.state.contextualAlert}
                        _handleCloseSnackBar={this._handleCloseSnackBar}
                    />  
                </div>
            );
        }

    }
}

export default UserProfileInformation;