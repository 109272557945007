import React, { Component } from 'react';
import ShareView from '../view/ShareView';

class ShareRecipe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            share: this.props.share || ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.share !== this.state.share ) {
            this.props._fetchShare(this.state.share);
        }
    }

    _handleChange = (event) => {
        this.setState({
            ...this.state,
            share: event.target.value
        });
    }

    render(){

        return(
            <ShareView 
                _handleChange={this._handleChange}
                share={this.state.share}
            />
        );

    }
}

export default ShareRecipe;