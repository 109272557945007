import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RecipeView from './RecipeView';
import { Divider } from '@material-ui/core';


const FavoriteRecipeView = (props) => {
    const classes = useStyles();

    let recipes = props.recipes;

    return (
        <main fixed="true" className={classes.container}>

            <div className={classes.toolbar} />

            <Grid container spacing={0}>
                <Typography variant="h5" className={classes.title}>
                    Mes favoris
                </Typography>
            </Grid>

            <Divider className={classes.mbr145} />

            <Grid container spacing={2}>
                {recipes.map((recipe, index) => 
                    <RecipeView
                        key={index}
                        index={index}
                        recipe={recipe}
                        _onDeleteFavorite={props._onDeleteFavorite}
                    />
                )}
            </Grid>
        </main>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
        fontWeight: 700
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
        marginTop: '24px',
        marginBottom: '34px'
    }
}));

export default FavoriteRecipeView;