import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const StyledBadge1 = withStyles(theme => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      background: '#FE7820',
      color: '#FFFFFF'
    }
  }))(Badge);

  const useStyles = makeStyles(theme => ({
    colorCart: {
        color: 'rgb(158, 158, 158)',
    },
    icon: {
        marginRight: '15px',
    }
  })) 

const CartView = (props) => {
    const classes = useStyles();
    return(
        <div>
            <IconButton aria-label="cart" className={classes.icon} onClick={props._toggleModal}>
                <StyledBadge1 badgeContent={props.total}>
                    <ShoppingCartIcon className={classes.colorCart} />
                </StyledBadge1>
            </IconButton>
        </div>
    );
}

export default  CartView;