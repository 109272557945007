import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Box from '@material-ui/core/Box';
import ShareIcon from '@material-ui/icons/Share';
import logo from '../../../../assets/img/Logo_horizontal.png';

const useStyles = makeStyles(theme => ({
    gridItem: {
        padding: '40px',
        '& section': {
          marginBottom: '25px',
          fontSize: '1.2em',
          [theme.breakpoints.down('md')]: { fontSize: '1em', },
        },
        background: '#F6F6F6',
        borderRight: '1px solid rgba(0,0,0,0.2)',
    },
    icon: {
        float: 'left',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        borderRadius: '50%',
        border: '1px solid',
        marginRight: '15px',
        color: '#FE7820'
    },
    description: {
        margin: '0',
        marginTop: '-20px'
    },
    logo: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    },
    you_recipe: {
        marginTop: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.2em',
            marginTop: '25px'
        },
    }
}))

const AuthenticationCaptionView = (props) => {
    const classes = useStyles();

    return(
        <Box component={Grid} item sm={5} md={7} className={classes.gridItem} display={{ xs: 'none', sm: 'block', md: 'block' }}>
            <section>
                <img src={logo} alt="Mon Bloc Recettes" className={classes.logo} />
                <p>Toutes vos recettes numérisées.
                <br />Ne cherchez plus vos notes, elles sont avec vous !</p>
            </section>

            <section>
                <div className={classes.icon__box}>
                    <div className={classes.icon}><CreateIcon /></div>
                    <h4 className={classes.title}>Rédiger</h4>
                    <p className={classes.description}>Écriver vos recettes en créant vos propres catégories</p>
                </div>

                <div className={classes.icon__box}>
                    <div className={classes.icon}><MenuBookIcon /></div>
                    <h4 className={classes.title}>Consulter</h4>
                    <p className={classes.description}>Accéder à vos recettes où que vous soyez</p>
                </div>

                <div className={classes.icon__box}>
                    <div className={classes.icon}><FormatListBulletedIcon /></div>
                    <h4 className={classes.title}>Lister</h4>
                    <p className={classes.description}>Préparer vos listes de courses</p>
                </div>

                <div className={classes.icon__box}>
                    <div className={classes.icon}><ShareIcon /></div>
                    <h4 className={classes.title}>Partager</h4>
                    <p className={classes.description}>Partager vos recettes avec vos amis</p>
                </div>
            </section>

            <section>
                <p>Rédiger autant de recettes que vous le souhaitez.
                <br />Aucune limitation et c'est gratuit !</p>
                <h2 className={classes.you_recipe}>C'est votre bloc recettes</h2>
            </section>
        </Box>
    );
}

export default AuthenticationCaptionView;