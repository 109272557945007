import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartView from '../view/CartView';
import ModalCourseRecipeView from '../view/ModalCourseRecipeView';

class AppCourseRecipe extends Component {
    constructor(props){
        super(props);

        this.state = {
            isModal:{
                isOpen: false
            } 
        }
    }

	/**
	 * Open or close modal form
	 */
	_toggleModal = () => {
        this.setState({
			isModal: {
				isOpen: !this.state.isModal.isOpen
			}
        });
    }

    render(){
        return(
           <div>
               {/* display cart front */}
               <CartView 
                    total={this.props.total}
                    _toggleModal={this._toggleModal}
               />

                {/* modal window to display list course */}
                <ModalCourseRecipeView 
                        show={this.state.isModal.isOpen}
                        _toggleModal={this._toggleModal}
                />
           </div>
        );
    }
}

AppCourseRecipe = connect()(AppCourseRecipe);

export default AppCourseRecipe;