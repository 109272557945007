import React from 'react';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ModalView from './ModalView';
import Rating from '@material-ui/lab/Rating';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PeopleIcon from '@material-ui/icons/People';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AlertDialog from '../../../components/CopyRecipe/container/AppCopyRecipe';
import SnackBarView from './SnackBarView';
import ShareIcon from '@material-ui/icons/Share';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { connect } from 'react-redux';
import DatePickerRecipeComponent from '../../../components/DatePickerRecipeComponent';
import Box from '@material-ui/core/Box';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { GiCookingGlove } from "react-icons/gi";
import { RiRestTimeLine } from "react-icons/ri";
import { ucFirst } from '../../../utils/services';
import { TbSquareChevronRight } from "react-icons/tb";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';



function createMarkup(note) {
    return {__html: note};
}
  

const MyRecipeView = (props) => {
    const classes = useStyles();

    let timeCook = "n/a";
    let timeWork = "n/a";
    let timeRest = "n/a";
    let timeRestingHour = "";
    let timeRestingMinute = "";
    let timeCookingHour = "";
    let timeCookingMinute = "";
    let timeWorkingHour = "";
    let timeWorkingMinute = "";

    const { items, steps } = props.recipe;

    if( parseInt(props.recipe.timeCookingHour) > 0 )
        timeCookingHour = props.recipe.timeCookingHour + "h";

    if( parseInt(props.recipe.timeCookingMinute) > 0 )
        timeCookingMinute = props.recipe.timeCookingMinute + "min";

    if( parseInt(props.recipe.timeWorkingHour) > 0 )
        timeWorkingHour = props.recipe.timeWorkingHour + "h";

    if( parseInt(props.recipe.timeWorkingMinute) > 0 )
        timeWorkingMinute = props.recipe.timeWorkingMinute + "min";
    
    if( parseInt(props.recipe.timeRestingHour) > 0 )
        timeRestingHour = props.recipe.timeRestingHour + "h";

    if( parseInt(props.recipe.timeRestingMinute) > 0 )
        timeRestingMinute = props.recipe.timeRestingMinute + "min";

    if (timeRestingHour || timeRestingMinute)
        timeRest = timeRestingHour + timeRestingMinute;

    if (timeCookingHour || timeCookingMinute)
        timeCook = timeCookingHour + timeCookingMinute;

    if (timeWorkingHour || timeWorkingMinute)
        timeWork = timeWorkingHour + timeWorkingMinute;

    return(
            <main fixed="true" className={classes.container}>

                <div className={classes.toolbar} />

                <SpeedDial
                    ariaLabel="options"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon />}
                    onClose={props._handleOnCloseSpeedDial}
                    onOpen={props._handleOnOpenSpeedDial}
                    open={props.openSpeedDial}
                    FabProps={{ style: { backgroundColor: "#FE7820" } }}
                >
                    <SpeedDialAction 
                        key="Bakery"
                        icon={props.isCourse ? <RemoveShoppingCartOutlinedIcon /> : <AddShoppingCartOutlinedIcon />}
                        tooltipTitle={props.isCourse ? "Supprimer de ma liste des courses" : "Ajouter dans ma liste des courses"}
                        onClick={props._toogleCart}
                    />
                    <SpeedDialAction 
                        key="Favorite"
                        icon={props.recipe.favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                        tooltipTitle={props.recipe.favorite ? "Supprimer de mes favoris" : "Ajouter dans mes favoris"}
                        onClick={props._toogleFavorite}
                    />
                    <SpeedDialAction 
                        key="Edit"
                        icon={<Link className={classes.link} to={`/mes-recettes/update/${props.recipe.id}`}><EditOutlinedIcon /></Link>}
                        tooltipTitle="Modifier"
                        to={`/mes-recettes/update/${props.recipe.id}`}
                    />
                    <SpeedDialAction 
                        key="Remove"
                        icon={<DeleteOutlineIcon />}
                        tooltipTitle="Supprimer ma recette"
                        onClick={props._deleteRecipe}
                    />
                    <SpeedDialAction 
                        key="Copy"
                        icon={<FileCopyIcon />}
                        tooltipTitle="Copier ma recette"
                        onClick={props._toggleCopyRecipe}
                    />
                    <SpeedDialAction 
                        key="Share"
                        icon={<Link className={classes.link} to={`/share/inbox/${props.recipe.id}`}><ShareIcon /></Link>}
                        tooltipTitle="Partager ma recette"
                        onClick={props._toggleCopyRecipe}
                    />
                    <SpeedDialAction 
                        key="Schedule"
                        icon={<CalendarTodayIcon />}
                        tooltipTitle="Ajouter dans mon agenda"
                        onClick={props._toggleDatePickerRecipe}
                    />

                </SpeedDial>

                <Grid 
                    container
                    style={{
                        background: "#FFFFFF",
                        borderRadius: 4
                    }}
                >

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box 
                            style={{ 
                                borderBottom: '3px solid rgba(33, 40, 50, 0.125)' 
                            }}
                            py={1}
                        >
                            <Typography 
                                variant="body1"
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                {ucFirst(props.recipe.theme)}
                            </Typography>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box
                            style={{
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                borderBottom: '3px solid rgba(33, 40, 50, 0.125)',
                            }}
                        >
                            <Typography variant="h4" className={classes.title}>
                                {props.recipe.title.toUpperCase()}
                            </Typography>
                            <Box style={{ textAlign: "center" }}>
                                <Rating value={props.recipe.difficulty} readOnly size="small" />
                            </Box>
                        </Box>
                    </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box 
                        elevation={0}
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
                            borderRadius: 4,
                            backgroundColor: 'rgba(255, 240, 230, 0.55)'
                        }}
                    >
                        <Box className={classes.postItInfoCook}>
                            <Box 
                                style={{ 
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <AccessTimeIcon style={{ fontSize: "1rem" }} />
                                </Box>
                                <Box>
                                    <Typography variant="body2">Préparation: {timeWork}</Typography>
                                </Box>
                            </Box>
                            <Box 
                                style={{ 
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <GiCookingGlove style={{ fontSize: "1rem" }} />
                                </Box>
                                <Box>
                                    <Typography variant="body2">Cuisson: {timeCook}</Typography>
                                </Box>
                            </Box>
                            <Box 
                                style={{ 
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <RiRestTimeLine style={{ fontSize: "1rem" }} />
                                </Box>
                                <Box>
                                    <Typography variant="body2">Repos: {timeRest}</Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{ 
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <PeopleIcon style={{ fontSize: "1rem" }} />
                                </Box>
                                <Box>
                                    <Typography variant="body2">{props.recipe.share}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        {/* PICTURE + ITEMS */}
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <img src={props.recipe.picture} alt="recipe_picture" className={classes.imgRecipe} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box
                                        style={{
                                            borderBottom: '1px solid rgba(33, 40, 50, 0.125)',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Typography variant="h6" className={classes.subTitle}>Ingrédients</Typography>
                                    </Box>
                                    <List  style={{ paddingTop: '5px', paddingBottom: 0 }}>
                                    { items ? (
                            items.map((item, index) => 
                            <div key={index}>
                                { item.title ? (
                                    <div className={classes.block_group}>
                                        <Typography variant="body1" style={{ fontWeight: 500 }}>{item.title}</Typography>
                                        <List className={classes.block__ul} >
                                            {item.listItems.map((list, index) =>
                                                <ListItem 
                                                    key={index}
                                                    style={{
                                                        paddingLeft: 0, 
                                                        paddingBottom: 0,
                                                        borderBottom: '1px solid rgba(33, 40, 50, 0.125)'
                                                    }}
                                                >
                                                    <ListItemIcon style={{ minWidth: '30px' }}>
                                                        <TbSquareChevronRight fontSize="large" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={list} />
                                                </ListItem>
                                            )}
                                        </List>
                                    </div>
                                ) : (
                                    <>
                                        {item.listItems.map((list, index) =>
                                            <ListItem 
                                                key={index} 
                                                style={{
                                                    paddingLeft: 0, 
                                                    paddingBottom: 0,
                                                    borderBottom: '1px solid rgba(33, 40, 50, 0.125)'
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <TbSquareChevronRight fontSize="large" />
                                                </ListItemIcon>
                                                <ListItemText primary={list} />
                                            </ListItem>
                                            
                                        )} 
                                    </>

                                    
                                )}
                            </div>
                            )
                        ) : (
                            null
                        )}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* STEPS */}
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box
                                        style={{
                                            borderBottom: '1px solid rgba(33, 40, 50, 0.125)',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Typography variant="h6" className={classes.subTitle}>Étapes</Typography>
                                    </Box>

                                        <List>
                                        { steps ? (
                            steps.map((step, index) => 
                            <div key={index}>
                            { step.title ? (
                                <div className={classes.block_group_step}>
                                    <Typography variant="body1" style={{ fontWeight: 500 }}>{step.title}</Typography>
                                    <List className={classes.block__ul}>
                                        {step.listSteps.map((list, index) =>
                                            <ListItem
                                                key={index}
                                                style={{
                                                    paddingLeft: 0, 
                                                    paddingBottom: 0,
                                                    borderBottom: '1px solid rgba(33, 40, 50, 0.125)'
                                                }}
                                            >
                                                <ListItemIcon className={classes.itemIcon}>
                                                    <ChevronRightIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={list} />
                                            </ListItem>
                                        )}
                                    </List>
                                </div>
                            ) : (
                                <>
                                    {step.listSteps.map((list, index) =>
                                        <ListItem 
                                            key={index}
                                            style={{
                                                paddingLeft: 0, 
                                                paddingBottom: 0,
                                                borderBottom: '1px solid rgba(33, 40, 50, 0.125)'
                                            }}
                                        >
                                            <ListItemIcon className={classes.itemIcon}>
                                                <ChevronRightIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={list} />
                                        </ListItem>
                                    )}   
                                </>

                            )}
                            </div>
                            )
                        ) : (
                            null
                        )}
                                        </List>


                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


             

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px' }}>
                        {props.recipe.note &&
                            <Box
                                p={2}
                                style={{
                                    marginTop: '35px',
                                    border: '1px solid rgba(33, 40, 50, 0.125)',
                                    borderRadius: 4
                                }}
                            >
                                <Box
                                    style={{ 
                                        textAlign: 'center'
                                    }}
                                    mb={2}
                                >
                                    <Typography variant="h6">
                                        Note(s)
                                    </Typography>
                                </Box>

                                <Typography variant="body1" dangerouslySetInnerHTML={createMarkup(props.recipe.note)} />
                            </Box>
                        }
                    </Grid>
                </Grid>



                {/* display popup to delete recipe */}
                {props.isModal &&
                    <ModalView 
                        _toggleModal={props._toggleModal} 
                        _confirmDeleteRecipe={props._confirmDeleteRecipe}
                        message={props.message}
                    />
                }

                {/* display popup to copy recipe in a theme */}
                <AlertDialog 
                    _toggleCopyRecipe={props._toggleCopyRecipe}
                    isCopy={props.isCopy}
                    idRecipe={props.recipe.id}
                    _copiedStatus={props._copiedStatus}
                />

                

                <DatePickerRecipeComponent 
                    recipe_id={props.recipe.id}
                    isPicker={props.isPicker}
                    _toggleDatePickerRecipe={props._toggleDatePickerRecipe}
                />

                <SnackBarView 
                    open={props.snackBar.open}
                    status={props.snackBar.status}
                    desc={props.snackBar.desc}
                    _handleCloseSnackBar={props._handleCloseSnackBar} 
                />

                
            </main>
    )
}

const useStyles = makeStyles(theme => ({ 
    container: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },  
    title: {
        textAlign: 'center',
    },
    groupIcons: {
        borderBottom: '1px dotted rgba(49, 54, 82, 0.2)',
        paddingBottom: '4px',
        marginBottom: '15px',
        marginTop: '4px'
    },
    imgRecipe: {
        width: '100%',
        borderRadius: '4px',
        marginBottom: '15px',
        [theme.breakpoints.down('sm')]: { width: '100%', },
    },
    itemIcon: {
        minWidth: '0px'
    },
    colorIcon: {
        color: 'rgba(0, 129, 255, 1)'
    },
    gridP: {
        '& p': {
            marginTop: 0,
            marginBottom: '7px'
        }
    },
    subTitle: {
        marginTop: 0,
        marginBottom: 0
    },
    subTitleItems: {
        marginTop: '40px',
        marginBottom: 0,
    },
    toolbar: theme.mixins.toolbar,
    block__ul: {
       paddingTop: 0,
       paddingBottom: 0
    },
    block_items: {
        marginBottom: '-20px'
    },
    block_group: {
        marginTop: '30px',
        marginBottom: '30px'
    },    
    block_items_step: {
        marginBottom: '-10px'
    },
    block_group_step: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    postItInfoCook: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    speedDial: {
        position: 'fixed',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
          top: theme.spacing(2),
          left: theme.spacing(2),
        }
    },
    link: {
        paddingTop: '5px',
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));


function mapsStateToProps(state) {
    return {
        schedule: state.schedule
    }
}

export default connect(mapsStateToProps)(MyRecipeView);