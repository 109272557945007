import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link } from "@material-ui/core";
import ContainerSchedulesView from './ContainerSchedulesView';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { ThreeDots } from 'react-loading-icons'
import '../src/css/schedule.css'

export default function DialogDatePickerView({
    isPicker,
    title,
    schedules,
    _closeDialog,
    isLoading,
    isError,
    _removeSchedule,
    isRemove,
    _openDatePicker
}) {

    return (
        <>
            <Dialog aria-labelledby="simple-dialog-title" open={isPicker} fullWidth={true}>
                <DialogTitle id="simple-dialog-title">Planification {title && <Box component="span" style={{ fontSize: '0.8em' }}> {title}</Box>}</DialogTitle>
                {isError.status ? (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                Une erreur s'est produite. 
                                Si l'erreur persiste, veuillez remonter votre souci à notre support technique 
                                en cliquant sur ce <Link href="/contacts">lien</Link>.
                            </Grid>
                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {isLoading ? (
                                    <Box component="div" className="mbr-box-loading">
                                        <ThreeDots fill="#98ff98" height="1em" width="60" style={{ display:'block',margin:'auto' }} />
                                    </Box> 
                                ) : (
                                    <ContainerSchedulesView 
                                        schedules={schedules} 
                                        isRemove={isRemove}
                                        _removeSchedule={_removeSchedule} 
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}

                <DialogActions>
                    {!isError.status ? (
                        <Grid style={{ display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                            <IconButton aria-label="add-calendar" onClick={_openDatePicker}>
                                <AddCircleRoundedIcon fontSize="large" />
                            </IconButton>
                            <Button 
                                variant="contained"
                                disableElevation
                                color="primary"
                                onClick={_closeDialog}
                            >
                                Fermer
                            </Button>
                        </Grid>
                    ) : (
                        <Grid>
                            <Button 
                                variant="contained"
                                disableElevation
                                color="primary"
                                onClick={_closeDialog}
                            >
                                Fermer
                            </Button>
                        </Grid>
                    )}


                </DialogActions>
            </Dialog>
        </>
    )
}