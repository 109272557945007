import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateRecipeLinkInput from '../container/CreateRecipeLinkInput';

export default function CreateRecipeLinkView({state}) {
    const classes = useStyles();

    return(
        <>
            {/* LINK */}
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    <CreateRecipeLinkInput 
                        _fetchLink={state._fetchLink}
                        link={state.link}
                    />
                </Paper>
            </Grid>
        </>

    )
}

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },    
    title: {
      flexGrow: 1,
      fontWeight: 700
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
      marginTop: '24px',
      marginBottom: '34px'
    },
    paper: {
        padding: theme.spacing(2),
        boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        background: '#ffffff',
        marginBottom: '20px',
      },
    breadCrumb: {
        '& li a': {
            color: 'rgba(0, 0, 0, 0.54)',
            textDecoration: 'none'
        },
        marginBottom: '5px',
    },
    buttonsChoiceCreate: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));