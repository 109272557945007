import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ModalSubmitRecipeView from './ModalSubmitRecipeView';
import { Divider, Breadcrumbs } from '@material-ui/core';
import { ucFirst } from '../../../utils/services';
import CreateRecipeWriteView from './CreateRecipeWriteView';

const FormRecipeView = (props) => {

    const classes = useStyles();

    return(
            <main fixed="true" className={classes.container}>

                <div className={classes.toolbar} />

                <Grid container spacing={3}>
                    <Grid item>
                        <Typography variant="h5" className={classes.title}>
                            Créer une recette
                        </Typography>
                    </Grid>
                </Grid>

                <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
                    <Link color="inherit" to="/creer-recette" className="breadcrumb">
                        <Typography variant="body1" color="primary">Créer un recette</Typography>
                    </Link>
                    <Typography variant="body1" color="textSecondary">{ucFirst(props.name)}</Typography>
                </Breadcrumbs>

                <Divider className={classes.mbr145} />

                <Grid spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.root}>
                            <CreateRecipeWriteView state={props} />
                        </div>
                    </Grid>
                </Grid>

                {props.isModalSubmit.status &&
                    <ModalSubmitRecipeView 
                        message={props.isModalSubmit.message}
                        id_recipe={props.isModalSubmit.recipe.id_recipe}
                        title_recipe={props.isModalSubmit.recipe.title_recipe}
                        link={props.link}
                    />
                } 
            </main>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },    
    title: {
      flexGrow: 1,
      fontWeight: 700
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
      marginTop: '24px',
      marginBottom: '34px'
    },
    paper: {
        padding: theme.spacing(2),
        boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        background: '#ffffff',
        marginBottom: '20px',
      },
    breadCrumb: {
        '& li a': {
            color: 'rgba(0, 0, 0, 0.54)',
            textDecoration: 'none'
        },
        marginBottom: '5px',
    },
    buttonsChoiceCreate: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));

export default FormRecipeView;
