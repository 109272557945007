import React, { Component } from 'react';
import Client from '../../../Client';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';
import { flashMessage } from '../../../redux/actions/flashmessage';
import ContactView from '../view/ContactView';
import { connect } from 'react-redux';

class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subject: "",
            comment: "",
            department: 1,
            status: 1,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
            },
            isError: false,
            isSubmit: false,
            error: ""
        }

        this.client = new Client();
    }

    _handleChangeSubject = (e) => {
        this.setState({
            ...this.state,
            subject: e.target.value
        })
    }

    _handleChangeDescription = (e) => {
        this.setState({
            ...this.state,
            comment: e.target.value
        })
    }

    _handleChangeDepartment = (e) => {
        this.setState({
            ...this.state,
            department: e.target.value
        })
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        var error = {
            ERROR_SUBJECT: false,
            ERROR_COMENT: false
        }

        if (!this.state.subject) error.ERROR_SUBJECT = true;
        if (!this.state.comment) error.ERROR_COMENT = true;

        var handleError = Object.values(error);
        var checkError = handleError.indexOf(true);

        if (checkError === -1) {
            this.setState({
                ...this.state,
                isSubmit: true,
                isError: false,
                error: "",
            })

            this.client.contactUs(this.state).then(res => {
                if (res.data.error === 0) {
                    this.props.dispatch(flashMessage(true, "success", "Votre message a bien été envoyé ! Nous vous répondrons dans les plus brefs délais.", 5000, true));
                }else {
                    this.props.dispatch(flashMessage(true, "error", "Oups ! il semble qu'il y ait eu un problème d'envoi.", 5000, true));
                }
                
            }).catch(error =>  {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });
            
        }else {
            this.setState({
                ...this.state,
                isError: true,
                error: error
            })
        }
        

    }

    render(){
        if (this.state.errorAuthAPI.status) {
			return <DialogError error={this.state.errorAuthAPI} />
		}else {
            return <ContactView 
                state={this.state}
                _handleSubmit={this._handleSubmit}
                _handleChangeSubject={this._handleChangeSubject}
                _handleChangeDescription={this._handleChangeDescription}
                _handleChangeDepartment={this._handleChangeDepartment}
            />
        }
    }
}

Contact = connect()(Contact);

export default Contact;