import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Typography, DialogContentText, DialogActions, DialogContent } from '@material-ui/core';


const ModalView = (props) => {

    return(
        <div>
            {props.message.success ? (
                <Dialog aria-labelledby="simple-dialog-title" open={true}>
                    <DialogTitle id="simple-dialog-title">Suppression recette</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography component="p">Votre message a bien été supprimée !</Typography>
                            {props.message.redirection &&
                                <Typography component="p">Redirection....</Typography>
                            }
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            ) : (
                <Dialog aria-labelledby="simple-dialog-title" open={true}>
                    <DialogTitle id="simple-dialog-title">Suppression recette</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography component="p">Confirmer la suppression de votre recette ?</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained" 
                            onClick={props._toggleModal}
                            disableElevation
                            color="primary"
                        >
                            Annuler
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={props._confirmDeleteRecipe}
                            disableElevation
                            color="primary"
                        >
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

  

  
export default ModalView;        