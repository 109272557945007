import React, { Component } from 'react';
//import '../css/createRecipe.css';
import TimeWorkingView from '../view/TimeWorkingView';

class TimeWorking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeWorkingRecipeHour: this.props.timeWorkingHour || "",
            timeWorkingRecipeMinute: this.props.timeWorkingMinute || ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.timeWorkingRecipeHour !== this.state.timeWorkingRecipeHour ) {
            this.props._fetchTimeWorkingHour(this.state.timeWorkingRecipeHour);
        }

        if( prevState.timeWorkingRecipeMinute !== this.state.timeWorkingRecipeMinute ) {
            this.props._fetchTimeWorkingMinute(this.state.timeWorkingRecipeMinute);
        }
    }
    
    _onSelectHour = (evt) => {
        this.setState({
            ...this.state,
            timeWorkingRecipeHour: evt.target.value
        })
    }

    _onSelectMinute = (evt) => {
        this.setState({
            ...this.state,
            timeWorkingRecipeMinute: evt.target.value
        })
    }

    render(){
 
        return(
            <TimeWorkingView 
                _onSelectHour={this._onSelectHour} 
                _onSelectMinute={this._onSelectMinute}
                timeWorkingRecipeHour={this.state.timeWorkingRecipeHour}
                timeWorkingRecipeMinute={this.state.timeWorkingRecipeMinute} />
        );
    }
}

export default TimeWorking;