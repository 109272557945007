import React, { Component } from 'react';
import UserProfileInformation from '../view/UserProfileInformation';

class UserProfileInformationForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            name: props.name,
            firstname: props.firstname,
            pseudo: props.pseudo,
            ERROR_FORM: {
                PSEUDO: false
            }
        }
    }

    /*
    componentDidUpdate(prevProps) {
        if (prevProps.pseudo !== this.props.pseudo) {
            this.setState({
                pseudo: this.props.pseudo
            });
        }
    }
    */

    _onChangePseudo = (e) => {
        this.setState({
            pseudo: e.target.value
        })
    }

    _onChangeName = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    _onChangeFirstname = (e) => {
        this.setState({
            firstname: e.target.value
        })
    }

    _onFormSubmit = (evt) => {
        evt.preventDefault();

        var ERROR_FORM = {
            PSEUDO: false,
        }

        if (!this.state.pseudo) {
            ERROR_FORM.PSEUDO = true;
        }

        var handleError = Object.values(ERROR_FORM);
        var checkError = handleError.indexOf(true);

        if (checkError === -1) {

            let reducer = {
                type: "EDIT_USER",
                datas: {
                    name: this.state.name,
                    firstname: this.state.firstname,
                    pseudo: this.state.pseudo
                }
            }
            this.props.dispatchAPI(reducer);

        }else {
            this.setState({
                ...this.state,
                ERROR_FORM: ERROR_FORM
            })
        }

    }

    render() {

        return (
            <UserProfileInformation 
                _onFormSubmit={this._onFormSubmit}
                _onChangeName={this._onChangeName}
                _onChangeFirstname={this._onChangeFirstname}
                _onChangePseudo={this._onChangePseudo}
                name={this.state.name}
                firstname={this.state.firstname}
                pseudo={this.state.pseudo}
                isRequest={this.props.isRequest}
                ERROR_FORM={this.state.ERROR_FORM}
            />
        );
    }
}

export default UserProfileInformationForm;