import React, { Component } from 'react';
import Client from '../../../Client';
import SearchFormView from '../view/SearchFormView';

export default class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadSearch: false,
            search: [],
            keyword: "",
            user: ""
        }

        this.client = new Client();
    }

    _searchKeyword = (e) => {
        const keyword = e.target.value;

            this.setState({ loadSearch: true })

            this.client.getSearchUser({"keyword": keyword}).then((res) => {
                this.setState({
                    search: res.data,
                    loadSearch: false,
                    keyword,
                    user: ""
                });
            });

    }

    _chooseUserToShare = (id_user, pseudo, lastname, firstname, authorPicture) => {
        this.setState({
            search: [],
            user: {
                pseudo,
                lastname,
                firstname,
                authorPicture
            }
        });
        this.props._userToShare(id_user);
    }

    _removeKeyword = () => {
        this.setState({
            search: [],
            loadSearch: false,
            keyword: "",
            user: ""
        });
    }

    render(){
        return (
            <SearchFormView 
                loadSearch={this.state.loadSearch}
                _searchKeyword={this._searchKeyword}
                search={this.state.search}
                _chooseUserToShare={this._chooseUserToShare}
                user={this.state.user}
                callApiUser={this.props.callApiUser}
                successShare={this.props.successShare}
                keyword={this.state.keyword}
                _removeKeyword={this._removeKeyword}
            />
        );
    }
}