import React from 'react';
import ModalCourseRecipe from '../container/ModalCourseRecipe';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
      background: '#fff0e6',
      color: '#000'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    bgDialog: {
        backgroundColor: '#f8f9fc'
    }
  }));
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ModalCourseRecipeView = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog fullScreen open={props.show} onClose={props._toggleModal} TransitionComponent={Transition} >
                <AppBar className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props._toggleModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Liste des courses
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ModalCourseRecipe
                    _removeCart={props._removeCart}
                    _toggleModal={props._toggleModal}
                />
            </Dialog>
        </div>
    );


}

export default ModalCourseRecipeView;