import React from 'react';
import { Link } from 'react-router-dom';
import { ucFirst } from '../../../utils/services';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import MyListRecipes from '../container/MyListRecipes';

const MyRecipesByCategoryView = (props) => {
    const classes = useStyles();

    let { recipes } = props;

    return(
            <main fixed="true" className={classes.container}>

                <div className={classes.toolbar} />
                
                <Grid container spacing={0}>
                    <Typography variant="h5" className={classes.title}>
                        {ucFirst(props.params.category_name)}
                    </Typography>
                </Grid>

                <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
                    <Link color="inherit" to="/mes-recettes" className="breadcrumb">
                        <Typography variant="body1" color="primary">Mes recettes</Typography>
                    </Link>
                    <Link color="inherit" to={`/mes-recettes/${props.params.id_theme}/${props.params.name_theme}`} className="breadcrumb">
                        <Typography variant="body1" color="primary">{ucFirst(props.params.name_theme)}</Typography>
                    </Link>
                    <Typography variant="body1" color="textSecondary">{ucFirst(props.params.category_name)}</Typography>
                </Breadcrumbs>

                <Divider className={classes.mbr145} />

                <MyListRecipes recipes={recipes} />

            </main>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },    
    title: {
        flexGrow: 1,
        fontWeight: 700
    },
    breadCrumb: {
        marginBottom: '5px',
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
        marginTop: '24px',
        marginBottom: '34px'
    }
}));

export default MyRecipesByCategoryView;