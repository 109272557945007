import { Box, Button, Card, CardActions, CardContent } from '@material-ui/core';
import React from 'react';
import { formatDate } from "../../../utils/services";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import '../src/css/schedule.css';

export default function ContainerSchedulesView({schedules, _removeSchedule, isRemove}) {

    const {status, schedule_id } = isRemove

    if (schedules.length > 0) {
        return (
            <>
                {schedules.map((schedule, index) =>
                    <Card key={schedule.id} className='mbr-date-paper' style={{ color: '#FFFFFF'}} variant="outlined">
                        <CardContent style={ status && schedule_id === schedule.id ? {backgroundColor: 'rgba(0,0,0,0.1)'} : {backgroundColor: '#FE7820'} }>
                            <Box component="span">
                                <CalendarTodayIcon style={{ fontSize: 15 }} className='mbr-date-header mbr-date-header-icon mb-date-header-calendar' />
                                {formatDate(schedule['datetime'],  "long_date", "fr")}
                            </Box>
    
                            <Box component="span">
                                <QueryBuilderIcon style={{ fontSize: 15 }} className='mbr-date-header mbr-date-header-icon mbr-date-header-clock' />
                                {formatDate(schedule['datetime'],  "time", "fr")}
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => _removeSchedule(schedule.id, index)} disabled={ status ? true : false }>Supprimer</Button>
                        </CardActions>
                    </Card>
    
    
                )}
            </>
    
        )
    } else {
        return (
            <Box component="div">Aucune planification en cours</Box>
        )
    }

}