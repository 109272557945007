import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: '20px',
    }
}));


export default function DialogError(props) {



  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState('md');

  const handleClose = () => {
    window.location.href = process.env.REACT_APP_URL_APP+'logout';
  };

  const _handleClose500 = () => {
    window.location.href = process.env.REACT_APP_URL_APP+'mes-recettes';
  }

  const _handleClose200 = () => {
    setOpen(false);
  }
  
  if( props.error.code_status === 400 ) {
    return (
      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Session expirée !</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Votre session a expirée. Veuillez vous reconnecter afin de profiter à nouveau de votre bloc recettes !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className={classes.button} color="primary">
              Se connecter
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }else if ( props.error.code_status === 500 ) {
    return(
      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={_handleClose500}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Erreur système !</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Une erreur est survenue. Si le problème persiste, veuillez nous contacter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_handleClose500} className={classes.button} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );

  }else if ( props.error.code_status === 200 ) {
    
    return(
      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={_handleClose500}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Fichier trop gros !</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Une erreur est survenue. Le poids du fichier est trop gros. Réduisez la taille de l'image.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_handleClose200} className={classes.button} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );

  }else {
    return null;
  }

}
