import React, { Component } from 'react';
import Client from '../../../Client';
import PictureRecipeView from '../view/PictureRecipeView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';
//import '../../css/App.css';

class PictureRecipe extends Component {
    constructor(props) {
        super(props);

        this.fileUpload = React.createRef();

        this.state = {
            picture: this.props.picture || "",
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
        }

        this.client = new Client();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.picture !== this.state.picture ) {
            this.props._fetchPicture(this.state.picture)
        }
    }

    _handleselectedFile = (event) => {

        const data = new FormData();
        data.append('file', event.target.files[0], event.target.files[0].name);

        this.client.pictureRecipe(data)
        .then(res => {
            if (res.data.error === 0) {
                this.setState({
                    ...this.state,
                    picture: res.data.desc
                });
            }else {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: 200,
                        data: "upload max filesize excedeed"
                    }
                });
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });

    }

    _uploadFile = () => {
        this.fileUpload.current.click()
    }

    _deleteFile = () => {
        this.setState({
            ...this.state,
            picture: ""
        })
    }

    render(){
        return(
            <div>
                <PictureRecipeView 
                    _handleselectedFile={this._handleselectedFile}
                    _uploadFile={this._uploadFile}
                    fileUpload={this.fileUpload}
                    picture={this.state.picture}
                    _deleteFile={this._deleteFile}
                />

                <DialogError error={this.state.errorAuthAPI} />
            </div>
        );
    }
}

export default PictureRecipe;