import React, { Component } from 'react';
import { connect } from 'react-redux';
import Client from '../../../Client';
import MyRecipesByCategoryView from '../view/MyRecipesByCategoryView';
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

class MyRecipesByCategory extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            total_recipe: "",
            recipes: [],
            isLoaded: true,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
        }

        this.client = new Client();
    }

    componentDidMount = () => {
        var id_theme = this.props.match.params.id_theme;
        var id_category = this.props.match.params.id_category;
        this.client.getRecipesFromCategory(id_theme, id_category).then(res => {
            if (res.data) {
                this.setState({
                    ...this.state,
                    total_recipe: res.data.total,
                    recipes: res.data.recipes,
                    isLoaded: false
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
        
    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if (this.state.isLoaded) {
			return <LinearProgressView />
		}else {
            return(
                <MyRecipesByCategoryView
                    params = {this.props.match.params}
                    recipes = {this.state.recipes}
                />
            );
        }
    }
}

MyRecipesByCategory = connect()(MyRecipesByCategory);

export default MyRecipesByCategory;