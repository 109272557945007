import React from 'react';
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';

export default function ButtonChooseKindWriteView({kindRecipe, _handleKindRecipe, state}) {

    const classes = useStyles();


    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonsChoiceCreate}>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<DescriptionOutlinedIcon />}
                    disabled={kindRecipe === 'write' ? "disabled" : ""}
                    disableElevation
                    onClick={() => _handleKindRecipe('write')}
                    className={classes.buttonCreate}
                >
                    Rédiger la recette
                </Button>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<LinkOutlinedIcon />}
                    disabled={kindRecipe === 'link' ? "disabled" : ""}
                    disableElevation
                    onClick={() => _handleKindRecipe('link')}
                >
                    Créer un lien de la recette
                </Button>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonsChoiceCreate: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '20px',
    },
    buttonCreate: {
        marginRight: '20px',
    }
}));