import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, Paper } from '@material-ui/core';
import SearchForm from '../container/SearchForm';
import HistoricRecipesShared from '../container/HistoricRecipesShared';
import { urlFormat, removeAccents } from '../../../utils/services';



const ShareSpecificRecipeView = (props) => {
    const classes = useStyles();

    return(
        <main fixed="true" className={classes.container}>
            <div className={classes.toolbar} />

            <Grid container spacing={0}>
                <Typography variant="h5" className={classes.title}>
                    Partager votre recette
                </Typography>
            </Grid>

            <Divider className={classes.mbr145} />

            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Link className={classes.mbr_link} to={`/mes-recettes/view/${urlFormat(removeAccents(props.recipe.title.toLowerCase()))}/${props.recipe.id_recipe}`}>
                        <Paper elevation={0} className={classes.mbr_paper}>
                            <div className={classes.grid_img_text}>
                                <img src={props.recipe.picture} alt="recette" className={classes.size} /> 
                                <Typography variant="body1" className={classes.text_grid_img_text}>{props.recipe.title}</Typography>
                            </div>
                        </Paper>
                    </Link>
                </Grid>
            </Grid>

            <Grid container spacing={0} className={classes.mbr_150}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.p15}>
                    <SearchForm 
                        _userToShare={props._userToShare}
                        callApiUser={props.callApiUser}
                        successShare={props.successShare}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.p15}>
                    <HistoricRecipesShared 
                        callApiUser={props.callApiUser}
                        successShare={props.successShare}
                        recipe={props.recipe}
                    />
                </Grid>
            </Grid>

        </main>
    );
}

const useStyles = makeStyles(theme => ({ 
    container: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },    
    title: {
        flexGrow: 1,
        fontWeight: 700
    },
    toolbar: theme.mixins.toolbar,
    mbr145: {
        marginTop: '24px',
        marginBottom: '34px'
    },
    size: {
        width: '10%',
        borderRadius: '4px'
    },
    grid_img_text: {
        display: 'flex',
        flexGrow: 1,
    },
    text_grid_img_text: {
        marginLeft: '10px'
    },
    mbr_150: {
        border: '1px solid rgba(0,0,0,0.12)',
        minHeight: 'auto',
        marginTop: '25px',
    },
    p15: {
        padding: '15px'
    },
    mbr_link: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    mbr_paper: {
        padding: '15px',
        boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
    }
}));

export default ShareSpecificRecipeView;