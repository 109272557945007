import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: '20px',
    }
}));

export default function ErrorScript(props) {
    const classes = useStyles();
    const [open] = React.useState(true);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('md');
    const [code] = useState(props.code)

    const handleClose = () => {
        window.location.href = process.env.REACT_APP_URL_APP+'/mes-recettes';
    };

    return (
        <React.Fragment>
            <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            >
            <DialogTitle id="max-width-dialog-title">Erreur système</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>Code erreur: {code} </p>
                    Une erreur est survénue lors de l'enregistrement de votre recette.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.button} color="primary">
                Fermer
                </Button>
            </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}