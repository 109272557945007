import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, CircularProgress, Divider, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
    mb2: {
        marginBottom: '20px'
    },
    mt2: {
        marginTop: '20px'
    },
    iconSearch: {
        position: 'absolute',
        right: '10px',
        bottom: '10px'
    },
    iconSearchRemove: {
        position: 'absolute',
        right: '8px',
        bottom: '7px'
    },
    searchForm: {
        position: 'relative',
    },
    listItem: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        background: '#fff',
        '&:hover': {
            background: '#fff0e6',
        },
    },
    iconSearchUserFromListItem: {
        position: 'absolute',
        right: '15px',
        bottom: '25px'
    },
    avatar: {
        background: 'rgb(158, 158, 158)'
    }
}));

function NameUserToSearch(props) {

    const { user } = props;

    let firstname = user.firstname !== null ? user.firstname : "";
    let lastname = user.lastname !== null ? user.lastname : "";

    return <ListItemText primary={user.pseudo} secondary={firstname +' '+ lastname} />;
}

const SearchFormView = (props) => {

    const classes = useStyles();

    const { loadSearch, search } = props;

    let iconInput;

    if (loadSearch) {
        iconInput = <CircularProgress size={20} className={classes.iconSearch} />
    }

    return (
        <div>
            <div className={classes.searchForm}>
                <TextField
                    id="search-user"
                    label="rechercher un contact"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={props._searchKeyword}
                    autoComplete="off"
                />

                {/* display spinner or display remove keyword */}
                {iconInput}
            </div>

            <Divider className={classes.mb2} />

            {search.map((user) =>
                <ListItem button key={user.id} className={classes.listItem} onClick={() => props._chooseUserToShare(user.id, user.pseudo, user.lastname, user.firstname, user.authorPicture)}>
                    <ListItemAvatar>
                        {user.authorPicture ? (
                            <Avatar
                                className={classes.avatar}
                                src={user.authorPicture}
                            />
                        ) : (
                            <PersonIcon />
                        )}
                    </ListItemAvatar>
                    <NameUserToSearch user={user} />
                </ListItem>
            )}

            {props.user &&
                <ListItem>
                    <ListItemAvatar>
                        {props.user.authorPicture ? (
                            <Avatar
                                className={classes.avatar}
                                src={props.user.authorPicture}
                            />
                        ) : (
                            <PersonIcon />
                        )}
                    </ListItemAvatar>
                    <NameUserToSearch user={props.user} />
                    {props.callApiUser && <CircularProgress size={20} className={classes.iconSearchUserFromListItem} />}
                    {props.successShare.status && <CheckCircleOutlineIcon size={20} className={classes.iconSearchUserFromListItem} />}
                </ListItem>
            }
        </div>

    );

}

export default SearchFormView;