import Cookies from 'js-cookie';

const urlAPI = process.env.REACT_APP_URL_API;
const COOKIE_STORAGE_KEY = 'mbr_4568762198545285638';

export default class ClientDatePicker {

    constructor() {
        this.useCookie = Cookies.get(COOKIE_STORAGE_KEY);

        if (typeof(this.useCookie) !== "undefined") {
            this.token = Cookies.get(COOKIE_STORAGE_KEY)
        }
    }

    async getSchedules(id_recipe) {
        const res = await fetch(`${urlAPI}planning/${id_recipe}`, {
            method: "GET",
            headers: {
                "X-Auth-Token": this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return res;
    }

    async removeSchedule(schedule_id) {
        const res = await fetch(`${urlAPI}planning/${schedule_id}`, {
            method: "DELETE",
            headers: {
                "X-Auth-Token": this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return res;
    }

    async setSchedule(data) {
        const res = await fetch(urlAPI + 'planning', {
            method: "POST",
            headers: {
                "X-Auth-Token": this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return res;
    }

}