import React, {Component} from 'react'
import { Redirect } from 'react-router-dom';
import { emailFormat } from '../../../utils/services';
import Client from '../../../Client';
import { connect } from 'react-redux';
import AuthenticationLoginView from '../view/AuthenticationLoginView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';



class AuthenticationLogin extends Component {

    constructor(props){
        super(props)

        this.state = {
            email: "",
            password: "",
            loading: false,
            ERROR_REPORT: {
                email: false,
                error_account: false,
                message: ""
            },
            errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
            },
            redirect: false,
        }

        this.client = new Client();
    }

    componentDidMount = () => {
        if (this.client.getToken()) {
            this.setState({ redirect: true })
        }
    }

    _emailHandle = (event) => {
        this.setState({
            ...this.state,
            email: event.target.value
        })
    }

    _passwordHandle = (event) => {
        this.setState({
            ...this.state,
            password: event.target.value
        })
    }

    _onLogin = (e) => {
        e.preventDefault()
        var _this = this;

        // check format email
        if( emailFormat(this.state.email) ) {

            this.setState({
                ...this.state,
                loading: true
            })

            let data = {
                email: this.state.email,
                password: this.state.password
            }


/*
        this.client.login(data).then(
            (result) => {
                console.log(result)
                
                if (result.user) {
                    _this.client.setToken(result.user.token);
                    window.location.href = config.env_url_app;
                }else {
                    console.log(result)
                    _this.setState({
                        ..._this.state,
                        loading: false,
                        ERROR_REPORT: {
                            email: false,
                            error_account: true,
                            message: result.message
                        }
                    })
                }
                
            },
            (error) => {
                console.log(error)
                
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
                
            });
            */


            this.client.login(data).then(function(res){
                const resp = res.data
                if (resp.user) {
                    _this.client.setToken(resp.user.token);
                    if (_this.client.getToken()) {
                        _this.setState({
                            redirect: true
                        });
                    }
                }else {
                    _this.setState({
                        ..._this.state,
                        loading: false,
                        ERROR_REPORT: {
                            email: false,
                            error_account: true,
                            message: res.data.message
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            })
            
            

        }else {
            this.setState({
                ...this.state,
                ERROR_REPORT: {
                    email: true
                }
            })
        }
    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if (this.state.redirect) {
            return <Redirect to="/" />
        }else {
            return(
                <AuthenticationLoginView 
                    _emailHandle={this._emailHandle}
                    _passwordHandle={this._passwordHandle}
                    _onSubmit={this._onLogin}
                    state={this.state}
                />
            );
        }
    }
}

function mapsStateToProps(state) {
    return {
        userInfo: state.logUser
    }
}

AuthenticationLogin = connect(mapsStateToProps)(AuthenticationLogin);

export default AuthenticationLogin;