// MANIPULATE SCHEDULE (DATETIME, REMOVE SCHEDULE, ADD)
let initialState = {
    is_reload_home: false
}

export const reloadHome = (state = initialState, action) => {

    if (action.type === "IS_RELOAD") {
        return {
            ...state,
            is_reload_home: action.payload
        }
        
    }

    return state;
}

