import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttons: {
        marginBottom: '10px',
        background: 'rgba(0, 0, 0, 0.14)',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.34)',
        }
    },
    circularProgress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function ThemesContent(props) {
    const classes = useStyles();
    const { themes } = props;
    return(
        <Grid container spacing={1}>
            {themes.map((theme) =>
                <Grid item xs={12} sm={3} md={3} lg={3} key={theme.id}>
                    <Button 
                        size="large" 
                        className={classes.buttons} 
                        variant="contained" 
                        disableElevation 
                        fullWidth
                        onClick={() => props._selectTheme(theme.id)}
                    >
                        {theme.name}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

function CaptionContent(props) {
    return (
        <Typography>Copie de votre recette...</Typography>
    );
}

const DialogCopyRecipe = (props) => {
    const classes = useStyles();
    const { themes, confirmCopy, isCopy, isLoaded } = props;
    let content = "";

    if (confirmCopy) {
        content = <CaptionContent />
    }else {
        content = <ThemesContent themes={themes} _selectTheme={props._selectTheme} />
    }

    return(
        <div>
            <Dialog
            open={isCopy}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Dans quel thème voulez-vous copier votre recette ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div" id="alert-dialog-description">
                        {isLoaded ? (
                            <div className={classes.circularProgress}><CircularProgress /></div>
                        ) : (
                            <div>{content}</div>
                        )}
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!props.confirmCopy &&
                        <Button onClick={props._toggleCopyRecipe} variant="contained" color="primary" disableElevation>
                            Annuler
                        </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogCopyRecipe;