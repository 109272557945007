import React, { Component } from 'react';
//import '../css/createRecipe.css';
import TimeCookingView from '../view/TimeCookingView';

class TimeCooking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timeCookingRecipeHour: this.props.timeCookingHour || "",
            timeCookingRecipeMinute: this.props.timeCookingMinute || ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.timeCookingRecipeHour !== this.state.timeCookingRecipeHour ) {
            this.props._fetchTimeCookingHour(this.state.timeCookingRecipeHour);
        }

        if( prevState.timeCookingRecipeMinute !== this.state.timeCookingRecipeMinute ) {
            this.props._fetchTimeCookingMinute(this.state.timeCookingRecipeMinute);
        }
    }

    _onSelectHour = (evt) => {
        this.setState({
            ...this.state,
            timeCookingRecipeHour: evt.target.value
        })
    }

    _onSelectMinute = (evt) => {
        this.setState({
            ...this.state,
            timeCookingRecipeMinute: evt.target.value
        })
    }

    render(){
        return(
            <TimeCookingView 
                _onSelectHour={this._onSelectHour} 
                _onSelectMinute={this._onSelectMinute}
                timeCookingHour={this.state.timeCookingRecipeHour}
                timeCookingMinute={this.state.timeCookingRecipeMinute} />
        );
    }
}

export default TimeCooking;